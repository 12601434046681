import { User } from "../../../types";
import { baseURL, mapToSnakeCase, resolveFetch } from "../../../api/shared";
import {
  Event,
  LocalContentGenerationParams,
  Person,
  TimeSeries,
  Topic,
  Widget,
} from "../../../types/widgets";

export const readWidget = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
): Promise<Widget> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export const updateWidget = async (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
  body: {
    content?: TimeSeries[] | Person[] | Event[] | Topic[];
    localContentGenerationParams?: LocalContentGenerationParams;
  },
): Promise<Widget> => {
  const snake_case_body = mapToSnakeCase(body, { blacklist: ["searchResult"] });
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/widgets/${widgetId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(snake_case_body),
    }),
    true,
  );
};
