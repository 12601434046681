import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createDashboardTemplate } from "../api/dashboardTemplates";
import { CreateDashboardTemplateBody, DashboardTemplate } from "../types";

export const useCreateDashboardTemplateMutation = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation<
    DashboardTemplate,
    Error,
    { orgId: string; body: CreateDashboardTemplateBody }
  >(({ orgId, body }) => createDashboardTemplate(user, orgId, body), {
    onSuccess: (_, { orgId }) => {
      queryClient.invalidateQueries([orgId, "dashboard_templates"]);
    },
  });
};
