import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "config-tailwind/globals.css";
import { userRoutes } from "./routes/userRoutes";
import { rooteRoute } from "./routes/rootRoutes";
import { authRoutes } from "./routes/authRoutes";
import { orgRoutes } from "./routes/orgRoutes";

export const AppRoutes: RouteObject[] = [
  ...authRoutes(),
  ...orgRoutes(),
  ...userRoutes(),
  ...rooteRoute(),
  { path: "*", element: <Navigate to="/" /> },
];

export const AppRouter = () => {
  return <RouterProvider router={createBrowserRouter(AppRoutes)} />;
};
