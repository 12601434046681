import { useQuestionTemplateQuery } from "../../hooks/questionTemplates";
import { APIError } from "../../api/shared";
import UpdateQuestionTemplateButton from "./UpdateQuestionTemplateButton";
import DeleteQuestionTemplateButton from "./DeleteQuestionTemplateButton";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Table from "../Table";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "../layout/DashboardLayout";

const QuestionTemplateTab = () => {
  const { orgId, questionTemplateId } = useValidatedParams([
    "orgId",
    "questionTemplateId",
  ]);
  const questionTemplateQuery = useQuestionTemplateQuery(
    orgId,
    questionTemplateId,
  );

  // Loading
  if (questionTemplateQuery.isLoading) {
    return (
      <DashboardLayout title="Question Templates">
        <p className="py-4 text-sm text-gray-500">Loading question template.</p>
      </DashboardLayout>
    );
  }

  // Insufficient permissions
  if (
    questionTemplateQuery.error instanceof APIError &&
    questionTemplateQuery.error.type === "PermissionError"
  ) {
    return (
      <DashboardLayout title="Question Templates">
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to view this question template."}
        </p>
      </DashboardLayout>
    );
  }

  // Error
  if (questionTemplateQuery.isError) {
    return (
      <DashboardLayout title="Question Templates">
        <p className="py-4 text-sm text-gray-500">
          Error viewing question template.
        </p>
      </DashboardLayout>
    );
  }

  const data = questionTemplateQuery.data.questions.map((question, index) => {
    return [
      <p key={`index-${index}`}>{index + 1}</p>,
      <p key={`content-${index}`}>{question.content}</p>,
    ];
  });

  return (
    <DashboardLayout
      title="Question Templates"
      actions={[
        <UpdateQuestionTemplateButton
          key="update-button"
          orgId={orgId}
          questionTemplate={questionTemplateQuery.data}
        />,
        <DeleteQuestionTemplateButton
          key="delete-button"
          orgId={orgId}
          questionTemplate={questionTemplateQuery.data}
        />,
      ]}
    >
      <Table
        columnHeaders={["", "Question"]}
        data={data}
        noDataIcon={QuestionMarkCircleIcon}
        noDataMessage="You don't have any questions in your question template yet."
        noDataButton={
          <UpdateQuestionTemplateButton
            orgId={orgId}
            questionTemplate={questionTemplateQuery.data}
          />
        }
      />
    </DashboardLayout>
  );
};

export default QuestionTemplateTab;
