import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { getWidgetTemplate, listWidgetTemplates } from "../api/widgetTemplates";

export const useWidgetTemplatesQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "widget_templates"],
    queryFn: () => listWidgetTemplates(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useWidgetTemplateQuery = (
  orgId: string,
  widgetTemplateId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "widget_templates", widgetTemplateId],
    queryFn: () => getWidgetTemplate(user, orgId, widgetTemplateId),
    enabled: !!user && !!widgetTemplateId,
    retry: defaultRetryFunc,
  });
};
