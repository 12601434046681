import {
  useUpdateUserInviteMutation,
  useUserInvitesQuery,
  useUserOrgPermissionsQuery,
} from "../hooks";
import { APIError } from "../api/shared";
import { useNavigate } from "react-router";
import info from "../info";
import { paths } from "@/config/paths";

export default function SingleTenantOrgInvite() {
  return (
    <div className="max-w-xl mx-auto">
      <div className="bg-white rounded-md p-8 shadow-md mt-16">
        <div className="flex flex-col p-8">
          <img src="/logo-1.svg" className="w-64 mx-auto mb-6" />
          <h2 className="mx-auto text-xl mb-6 text-gray-700">{info.orgId}</h2>
          <p className="text-sm max-w-lg text-gray-700 mb-6 text-center">
            To enter you must accept the invite.
          </p>
          <hr className="mb-6" />
          <OrgInvite />
          <hr className="mb-6" />
        </div>
      </div>
    </div>
  );
}

export function OrgInvite() {
  const userInvitesQuery = useUserInvitesQuery();
  const userOrgPermission = useUserOrgPermissionsQuery(info.orgId);
  const updateUserInviteMutation = useUpdateUserInviteMutation();
  const navigate = useNavigate();

  if (userOrgPermission.data !== undefined && userOrgPermission.data.size > 0) {
    navigate(paths.root.getHref());
  }

  // Loading
  if (userInvitesQuery.isLoading) {
    return (
      <p className="text-sm max-w-lg text-gray-700 mb-6 text-center">
        Loading invites.
      </p>
    );
  }

  // Insufficient permissions
  if (
    userInvitesQuery.error instanceof APIError &&
    userInvitesQuery.error.type === "PermissionError"
  ) {
    return (
      <p className="text-sm max-w-lg text-gray-700 mb-6 text-center">
        {"Sorry, you don't have invite contact your Capsa AI representative."}
      </p>
    );
  }

  // Error
  if (userInvitesQuery.isError) {
    return (
      <p className="text-sm max-w-lg text-gray-700 mb-6 text-center">
        {"Sorry, you don't have invite contact your Capsa AI representative."}
      </p>
    );
  }

  // Empty
  if (userInvitesQuery.data.length === 0) {
    return (
      <p className="text-sm max-w-lg text-gray-700 mb-6 text-center">
        {"Sorry, you don't have invite contact your Capsa AI representative."}
      </p>
    );
  }

  const invite = userInvitesQuery.data[0];

  return (
    <button
      onClick={() =>
        updateUserInviteMutation.mutate({
          inviteId: invite.id,
          orgId: invite.org_id,
          data: {
            response: "accept",
          },
        })
      }
      type="button"
      className="mb-6 mx-auto w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Accept
    </button>
  );
}
