import { Button, Text } from "capsa-ui";
import { useValidatedParams } from "@/utils/router";
import { useNavigate } from "react-router-dom";
import { paths } from "@/config/paths";
import { usePostHog } from "posthog-js/react";

export const EmptyDashboard = () => {
  const { dealId, orgId } = useValidatedParams(["dealId", "orgId"]);
  const posthog = usePostHog();
  const navigate = useNavigate();
  return (
    <div className="flex-center flex-col gap-3 w-full p-10 border border-primary border-dashed bg-primary rounded-layout">
      <Text>Upload more data sources to generate company dashboard.</Text>
      <div className="flex-center flex-wrap gap-2">
        <Button
          iconLeft="folderClosed"
          onClick={() =>
            navigate(
              paths.orgs.selected.deals.selected.files.getHref(orgId, dealId),
            )
          }
        >
          Files
        </Button>
        <Button
          iconLeft="website"
          onClick={() =>
            navigate(
              paths.orgs.selected.deals.selected.websites.getHref(
                orgId,
                dealId,
              ),
            )
          }
        >
          Websites
        </Button>
        {posthog.isFeatureEnabled("legal_entity_search") && (
          <Button
            iconLeft="registrySearch"
            onClick={() =>
              navigate(
                paths.orgs.selected.deals.selected.registrySearchJobs.getHref(
                  orgId,
                  dealId,
                ),
              )
            }
          >
            Registry Documents
          </Button>
        )}
      </div>
    </div>
  );
};
