import type React from "react";
import styles from "./FileManager.styles";
import {
  UNSTABLE_Tree as Tree,
  UNSTABLE_TreeItem as TreeItem,
  UNSTABLE_TreeItemContent as TreeItemContent,
  Button,
  Collection,
  Selection,
} from "react-aria-components";
import { Checkbox } from "../Checkbox";
import { Icon } from "../../icons";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { Text } from "../Text";
import { Spinner } from "../Spinner";
import { Tags } from "../Tags";
import { buildTree, getAllDescendantIds } from "../../../lib/files";

export interface TreeNode {
  id: string;
  parentId: string | null;
  type: "folder" | "file";
  name: string;
  filetype?: string;
  children?: TreeNode[];
}

export interface QueryState<TData = unknown, TError = unknown> {
  data: TData | undefined;
  dataUpdateCount: number;
  dataUpdatedAt: number;
  error: TError | null;
  errorUpdateCount: number;
  errorUpdatedAt: number;
  fetchFailureCount: number;
  fetchFailureReason: TError | null;
  fetchMeta: any; //eslint-disable-line
  isInvalidated: boolean;
  status: "loading" | "error" | "success";
  fetchStatus: "fetching" | "paused" | "idle";
}

export type QueriesState = Record<
  string,
  QueryState<unknown, undefined> | undefined
>;

export interface FileManagerProps {
  list: TreeNode[];
  selectedIds: string[];
  expandedIds: string[];
  onSelectionChange: (ids: string[]) => void;
  onExpandedChange: (ids: string[]) => void;
  disableAnimation?: boolean;
  queriesState: QueriesState;
}

export const FileManager = ({
  list,
  selectedIds,
  expandedIds,
  onSelectionChange,
  onExpandedChange,
  disableAnimation,
  queriesState,
}: FileManagerProps) => {
  const selectedKeys = useMemo(() => new Set(selectedIds), [selectedIds]);
  const expandedKeys = useMemo(() => new Set(expandedIds), [expandedIds]);

  const handleOnSelection = (keys: Selection) => {
    if (!onSelectionChange) return;

    const newSelectedIds = new Set(Array.from(keys) as string[]);
    const finalSelectedIds = new Set<string>(newSelectedIds);

    // Add newly selected items and their descendants, but not siblings
    newSelectedIds.forEach((id) => {
      if (!selectedIds.includes(id)) {
        const allDescendants = getAllDescendantIds(list, id);
        allDescendants.forEach((descId) => finalSelectedIds.add(descId));
      }
    });

    // Handle selection of parent items if all their children are selected
    list.forEach((item) => {
      if (item.parentId) {
        const parent = list.find(
          (parentItem) => parentItem.id === item.parentId,
        );
        if (parent) {
          const allChildrenSelected = list
            .filter((childItem) => childItem.parentId === parent.id)
            .every((childItem) => finalSelectedIds.has(childItem.id));

          if (allChildrenSelected) {
            finalSelectedIds.add(parent.id);
          }
        }
      }
    });

    // Handle deselection of items
    selectedIds.forEach((id) => {
      if (!newSelectedIds.has(id)) {
        // Deselect the item and all its descendants
        const allDescendants = getAllDescendantIds(list, id);
        allDescendants.forEach((descId) => finalSelectedIds.delete(descId));
        finalSelectedIds.delete(id);

        // Recursively deselect parent folders if any child is deselected
        let currentId = id;
        while (currentId) {
          const child = list.find((item) => item.id === currentId);
          if (child && child.parentId) {
            const parent = list.find((item) => item.id === child.parentId);
            if (parent) {
              // Check if all children of the parent are still selected
              const allChildrenSelected = list
                .filter((item) => item.parentId === parent.id)
                .every((childItem) => finalSelectedIds.has(childItem.id));

              if (!allChildrenSelected) {
                // If not all children are selected, mark the parent for deselection
                finalSelectedIds.delete(parent.id);
                currentId = parent.id;
              } else {
                break;
              }
            } else {
              break;
            }
          } else {
            break;
          }
        }
      }
    });

    onSelectionChange(Array.from(finalSelectedIds));
  };

  const handleOnExpanded = (keys: Selection) => {
    if (!onExpandedChange) return;
    onExpandedChange(Array.from(keys) as string[]);
  };

  const { treeStyle, itemStyle } = styles();

  const tree = useMemo(() => buildTree(list), [list]);

  const renderStatus = (type: "loading" | "error" | "empty") => (
    <div className="flex items-center gap-1.5 p-3 text-label">
      {type === "loading" && (
        <>
          <Spinner size="sm" />
          <Text color="label">Loading files...</Text>
        </>
      )}
      {type === "error" && (
        <>
          <Icon type="info" size="sm" className="text-danger" />
          <Text color="label">There was an error loading files.</Text>
        </>
      )}
      {type === "empty" && (
        <>
          <Icon type="info" size="sm" className="text-primary" />
          <Text color="label">No files found.</Text>
        </>
      )}
    </div>
  );

  if (queriesState.root?.status === "loading") {
    return renderStatus("loading");
  }

  if (queriesState.root?.status === "error") {
    return renderStatus("error");
  }

  return (
    <Tree
      aria-label="Files"
      selectionMode="multiple"
      items={tree}
      className={treeStyle()}
      selectedKeys={selectedKeys}
      expandedKeys={expandedKeys}
      onSelectionChange={handleOnSelection}
      onExpandedChange={handleOnExpanded}
      renderEmptyState={() => renderStatus("empty")}
    >
      {function renderTreeItem(item) {
        return (
          <TreeItem textValue={item.name} className={itemStyle()}>
            <TreeItemContent>
              {({ isExpanded, isSelected, level }) => {
                const {
                  labelStyle,
                  chevronStyle,
                  contentStyle,
                  iconStyle,
                  textStyle,
                } = styles({
                  isExpanded,
                  isSelected,
                });
                const levelOffset = (level - 1) * 12;
                if (queriesState[item.id]?.status === "loading") {
                  return renderStatus("loading");
                }
                if (queriesState[item.id]?.status === "error") {
                  return renderStatus("error");
                }
                return (
                  <motion.div
                    key={item.id}
                    className={contentStyle()}
                    initial={
                      level === 1 || disableAnimation
                        ? {}
                        : { height: 0, opacity: 0 }
                    }
                    animate={
                      level === 1 || disableAnimation
                        ? {}
                        : { height: "auto", opacity: 1 }
                    }
                    transition={{ duration: 0.1, ease: "easeOut" }}
                  >
                    {item.type === "folder" && item.children?.length ? (
                      <Button
                        aria-label="expand"
                        slot="chevron"
                        style={level ? { paddingLeft: levelOffset } : {}}
                        className="flex-center"
                      >
                        <Icon
                          type="chevronRight"
                          size="sm"
                          className={chevronStyle()}
                        />
                      </Button>
                    ) : (
                      <div
                        className="w-icon-sm shrink-0"
                        style={level ? { marginLeft: levelOffset } : {}}
                      />
                    )}
                    <div className={labelStyle()}>
                      <Checkbox slot="selection" checked={isSelected} />
                      <Icon
                        type={
                          item.type === "folder"
                            ? isExpanded
                              ? "folderOpen"
                              : "folderClosed"
                            : "file"
                        }
                        size="sm"
                        className={iconStyle()}
                      />
                      <Text className={textStyle()}>{item.name}</Text>
                      {item.filetype && (
                        <div className="flex-center h-4">
                          <Tags
                            tags={[
                              { label: `.${item.filetype}`, id: "filetype" },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  </motion.div>
                );
              }}
            </TreeItemContent>
            <Collection items={item.children}>{renderTreeItem}</Collection>
          </TreeItem>
        );
      }}
    </Tree>
  );
};
