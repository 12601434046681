import { useMemo, useRef } from "react";
import { useCreateDealFileMutation } from "../../hooks/deals";
import { useUserDealPermissionsQuery } from "../../hooks/userDealRoles";
import { checkForPassword, hasPermission } from "../../utils";
import { Button } from "capsa-ui";

interface Props {
  orgId: string;
  dealId: string;
}

export default function UploadFilesButton({ orgId, dealId }: Props) {
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
  const mutation = useCreateDealFileMutation(orgId, dealId);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      throw new Error("No files selected");
    }
    Array.from(e.target.files).forEach(async (file) => {
      try {
        const password = await checkForPassword(file);
        mutation.mutate({
          file: file,
          password: password,
        });
      } catch (e) {
        console.error(e);
        alert(`"${file.name}" could not be uploaded.`);
      }
    });
    e.target.value = "";
  };

  const status = useMemo(
    () => hasPermission(permissionsQuery, "files.create"),
    [permissionsQuery.status],
  );

  return (
    <>
      {status === "true" && (
        <input
          className="hidden"
          type="file"
          id="file"
          multiple
          ref={inputRef}
          onChange={handleChange}
        />
      )}
      <Button
        onClick={handleClick}
        loading={status === "loading"}
        disabled={status === "false" || status === "unknown"}
      >
        Upload Files
      </Button>
    </>
  );
}
