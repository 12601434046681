import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";
import QuestionTemplatesTab from "@/components/QuestionTemplatesTab";
import QuestionTemplatesCreate from "@/components/QuestionTemplatesTab/QuestionTemplatesCreate";
import QuestionTemplateTab from "@/components/QuestionTemplateTab";
import QuestionTemplatesUpdate from "@/components/QuestionTemplatesTab/QuestionTemplatesUpdate";

export const questionTemplateRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.templates.questionTemplates.path,
    handle: createHandle("Question Thread Templates", "question"),
    children: [
      {
        path: "",
        element: <QuestionTemplatesTab />,
      },
      {
        path: paths.orgs.selected.templates.questionTemplates.create.path,
        element: <QuestionTemplatesCreate />,
        handle: createHandle("Create"),
      },
      {
        path: paths.orgs.selected.templates.questionTemplates.selected.path,
        handle: createHandle(MatchParam.questionTemplateId),
        children: [
          {
            path: "",
            element: <QuestionTemplateTab />,
          },
          {
            path: paths.orgs.selected.templates.questionTemplates.selected
              .update.path,
            element: <QuestionTemplatesUpdate />,
            handle: createHandle("Update"),
          },
        ],
      },
    ],
  },
];
