import CompanyProfileTable from "./CompanyProfileTable";
import FileViewer, { FileViewerParams } from "../../../FileViewer";
import { useState } from "react";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

export default function CompanyProfile() {
  const { orgId, dealId, companyProfileId } = useValidatedParams([
    "orgId",
    "dealId",
    "companyProfileId",
  ]);
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  return (
    <DashboardLayout className="max-h-screen">
      <div className="grid grid-cols-2 min-h-0 gap-4">
        <div className="h-full min-h-0">
          <FileViewer
            orgId={orgId}
            dealId={dealId}
            {...fileViewerParams}
            setFileViewerParams={setFileViewerParams}
          />
        </div>
        <div className="h-full min-h-0">
          <CompanyProfileTable
            orgId={orgId}
            dealId={dealId}
            companyProfileId={companyProfileId}
            setFileViewerParams={setFileViewerParams}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
