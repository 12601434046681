import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBrandingConfig } from "../api/branding";
import { UpdateBrandingConfigBody } from "../types";

interface UpdateBrandingConfigVariables {
  orgId: string;
  body: UpdateBrandingConfigBody;
}

export const useUpdateBrandingConfigMutation = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ orgId, body }: UpdateBrandingConfigVariables) =>
      updateBrandingConfig(user, orgId, body),
    onSuccess: (_, { orgId }) => {
      queryClient.invalidateQueries({ queryKey: [orgId, "branding_config"] });
    },
  });
};
