import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { WidgetContentType, WidgetName, WidgetStatus } from "@/types/widgets";

export interface WidgetDetails {
  id: string;
  name: WidgetName;
  outputType: WidgetContentType;
  status: WidgetStatus;
}

export interface DashboardRead {
  id: string;
  orgId: string;
  dealId: string;
  widgets: WidgetDetails[];
}

export const getCurrentDashboard = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<DashboardRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/dashboards/current`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export interface DomainLogoRead {
  url: string;
}

export const getDomainLogo = async (
  user: User,
  domain: string,
): Promise<DomainLogoRead> => {
  const response = await fetch(`${baseURL}/domain-logo/${domain}`, {
    headers: {
      Authorization: `Bearer ${await user.getIdToken()}`,
    },
  });
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  return { url };
};
