import { paths } from "@/config/paths";
import info from "@/info";
import { Navigate, RouteObject } from "react-router-dom";

export const rooteRoute = (): RouteObject[] => [
  {
    path: paths.root.path,
    element: <Navigate to={paths.orgs.selected.getHref(info.orgId)} />,
  },
];
