import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import DealChatThreadExportsCreate from "@/components/DealTab/ChatSubTab/DealChatThreadExportsCreate";
import DealUserChatThread from "@/components/DealTab/ChatSubTab/DealUserChatThread";
import DealUserChatThreadList from "@/components/DealTab/ChatSubTab/DealUserChatThreadList";
import DealChatThread from "@/components/DealTab/ChatSubTab/DealChatThread";
import DealChatThreadList from "@/components/DealTab/ChatSubTab/DealChatThreadList";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";

export const chatThreadRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.chatThreads.path,
    handle: createHandle("Chat Threads", "chat"),
    children: [
      {
        path: "",
        element: <DealUserChatThreadList />,
      },
      {
        path: paths.orgs.selected.deals.selected.chatThreads.selected.path,
        handle: createHandle(MatchParam.dealChatThreadId),
        children: [
          {
            path: "",
            element: <DealUserChatThread />,
          },
          {
            path: paths.orgs.selected.deals.selected.chatThreads.selected.export
              .path,
            element: <DealChatThreadExportsCreate />,
            handle: createHandle("Export"),
          },
        ],
      },
    ],
  },
  {
    path: paths.orgs.selected.deals.selected.chatThreadsSu.path,
    handle: createHandle("Chat Threads", "chat"),
    children: [
      {
        path: "",
        element: <DealChatThreadList />,
      },
      {
        path: paths.orgs.selected.deals.selected.chatThreadsSu.selected.path,
        element: <DealChatThread />,
        handle: createHandle(MatchParam.dealChatThreadId),
      },
    ],
  },
];
