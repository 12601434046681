import { APIError } from "../../../api/shared";
import { useDealUserChatThreadsQuery } from "../../../hooks";
import { Link } from "react-router-dom";
import DeleteDealUserChatThreadButton from "./DeleteChatThreadButton";
import CreateDealUserChatThreadButton from "../CreateDealUserChatThreadButton";
import Table from "../../Table";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import { useValidatedParams } from "@/utils/router";
import { paths } from "@/config/paths";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

function _DealUserChatThreadList() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const dealChatThreadsQuery = useDealUserChatThreadsQuery(orgId, dealId);

  // Insufficient permissions
  if (
    dealChatThreadsQuery.error instanceof APIError &&
    dealChatThreadsQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list chat threads."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (dealChatThreadsQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="py-4 text-sm text-gray-500">
            Error listing chat threads.
          </p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!dealChatThreadsQuery.isLoading) {
    data = dealChatThreadsQuery.data.map((thread) => {
      return [
        <Link
          key={`link-${thread.id}`}
          to={paths.orgs.selected.deals.selected.chatThreads.selected.getHref(
            orgId,
            dealId,
            thread.id,
          )}
          className="text-indigo-600"
        >
          {thread.name || "New Thread"}
        </Link>,
        <time key={`time-${thread.id}`} dateTime={thread.created_at}>
          {new Date(thread.created_at).toLocaleString()}
        </time>,
        <div
          key={`actions-${thread.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteDealUserChatThreadButton
            orgId={orgId}
            dealId={dealId}
            dealChatThreadId={thread.id}
          />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Name", "Created", ""]}
      data={data}
      noDataIcon={ChatBubbleLeftRightIcon}
      noDataMessage={"You don't have any chat threads yet."}
      noDataButton={
        <CreateDealUserChatThreadButton orgId={orgId} dealId={dealId} />
      }
      isLoading={dealChatThreadsQuery.isLoading}
    />
  );
}

export default function DealUserChatThreadList() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);

  return (
    <DashboardLayout
      title="Chat Threads"
      description="Utilise Capsa’s assistant to extract insights and answers from the content in your deal folder. All answers are traceable with citations. Export your results as a .pdf or .doc file."
      actions={[
        <CreateDealUserChatThreadButton
          key={orgId}
          orgId={orgId}
          dealId={dealId}
        />,
      ]}
    >
      <_DealUserChatThreadList />
    </DashboardLayout>
  );
}
