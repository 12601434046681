import { clsx, type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

const merge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        "text-size-h1",
        "text-size-h2",
        "text-size-h3",
        "text-size-subheading",
        "text-size-body",
        "text-size-label",
        "text-size-caption",
      ],
      "text-color": [
        "text",
        "text-inherit",
        "text-current",
        "text-transparent",
        "text-oncolor",
        "text-label",
        "text-subdued",
        "text-primary",
        "text-primary-subdued",
        "text-primary-oncolor",
        "text-success",
        "text-success-subdued",
        "text-success-oncolor",
        "text-warning",
        "text-warning-subdued",
        "text-warning-oncolor",
        "text-danger",
        "text-danger-subdued",
        "text-danger-oncolor",
        "text-brand-text",
        "text-brand-mark",
      ],
      "bg-color": [
        "bg",
        "bg-inherit",
        "bg-current",
        "bg-transparent",
        "bg-selected",
        "bg-primary",
        "bg-primary-selected",
        "bg-primary-inverse",
        "bg-success",
        "bg-success-selected",
        "bg-success-inverse",
        "bg-warning",
        "bg-warning-selected",
        "bg-warning-inverse",
        "bg-danger",
        "bg-danger-selected",
        "bg-danger-inverse",
      ],
    },
    conflictingClassGroups: {
      "font-size": ["font-size"],
      "text-color": ["text-color"],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return merge(clsx(inputs));
}
