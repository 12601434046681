import { Button, List, Text, TextField } from "capsa-ui";
import { useCompanyWebsites } from "@/features/companies/hooks/useCompanyWebsites";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import { sendEvent } from "@/lib/posthog";
import React, { useEffect, useState } from "react";
import { Form } from "react-aria-components";
import { createURL } from "@/utils/urls";

const TabWebsite = () => {
  const {
    nextTab,
    previousTab,
    companyName,
    website,
    setWebsite,
    setWebsiteOptions,
    websiteOptions,
  } = useCreateCompanyStore();

  const companyWebsites = useCompanyWebsites(companyName);
  const [websiteValue, setWebsiteValue] = useState(website);

  useEffect(() => {
    sendEvent("viewed_website_tab", {
      feature: "create_company",
    });
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newWebsite = createURL(websiteValue);
    setWebsite(newWebsite?.origin || websiteValue);
    nextTab();
  };

  useEffect(() => {
    if (companyWebsites.data) {
      setWebsiteOptions(companyWebsites.data);
    }
  }, [companyWebsites.data]);

  const handleSelectWebsite = (item: string | null) => {
    if (item) {
      setWebsiteValue(new URL(item).origin);
    }
  };

  const handleSkip = () => {
    setWebsite("");
    nextTab();
  };

  return (
    <div className="flex flex-col gap-3">
      <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <TextField
            label="Website"
            value={websiteValue}
            onChange={(e) => setWebsiteValue(e.target.value)}
            autoFocus
          />
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1.5">
              <Text type="label" color="label" strong className="pl-3">
                Suggestions
              </Text>
              <div className="border rounded-layout h-fit overflow-scroll max-h-[200px]">
                <List
                  onSelection={handleSelectWebsite}
                  items={websiteOptions.map((w) => ({
                    id: w.companyWebsite,
                    label: new URL(w.companyWebsite).origin,
                    avatar: `https://www.google.com/s2/favicons?domain=${new URL(w.companyWebsite).origin}&sz=128`,
                  }))}
                  emptyText="No suggestions found."
                  loading={companyWebsites.isLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-1.5">
          <Button
            variant="outline"
            iconLeft="chevronLeft"
            onClick={previousTab}
          >
            Back
          </Button>
          <div className="flex-1" />
          <Button variant="outline" onClick={handleSkip}>
            Skip
          </Button>
          <Button
            type="submit"
            iconRight="chevronRight"
            onClick={() => {}}
            disabled={websiteValue.length === 0}
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TabWebsite;
