import { useCurrentDashboardQuery } from "../hooks/useCurrentDashboard";
import { useWidgetListQuery } from "../../widgets/hooks/widgets";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Button } from "capsa-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useGlobalStore } from "@/store";
import { useDealQuery } from "@/hooks/deals";
import { useValidatedParams } from "@/utils/router";
import { useWebsitesQuery } from "@/hooks/websites";
import { Topic } from "@/types/widgets";
import { EmptyDashboard } from "../components/EmptyDashboard";
import { DashboardWidgetContent } from "../components/DashboardWidgetContent";
import {
  getCompanyLogoDomain,
  getCompanyLogoFromWebsites,
} from "@/utils/image";
import { DashboardExport } from "../components/DashboardExport";
import {
  useListWidgetGenerationJobs,
  useRefreshWidgetJob,
} from "@/features/widgets/hooks/widgetGenerationJobs";
import { useDashboardStore } from "../store/useDashboardStore";
import { useDomainLogoQuery } from "../hooks/useDomainLogo";

export const DashboardView = () => {
  const { dealId, orgId } = useValidatedParams(["dealId", "orgId"]);
  const dashboardQuery = useCurrentDashboardQuery(orgId, dealId);
  const dealQuery = useDealQuery(orgId, dealId);
  const { setSelectedCompany, selectedCompany, closeDashboardDrawer } =
    useGlobalStore();
  const { setExportDialogOpen } = useDashboardStore();
  const websites = useWebsitesQuery(orgId, selectedCompany?.id || "");
  const widgetIds = dashboardQuery.data?.widgets.map((w) => w.id) || [];
  const widgetsQuery = useWidgetListQuery(orgId, dealId, widgetIds);
  const refreshWidgetJob = useRefreshWidgetJob(orgId, dealId);
  const refreshingWidgetJobs = useListWidgetGenerationJobs(orgId, dealId, [
    "pending",
    "in_progress",
  ]);
  const domainLogoQuery = useDomainLogoQuery(
    getCompanyLogoDomain(websites.data) || "",
  );

  useEffect(() => {
    return () => {
      closeDashboardDrawer();
    };
  }, []);

  useEffect(() => {
    dashboardQuery.data?.widgets?.forEach((widget) => {
      if (widget.status === "pending") {
        handleRefresh(widget.id);
      }
    });
  }, [dashboardQuery.data]);

  const refreshingIds = useMemo(() => {
    return (
      refreshingWidgetJobs.data
        ?.map((job) => job.widgetId)
        .filter((id) => widgetIds.includes(id)) || []
    );
  }, [refreshingWidgetJobs.data, widgetIds]);

  const refreshing = useMemo(() => {
    return refreshingIds.length > 0;
  }, [refreshingIds]);

  useEffect(() => {
    if (dealQuery.data) {
      setSelectedCompany(dealQuery.data);
    }
  }, [dealQuery.data]);

  const logoUrl = useMemo(
    () => getCompanyLogoFromWebsites(websites.data || []),
    [websites.data],
  );

  const noData = useMemo(() => {
    if (!dashboardQuery.data) return true;
    if (dashboardQuery.data.widgets.length === 0) return true;
    return widgetsQuery.every((w) => !w.data || w.data.empty);
  }, [dashboardQuery.data, widgetsQuery]);

  const handleRefresh = (id: string) => {
    refreshWidgetJob.mutate({ widgetId: id });
  };

  const triggerRefresh = useCallback(() => {
    widgetIds.forEach((id) => {
      handleRefresh(id);
    });
  }, [widgetIds, refreshWidgetJob]);

  const oneLinerContent = useMemo(() => {
    const widgets = dashboardQuery.data?.widgets || [];
    const oneLinerWidget = widgets.find((w) => w.name === "company_one_liner");
    if (!oneLinerWidget || widgetsQuery[0].data?.contentType !== "topic_list") {
      return undefined;
    }
    const content = widgetsQuery[0].data.content as Topic[];
    return content[0]?.entry?.options[0]?.content?.replace(/^- /, "");
  }, [dashboardQuery.data, widgetsQuery]);

  const mainWidgets = useMemo(
    () =>
      widgetsQuery
        .filter((w) => w.data?.name !== "company_one_liner")
        .map((w) => w.data)
        .filter((w) => !!w) || [],
    [widgetsQuery],
  );

  return (
    <>
      <DashboardExport
        companyName={dealQuery.data?.company_name || ""}
        companyDescription={oneLinerContent || ""}
        widgets={mainWidgets}
        companyLogo={domainLogoQuery.data?.url}
      />
      <DashboardLayout
        title={dealQuery.data?.company_name || "Company Dashboard"}
        avatar={logoUrl}
        description={oneLinerContent}
        refreshing={refreshing}
        loading={dashboardQuery.isLoading}
        actions={[
          <Button
            key="refresh"
            variant="outline"
            iconLeft={refreshing ? undefined : "refresh"}
            loading={refreshing}
            onClick={triggerRefresh}
            loadingText="Refreshing..."
          >
            Refresh
          </Button>,
          !noData && (
            <Button
              key="export"
              iconLeft="export"
              onClick={() => setExportDialogOpen(true)}
              loadingText="Exporting..."
            >
              Export
            </Button>
          ),
        ]}
      >
        {noData && <EmptyDashboard />}
        <div className="@container contain-layout">
          <div
            id="dashboard-grid"
            className="grid grid-cols-1 @5xl:grid-cols-2 gap-3"
          >
            {widgetsQuery?.map(
              (query, index) =>
                dashboardQuery.data?.widgets[index].name !==
                  "company_one_liner" && (
                  <DashboardWidgetContent
                    key={widgetIds[index]}
                    query={query}
                    onRefresh={() => handleRefresh(widgetIds[index])}
                    refreshing={refreshingIds.includes(widgetIds[index])}
                  />
                ),
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
