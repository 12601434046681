import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import DealsTab from "@/components/DealsTab";
import DealsCreate from "@/components/DealsTab/DealsCreate";
import CreateCompanyFeatureFlag from "@/features/companies/pages/CreateCompanyFeatureFlag";
import DealsUpdate from "@/components/DealsTab/DealsUpdate";
import { fileRoutes } from "./fileRoutes";
import { tableAggregationRoutes } from "./tableAggregationRoutes";
import { websiteRoutes } from "./websiteRoutes";
import { registrySearchJobRoutes } from "./registrySearchJobRoutes";
import { questionThreadRoutes } from "./questionThreadRoutes";
import { chatThreadRoutes } from "./chatThreadRoutes";
import { searchRoutes } from "./searchRoutes";
import { userRoutes } from "./userRoutes";
import { profileRoutes } from "./profileRoutes";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";
import { dashboardRoutes } from "./dashboardRoutes";
export const companyRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.path,
    handle: createHandle("Deals", "company"),
    children: [
      {
        path: "",
        element: <DealsTab />,
      },
      {
        path: paths.orgs.selected.deals.save.path,
        element: <DealsCreate />,
        handle: createHandle("Save"),
      },
      {
        path: paths.orgs.selected.deals.create.path,
        element: <CreateCompanyFeatureFlag />,
        handle: createHandle("Create"),
      },
      {
        path: paths.orgs.selected.deals.selected.path,
        handle: createHandle(MatchParam.dealId),
        children: [
          {
            path: paths.orgs.selected.deals.selected.update.path,
            element: <DealsUpdate />,
            handle: createHandle("Update"),
          },
          ...fileRoutes(),
          ...tableAggregationRoutes(),
          ...websiteRoutes(),
          ...registrySearchJobRoutes(),
          ...questionThreadRoutes(),
          ...chatThreadRoutes(),
          ...searchRoutes(),
          ...userRoutes(),
          ...profileRoutes(),
          ...dashboardRoutes(),
        ],
      },
    ],
  },
];
