import { APIError } from "../../../api/shared";
import DeleteWebsiteButton from "./DeleteWebsiteButton";
import { Link } from "react-router-dom";
import { useWebsitesQuery } from "../../../hooks/websites";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import CreateWebsiteButton from "../CreateWebsiteButton";
import Table from "../../Table";
import { usePostHog } from "posthog-js/react";
import { WebsiteCrawlActiveJobs } from "./WebsiteCrawlActiveJobs";
import { useValidatedParams } from "@/utils/router";
import { paths } from "@/config/paths";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

function StatusTag({ status }: { status: string }) {
  if (status === "completed") {
    return (
      <div className="bg-white w-fit p-1 rounded-md">
        <p className="">Processed</p>
      </div>
    );
  }
  if (status === "failed") {
    return (
      <div className="border-red-500 border w-fit p-1 rounded-md">
        <p className=" text-red-500">Failed</p>
      </div>
    );
  }
  return (
    <div className="border-orange-500 border w-fit p-1 rounded-md">
      <p className=" text-orange-500">Processing</p>
    </div>
  );
}

function _WebsiteList({ orgId, dealId }: { orgId: string; dealId: string }) {
  const websitesQuery = useWebsitesQuery(orgId, dealId);
  const posthog = usePostHog();

  // Insufficient permissions
  if (
    websitesQuery.error instanceof APIError &&
    websitesQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list websites."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (websitesQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Error listing websites.</p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!websitesQuery.isLoading) {
    data = websitesQuery.data.map((website) => {
      return [
        posthog.isFeatureEnabled("website_page") ? (
          <Link
            to={paths.orgs.selected.deals.selected.websites.selected.getHref(
              orgId,
              dealId,
              website.id,
            )}
            key={`url-${website.id}`}
          >
            <p>{website.url}</p>
          </Link>
        ) : (
          <p key={`url-${website.id}`}>{website.url}</p>
        ),
        <time key={`created-${website.id}`} dateTime={website.created_at}>
          {new Date(website.created_at).toLocaleString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </time>,
        <p key={`items-${website.id}`}>{website.items_ingested}</p>,
        <StatusTag
          key={`status-${website.id}`}
          status={website.ingestion_status}
        />,
        <div
          key={`actions-${website.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteWebsiteButton
            orgId={orgId}
            dealId={dealId}
            websiteId={website.id}
          />
        </div>,
      ];
    });
  }

  return (
    <>
      <WebsiteCrawlActiveJobs />
      <Table
        columnHeaders={["URL", "Created", "Webpages", "Status", ""]}
        data={data}
        noDataIcon={GlobeAltIcon}
        noDataMessage={"You don't have any websites yet."}
        noDataButton={<CreateWebsiteButton orgId={orgId} dealId={dealId} />}
        isLoading={websitesQuery.isLoading}
      />
    </>
  );
}

export default function WebsitesList() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);

  return (
    <DashboardLayout
      title="Websites"
      description={
        <>
          {`Connect your deal folder to relevant company websites and easily add new sites online via their URL, or by using our `}
          <a
            className="text-primary underline"
            href="https://chromewebstore.google.com/detail/capsa-ai/mpidolfbpgpljbebeobmbhnhkdicfcnb"
            target="_blank"
            rel="noreferrer"
          >{`Chrome Extension.`}</a>
        </>
      }
      actions={[
        <CreateWebsiteButton key={orgId} orgId={orgId} dealId={dealId} />,
      ]}
    >
      <_WebsiteList orgId={orgId} dealId={dealId} />
    </DashboardLayout>
  );
}
