import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createWidgetTemplate } from "../api/widgetTemplates";
import { CreateWidgetTemplateBody } from "../types";

interface CreateWidgetTemplateVariables {
  orgId: string;
  body: CreateWidgetTemplateBody;
}

export const useCreateWidgetTemplateMutation = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ orgId, body }: CreateWidgetTemplateVariables) =>
      createWidgetTemplate(user, orgId, body),
    onSuccess: (_, { orgId }) => {
      queryClient.invalidateQueries({ queryKey: [orgId, "widget_templates"] });
    },
  });
};
