import { paths } from "@/config/paths";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

interface Props {
  orgId: string;
  dealId: string;
  tableAggregationId: string;
}

export default function UpdateTableAggregationIconButton({
  orgId,
  dealId,
  tableAggregationId,
}: Props) {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() =>
        navigate(
          paths.orgs.selected.deals.selected.tableAggregations.selected.update.getHref(
            orgId,
            dealId,
            tableAggregationId,
          ),
        )
      }
      className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
    >
      <PencilSquareIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
    </button>
  );
}
