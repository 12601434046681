import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import CompanyProfile from "@/components/DealTab/CompanyProfileSubtab/view/CompanyProfile";
import CompanyProfileList from "@/components/DealTab/CompanyProfileSubtab/list/CompanyProfileList";
import CompanyProfileCreate from "@/components/DealTab/CompanyProfileSubtab/create/CompanyProfileCreate";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";

export const profileRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.companyProfiles.path,
    handle: createHandle("Data Extractions", "extract"),
    children: [
      {
        path: "",
        element: <CompanyProfileList />,
      },
      {
        path: paths.orgs.selected.deals.selected.companyProfiles.create.path,
        element: <CompanyProfileCreate />,
        handle: createHandle("Create"),
      },
      {
        path: paths.orgs.selected.deals.selected.companyProfiles.selected.path,
        element: <CompanyProfile />,
        handle: createHandle(MatchParam.companyProfileId),
      },
    ],
  },
];
