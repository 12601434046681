import { APIError } from "../../../api/shared";
import DeleteFileButton from "./DeleteFileButton";
import { useDealFilesQuery, useDealQuery } from "../../../hooks/deals";
import { Link, useNavigate } from "react-router-dom";
import UploadFilesButton from "../UploadFilesButton";
import UploadFolderButton from "../UploadFolderButton";
import { FolderIcon } from "@heroicons/react/24/outline";
import Table from "../../Table";
import React, { useEffect, useMemo } from "react";
import SyncSharepointModal from "@/features/sharepoint/components/SyncSharepointModal";
import { SharepointSync } from "@/features/sharepoint/components/SharepointSync";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import { DocumentUploadActiveJobs } from "./DocumentUploadActiveJobs";
import { useInitSharepoint } from "@/features/sharepoint/hooks/config";
import { useGlobalStore } from "@/store";
import { useValidatedParams } from "@/utils/router";
import { paths } from "@/config/paths";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Button, Text } from "capsa-ui";
import { FileMetadata } from "../../../api/dealFiles";
import { usePostHog } from "posthog-js/react";

function StatusTag({ status }: { status: string }) {
  if (status === "completed") {
    return (
      <div className="bg-white w-fit p-1 rounded-md">
        <p className="">Processed</p>
      </div>
    );
  }
  if (status === "failed") {
    return (
      <div className="border-red-500 border w-fit p-1 rounded-md">
        <p className=" text-red-500">Failed</p>
      </div>
    );
  }
  return (
    <div className="border-orange-500 border w-fit p-1 rounded-md">
      <p className=" text-orange-500">Processing</p>
    </div>
  );
}

function capitalize(str: string) {
  // split by _ and capitalize each word
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function Tags({ metadata }: { metadata: FileMetadata }) {
  if (!metadata) return null;

  const getFileTypeDescription = (type: string) => {
    const descriptions: Record<string, string> = {
      cim: "This file is a CIM or equivalent report",
      teaser: "This file is a Teaser document",
      annual_report: "This file is an Annual Report",
      consolidated_annual_report: "This file is a Consolidated Annual Report",
    };
    return descriptions[type] || "Unknown file type";
  };

  const getLanguageName = (code: string) => {
    const languages: Record<string, string> = {
      en: "English",
      de: "German",
    };
    return languages[code] || code.toUpperCase();
  };

  const getCountryName = (code: string) => {
    const countries: Record<string, string> = {
      UK: "United Kingdom",
      US: "United States",
      DE: "Germany",
      FR: "France",
    };
    return countries[code] || code;
  };

  return (
    <div className="flex flex-col gap-2 p-2">
      <div className="flex flex-wrap gap-1">
        {metadata && metadata.file_type && (
          <span
            className="px-2 py-0.5 rounded-full text-xs bg-blue-100 text-blue-800 cursor-help"
            title={getFileTypeDescription(metadata.file_type)}
          >
            {metadata.file_type === "cim"
              ? "CIM"
              : capitalize(metadata.file_type)}
          </span>
        )}
        {metadata.language && (
          <span
            className="px-2 py-0.5 rounded-full text-xs bg-purple-100 text-purple-800 cursor-help"
            title={`The file main language is ${getLanguageName(metadata.language)}`}
          >
            {metadata.language.toUpperCase()}
          </span>
        )}
        {metadata.covered_countries?.map((country) => (
          <span
            key={country}
            className="px-2 py-0.5 rounded-full text-xs bg-yellow-100 text-yellow-800 cursor-help"
            title={`This document contains financial or business information related to operations in ${getCountryName(country)}`}
          >
            {country}
          </span>
        ))}
      </div>
      {metadata.covered_years && metadata.covered_years.length > 0 && (
        <div className="flex flex-wrap gap-1">
          {metadata.covered_years.map((year) => (
            <span
              key={year}
              className="px-2 py-0.5 rounded-full text-xs bg-green-100 text-green-800 cursor-help"
              title={`The file contains information for year ${year}`}
            >
              {year}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

function _FilesList() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const filesQuery = useDealFilesQuery(orgId, dealId);
  useInitSharepoint();
  const { SharepointImportButton } = useSharepointStore();
  const navigate = useNavigate();
  const posthog = usePostHog();
  // Insufficient permissions
  if (
    filesQuery.error instanceof APIError &&
    filesQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list files."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (filesQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="py-4 text-sm text-gray-500">Error listing files.</p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!filesQuery.isLoading) {
    data = filesQuery.data.map((file) => {
      return [
        <Link
          key={file.id}
          to={paths.orgs.selected.deals.selected.files.selected.getHref(
            orgId,
            dealId,
            file.id,
          )}
          className="text-indigo-600"
        >
          {file.name}
        </Link>,
        <div key={`${file.id}-tables`} className="flex items-center gap-3">
          <Text className="min-w-8">
            {file.file_metadata.num_tables ?? "-"}
          </Text>
          {file.file_metadata.num_tables &&
            file.file_metadata.num_tables > 0 && (
              <Button
                variant="ghost"
                iconLeft="table"
                size="sm"
                className="-ml-0.5 border"
                onClick={() => {
                  navigate(
                    paths.orgs.selected.deals.selected.files.selected.getHref(
                      orgId,
                      dealId,
                      file.id,
                    ),
                  );
                }}
              >
                Export
              </Button>
            )}
        </div>,
        <time key={`${file.id}-created_at`} dateTime={file.created_at}>
          {new Date(file.created_at).toLocaleString()}
        </time>,
        ...(posthog.isFeatureEnabled("show_file_tags_on_deal_files_view")
          ? [
              file.file_metadata ? (
                <Tags key={`${file.id}-tags`} metadata={file.file_metadata} />
              ) : (
                <React.Fragment key={`${file.id}-tags`} />
              ),
            ]
          : []),
        <StatusTag key={`${file.id}-status`} status={file.ingestion_status} />,
        <div key={`${file.id}-error_message`}>
          <p>{file.ingestion_error_message}</p>
        </div>,
        <div
          key={`${file.id}-actions`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteFileButton orgId={orgId} dealId={dealId} fileId={file.id} />
        </div>,
      ];
    });
  }

  return (
    <>
      <SyncSharepointModal />
      <div className="flex flex-col gap-1">
        <SharepointSync />
        <DocumentUploadActiveJobs />
      </div>
      <div className="-mt-2">
        <Table
          columnHeaders={[
            "Name",
            "Tables",
            "Uploaded",
            ...(posthog.isFeatureEnabled("show_file_tags_on_deal_files_view")
              ? ["Tags"]
              : []),
            "Status",
            "",
            "",
          ]}
          data={data}
          noDataIcon={FolderIcon}
          noDataMessage={"You don't have any files yet."}
          noDataButton={
            <div className="flex gap-2 flex-wrap">
              <UploadFilesButton orgId={orgId} dealId={dealId} />
              {SharepointImportButton && <SharepointImportButton />}
            </div>
          }
          isLoading={filesQuery.isLoading}
        />
      </div>
    </>
  );
}

export default function FilesList() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const dealQuery = useDealQuery(orgId, dealId);
  const { SharepointImportButton, reset, sharepointEnabled } =
    useSharepointStore();
  const { setSelectedCompany } = useGlobalStore();
  const filesQuery = useDealFilesQuery(orgId, dealId);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (dealQuery.data) {
      setSelectedCompany(dealQuery.data);
    }
  }, [dealQuery.data]);

  const buttons = useMemo(() => {
    if (filesQuery.data?.length === 0) {
      return [];
    }
    const newButtons = [
      <UploadFolderButton orgId={orgId} dealId={dealId} key="upload folder" />,
      <UploadFilesButton orgId={orgId} dealId={dealId} key="upload file" />,
    ];
    if (SharepointImportButton) {
      newButtons.unshift(<SharepointImportButton />);
    }
    return newButtons;
  }, [sharepointEnabled, dealId, filesQuery.data]);

  return (
    <DashboardLayout
      title="Files"
      description="Upload all your relevant files, allowing you to export tables as needed (.pdf, .docx, .pptx and .eml supported)."
      actions={buttons}
    >
      <_FilesList />
    </DashboardLayout>
  );
}
