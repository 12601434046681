import { withVariants } from "../../../lib/withVariants";

const styles = withVariants({
  base: "text",
  variants: {
    type: {
      hero: "text-size-hero font-strong",
      h1: "text-size-h1 font-strong",
      h2: "text-size-h2 font-strong",
      h3: "text-size-h3 font-strong",
    },
    truncated: {
      true: "whitespace-nowrap overflow-hidden text-ellipsis",
    },
  },
});

export default styles;
