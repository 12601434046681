import { RouteObject } from "react-router-dom";
import FilesList from "@/components/DealTab/FilesSubTab/FilesList";
import File from "@/components/DealTab/FilesSubTab/File";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";

export const fileRoutes = (): RouteObject[] => [
  { path: "", element: <FilesList />, handle: createHandle("Files") },
  {
    path: paths.orgs.selected.deals.selected.files.path,
    handle: createHandle("Files", "folderOpen"),
    children: [
      {
        path: "",
        element: <FilesList />,
      },
      {
        path: paths.orgs.selected.deals.selected.files.selected.path,
        element: <File />,
        handle: createHandle(MatchParam.fileId),
      },
    ],
  },
];
