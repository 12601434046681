import { TreeNode } from "../components/ui/FileManager";
import _ from "lodash";

export const buildTree = <T extends TreeNode>(items: T[]): T[] => {
  const grouped = _.groupBy(items, (item) => item.parentId || "root");

  const buildTree = (parentId: string | null): T[] => {
    const children = grouped[parentId || "root"] || [];
    return children.map((item) => ({
      ...item,
      children: buildTree(item.id),
    }));
  };

  return buildTree(null);
};

export const getAllDescendantIds = (
  flatList: TreeNode[],
  parentId: string,
): string[] => {
  const descendants: string[] = [];

  const collectDescendants = (id: string) => {
    flatList.forEach((item) => {
      if (item.parentId === id) {
        descendants.push(item.id);
        collectDescendants(item.id);
      }
    });
  };

  collectDescendants(parentId);
  return descendants;
};
