import LoggedOutDashboardLayout from "@/components/LoggedOutDashboardLayout";
import UnauthenticatedRoute from "@/components/UnauthenticatedRoute";
import { paths } from "@/config/paths";
import { RouteObject } from "react-router-dom";
import Authenticate from "@/components/Authenticate";
import UnverifiedRoute from "@/components/UnverifiedRoute";
import VerifyEmail from "@/components/VerifyEmail";
import TermsAndConditions from "@/components/TermsAndConditions";
import PrivacyPolicy from "@/components/PrivacyPolicy";
import UserDashboardLayout from "@/components/UserDashboardLayout";
import { createHandle } from "@/utils/router";

export const authRoutes = (): RouteObject[] => [
  {
    path: paths.auth.login.path,
    element: (
      <UnauthenticatedRoute>
        <LoggedOutDashboardLayout />
      </UnauthenticatedRoute>
    ),
    children: [
      {
        path: "",
        element: <Authenticate />,
        handle: createHandle("Login"),
      },
    ],
  },
  {
    path: paths.auth.terms.path,
    element: <LoggedOutDashboardLayout />,
    children: [
      {
        path: "",
        element: <TermsAndConditions />,
        handle: createHandle("Terms and Conditions"),
      },
    ],
  },
  {
    path: paths.auth.privacy.path,
    element: <LoggedOutDashboardLayout />,
    children: [
      {
        path: "",
        element: <PrivacyPolicy />,
        handle: createHandle("Privacy Policy"),
      },
    ],
  },
  {
    path: paths.auth.verify.path,
    element: (
      <UnverifiedRoute>
        <UserDashboardLayout />
      </UnverifiedRoute>
    ),
    children: [
      {
        path: "",
        element: <VerifyEmail />,
        handle: createHandle("Verify Email"),
      },
    ],
  },
];
