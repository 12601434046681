import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDealQuery, useUpdateDealMutation } from "../../hooks/deals";
import { DealRead } from "../../api/deals";
import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useUserDealPermissionsQuery } from "../../hooks/userDealRoles";
import { hasPermission } from "../../utils";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "../layout/DashboardLayout";

interface FormValues {
  name: string;
  companyName: string;
  companyAliases: { value: string }[];
}

function DealsUpdateForm({ deal }: { deal: DealRead }) {
  const permissionQuery = useUserDealPermissionsQuery(deal.org_id, deal.id);
  const { control, handleSubmit, reset, register } = useForm<FormValues>({
    defaultValues: {
      name: deal.name,
      companyName: deal.company_name,
      companyAliases: deal.company_aliases.map((value) => ({ value })),
    },
  });
  const navigate = useNavigate();
  const mutation = useUpdateDealMutation(deal.org_id, deal.id);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "companyAliases",
  });
  switch (hasPermission(permissionQuery, "deals.update")) {
    case "loading":
      return (
        <div className="flex border-b-[1px] border-gray-200 pb-4">
          <p className="text-sm max-w-lg text-gray-700">Loading</p>
        </div>
      );
    case "false":
      return (
        <div className="flex border-b-[1px] border-gray-200 pb-4">
          <p className="text-sm max-w-lg text-gray-700">
            You do not have permission to update this deal.
          </p>
        </div>
      );
    case "true":
      return (
        <div>
          <form
            onSubmit={handleSubmit((values) => {
              mutation.mutate(
                {
                  name: values.name,
                  company_name: values.companyName,
                  company_aliases: values.companyAliases.map(
                    (alias) => alias.value,
                  ),
                },
                {
                  onSuccess: () => {
                    navigate(
                      paths.orgs.selected.deals.selected.getHref(
                        deal.org_id,
                        deal.id,
                      ),
                    );
                    reset();
                  },
                },
              );
            })}
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Deal Name
                    </label>
                    <div className="mt-1">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            id="name"
                            type="text"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            {...field}
                            value={field.value ?? ""}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company Name
                    </label>
                    <div className="mt-1">
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <input
                            id="companyName"
                            type="text"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            {...field}
                            value={field.value ?? ""}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company Aliases
                    </label>
                    <div className="space-y-2">
                      {fields.map((field, index) => (
                        <div className="mt-1" key={index}>
                          <div className="flex">
                            <input
                              key={field.id}
                              {...register(`companyAliases.${index}.value`)}
                              type="text"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                            <button
                              title="Remove question"
                              type="button"
                              onClick={() => remove(index)}
                              className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                            >
                              <XMarkIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        append({ value: "" });
                      }}
                      type="button"
                      className="rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <PlusIcon className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-6">
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    navigate(paths.orgs.selected.deals.getHref(deal.org_id))
                  }
                  type="button"
                  className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    default:
      return (
        <div className="flex border-b-[1px] border-gray-200 pb-4">
          <p className="text-sm max-w-lg text-gray-700">
            Error loading permissions.
          </p>
        </div>
      );
  }
}

export default function DealsUpdate() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const dealQuery = useDealQuery(orgId as string, dealId as string);
  if (dealQuery.isLoading) {
    return (
      <DashboardLayout>
        <p>Loading...</p>
      </DashboardLayout>
    );
  }

  if (dealQuery.isError) {
    return (
      <DashboardLayout>
        <p>Error loading deal.</p>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout title="Update your deal">
      <DealsUpdateForm deal={dealQuery.data} />
    </DashboardLayout>
  );
}
