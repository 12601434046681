import type React from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { TooltipIcon } from "../Tooltip";
import { cn } from "../../../lib/utils";
import { Text } from "../Text";
import { Button } from "../Button";
import { Icon } from "../../icons";

export interface TextFieldProps
  extends Omit<React.ComponentPropsWithoutRef<"input">, "size"> {
  label?: string;
  autoHighlight?: boolean;
  tooltip?: string;
  invalid?: boolean;
  description?: string;
  errorMessage?: string;
  inputClassName?: string;
  labelClassName?: string;
  prependElement?: React.ReactNode;
  autoSize?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      type,
      autoHighlight,
      invalid,
      label,
      onFocus,
      onBlur,
      tooltip,
      description,
      errorMessage,
      inputClassName,
      labelClassName,
      prependElement,
      autoSize,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);
    const elementRef = useRef(null);
    const inputRef = (ref || elementRef) as React.RefObject<HTMLInputElement>;
    const widthSpanRef = useRef<HTMLSpanElement>(null);

    const [inputWidth, setInputWidth] = useState<number | null>(null);

    useEffect(() => {
      if (autoHighlight) {
        inputRef.current?.select();
      }
    }, []);

    useEffect(() => {
      if (autoSize) {
        const width = widthSpanRef.current?.clientWidth;
        setInputWidth(width ? width + 10 : null);
      }
    }, [props.value]);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus?.(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur?.(event);
    };

    const clearEvent = {
      target: { value: "" },
    } as React.ChangeEvent<HTMLInputElement>;

    return (
      <div
        className={cn(
          "w-full flex flex-col gap-1.5 relative",
          className,
          props.disabled && "disabled select-none",
        )}
      >
        {label && (
          <label
            htmlFor={props.id || props.name || label}
            className={cn(
              "flex items-center gap-1 text-label text-size-label font-strong shrink-0",
              focused && "text-primary",
              labelClassName,
            )}
          >
            {label}
            {description && (
              <Text className="text-subdued text-size-caption">
                ({description})
              </Text>
            )}
            {props.required && <span className="text-primary">*</span>}
            {tooltip && <TooltipIcon>{tooltip}</TooltipIcon>}
          </label>
        )}
        <div className="relative">
          {type === "file" && (
            <Button
              className="absolute top-1.5 left-1.5 pointer-events-none"
              onClick={(e) => e.preventDefault()}
              iconRight="file"
              variant="outline"
              size="sm"
            >
              Select File
            </Button>
          )}
          {type === "search" && (
            <Icon
              type="search"
              size="sm"
              className={cn(
                "absolute left-2.5 top-1/2 transform -translate-y-1/2 text-subdued",
                focused && "text-primary",
              )}
            />
          )}
          {prependElement && (
            <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2">
              {prependElement}
            </div>
          )}
          <span
            aria-hidden="true"
            className="pointer-events-none absolute text-size-body px-3 h-10 -z-50 opacity-0"
            ref={widthSpanRef}
          >
            {props.value}
          </span>
          <input
            type={type}
            className={cn(
              "focus-field outline-none border-base text-size-body text h-10 rounded px-3 placeholder:text-subdued w-full",
              props.disabled && "select-none",
              invalid && "border-danger",
              type === "file" &&
                "cursor-pointer rounded border bg-background py-2 pl-[6.5rem] pr-2 file:hidden",
              type === "search" && "px-9 no-cancel",
              prependElement && "pl-9",
              type === "number" &&
                "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none",
              inputClassName,
            )}
            style={inputWidth ? { width: inputWidth } : undefined}
            ref={inputRef}
            aria-invalid={invalid}
            aria-label={label}
            name={props.name || label}
            id={props.id || label}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          />
          {type === "search" &&
            props.value &&
            props.value.toString().length > 0 && (
              <Button
                className="absolute top-1.5 right-1.5"
                onClick={() => props.onChange?.(clearEvent)}
                iconLeft="cancel"
                variant="outline"
                size="sm"
              />
            )}
        </div>
        {errorMessage && (
          <Text className="flex items-center gap-1 text-danger text-size-label shrink-0">
            {errorMessage}
          </Text>
        )}
      </div>
    );
  },
);
TextField.displayName = "TextField";
