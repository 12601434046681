import { PeriodsPerFile, TablesPerPeriod } from "@/api/tableAggregationLayouts";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Dispatch, SetStateAction } from "react";
import { useWizard } from "react-use-wizard";

export interface LayoutPredictionConfig {
  periodsPerFile: PeriodsPerFile;
  tablesPerPeriod: TablesPerPeriod;
}

function OptionButton({
  label,
  description,
  selected,
  disabled,
  onClick,
}: {
  label: string;
  description: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}) {
  return (
    <button
      className={
        "max-w-80 h-40 rounded-lg border-2 p-4 text-left align-text-top inline-flex flex-col disabled:opacity-30" +
        (selected ? " border-indigo-700 border-2" : "")
      }
      disabled={disabled}
      onClick={onClick}
    >
      <div className="flex flex-row w-full mb-2">
        <div className="text-size-label font-strong">{label}</div>
        <CheckCircleIcon
          className={
            "h-4 w-4 ml-auto text-indigo-700 opacity-0" +
            (selected ? " opacity-100" : "")
          }
        />
      </div>
      <div className="text-body text-label text-size-body">{description}</div>
    </button>
  );
}

export default function ConfigureLayoutPrediction({
  layoutPredictionConfig,
  setLayoutPredictionConfig,
}: {
  orgId: string;
  dealId: string;
  layoutPredictionConfig: LayoutPredictionConfig;
  setLayoutPredictionConfig: Dispatch<SetStateAction<LayoutPredictionConfig>>;
}) {
  const { nextStep, previousStep } = useWizard();

  const handlePreviousStep = () => {
    previousStep();
  };

  const handleNextStep = () => {
    nextStep();
  };

  const handleClick = ({
    periodsPerFile,
    tablesPerPeriod,
  }: {
    periodsPerFile?: PeriodsPerFile;
    tablesPerPeriod?: TablesPerPeriod;
  }) => {
    setLayoutPredictionConfig((prev) => {
      return {
        ...prev,
        periodsPerFile: periodsPerFile || prev.periodsPerFile,
        tablesPerPeriod: tablesPerPeriod || prev.tablesPerPeriod,
      };
    });
  };

  return (
    <div>
      <h1 className="text-sm font-semibold mb-2">Configure</h1>
      {/* <div className="mb-8">
        <div className="mb-4 text-size-body">
          How many financial periods does each file contain?
        </div>
        <div className="flex flex-row space-x-4 w-full">
          <OptionButton
            label="One"
            description="Each file contains a single financial period. E.g. an account statement for 2018 or a P&L for January 2018."
            selected={layoutPredictionConfig.periodsPerFile === "one"}
            onClick={() => handleClick({ periodsPerFile: "one" })}
          />
          <OptionButton
            label="Multiple"
            description="Each file contains multiple financial periods. E.g. a single file containing P&Ls for January 2018, February 2018, March 2018, etc."
            selected={layoutPredictionConfig.periodsPerFile === "multiple"}
            disabled={true}
          />
        </div>
      </div> */}
      <div>
        <div className="mb-4 text-size-body">
          How many tables are there in each file?
        </div>
        <div className="flex flex-row space-x-4 w-full">
          <OptionButton
            label="One"
            description="Each file should be treated as one table, e.g. a P&L table per file."
            selected={layoutPredictionConfig.tablesPerPeriod === "one"}
            onClick={() => handleClick({ tablesPerPeriod: "one" })}
          />
          <OptionButton
            label="Multiple"
            description="Each file contains multiple table, e.g. a P&L and Balance Sheet per file."
            selected={layoutPredictionConfig.tablesPerPeriod === "multiple"}
            onClick={() => handleClick({ tablesPerPeriod: "multiple" })}
          />
        </div>
      </div>
      <div className="mt-4 flex flex-row grow justify-end space-x-2">
        <button
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          onClick={() => handlePreviousStep()}
        >
          Previous
        </button>
        <button
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          onClick={() => handleNextStep()}
        >
          Next
        </button>
      </div>
    </div>
  );
}
