import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useListDealNodesQuery } from "../../../hooks";
import FileViewer, { FileViewerParams } from "../../FileViewer";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

function SearchResults({
  orgId,
  dealId,
  query,
  limit,
  setFileViewerParams,
}: {
  orgId: string;
  dealId: string;
  query: string;
  limit: number;
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  const [open, setOpen] = useState<Map<string, boolean>>(new Map());
  const nodesQuery = useListDealNodesQuery(orgId, dealId, query, limit);

  useEffect(() => {
    setOpen(new Map());
  }, [query]);

  const updateOpen = (k: string, v: boolean) => {
    setOpen(new Map(open.set(k, v)));
  };

  if (nodesQuery.isLoading) {
    return (
      <p className="mt-2 text-sm text-gray-500">Loading search results...</p>
    );
  }

  if (nodesQuery.isError) {
    return (
      <p className="mt-2 text-sm text-gray-500">
        Error loading search results.
      </p>
    );
  }
  return (
    <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
      {nodesQuery.data.map((node) => (
        <li key={node.id}>
          <div className="py-4 flex">
            <div className="overflow-hidden w-full">
              <div className="flex items-center justify-between">
                <p
                  className="truncate text-sm font-medium text-indigo-600 whitespace-pre hover:cursor-pointer"
                  onClick={() => {
                    if ("file_id" in node) {
                      setFileViewerParams({
                        fileId: node.file_id,
                        pageNumber: node.metadata.page_number,
                        websiteId: null,
                        webpageId: null,
                        waiting: true,
                      });
                    } else {
                      setFileViewerParams({
                        fileId: null,
                        pageNumber: null,
                        websiteId: node.website_id,
                        webpageId: node.webpage_id,
                        waiting: false,
                      });
                    }
                  }}
                >
                  {node.metadata.file_name}, Page {node.metadata.page_number}
                </p>
                <p className="text-gray-400 ml-auto mr-4 text-sm">
                  {node.similarity?.toFixed(2)}
                </p>
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500 hover:cursor-pointer">
                {open.get(node.id) ? (
                  <p
                    className="whitespace-pre-wrap"
                    onClick={() => updateOpen(node.id, false)}
                  >
                    {node.metadata.text}
                  </p>
                ) : (
                  <p
                    className="whitespace-pre-wrap line-clamp-3"
                    onClick={() => updateOpen(node.id, true)}
                  >
                    {node.metadata.text}
                  </p>
                )}
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

function _DealQuestionThread({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const [query, setQuery] = useState("");
  const [submittedQuery, setSubmittedQuery] = useState("");
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });
  return (
    <div className="grid grid-cols-2 flex-1 min-h-0 gap-4">
      <div className="h-full min-h-0">
        <FileViewer
          orgId={orgId}
          dealId={dealId}
          {...fileViewerParams}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
      <div className="h-full flex flex-col min-h-0">
        <div className="flex flex-row pb-2">
          <input
            type="search"
            className="rounded-md w-full h-8 mr-1 focuse:outline-none focus:ring-1 focus:ring-indigo-600"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSubmittedQuery(query);
              }
            }}
          />
          <button className="bg-indigo-600 rounded-md h-8 w-8">
            <MagnifyingGlassIcon className="h-4 w-4 text-white m-auto" />
          </button>
        </div>
        {submittedQuery ? (
          <div className="flex-grow overflow-auto h-full">
            <SearchResults
              orgId={orgId}
              dealId={dealId}
              query={submittedQuery}
              limit={20}
              setFileViewerParams={setFileViewerParams}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default function DealQuestionThread() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);

  return (
    <DashboardLayout
      title="Search"
      description="Quickly search across all files in your deal folder, with results traceable through citations linking back to their original sources."
      className="max-h-screen"
    >
      <_DealQuestionThread orgId={orgId} dealId={dealId} />
    </DashboardLayout>
  );
}
