import React, { ReactNode, useMemo, useRef, useState } from "react";
import { cn } from "../../../../lib/utils";
import {
  Button,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  Spinner,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../ui";
import { Icon } from "../../../icons";
import { AnimatePresence } from "framer-motion";

export interface Citation {
  citationNumber: number;
  searchResult: SearchResultWeb | SearchResultFile;
}

export interface SearchResultWeb {
  type: "webpage";
  id: string;
  websiteId: string;
  webpageId: string | null;
  url: string | null;
}

export interface SearchResultFile {
  type: "file";
  id: string;
  fileId: string;
  fileName: string | null;
  pageNumber: number | null;
}
export interface WidgetRenderProps {
  setClipboard?: (items: ClipboardItem) => void;
  onRefresh?: () => void;
  onSource?: (source: Citation) => void;
  refreshing?: boolean;
  widgetRefreshing?: boolean;
  loading?: boolean;
  filters?: React.ReactNode;
}

export interface WidgetProps {
  children?: (props: WidgetRenderProps) => ReactNode;
  title?: string;
  loading?: boolean;
  refreshing?: boolean;
  className?: string;
  sources?: Citation[];
  onSource?: (source: Citation) => void;
  onRefresh?: () => void;
  onCopy?: (items: ClipboardItem) => void;
  isEmpty?: boolean;
  isStale?: boolean;
  filters?: React.ReactNode;
}

const Empty = () => (
  <div className="flex-1 mt-1 flex-center gap-1 text-subdued w-full min-h-16 border border-subdued border-dashed rounded">
    <Icon type="info" size="xs" />
    <Text type="label" color="subdued" className="text-center">
      No data found.
    </Text>
  </div>
);

export const Widget = React.forwardRef<HTMLDivElement, WidgetProps>(
  (
    {
      children,
      className,
      title,
      loading,
      sources,
      refreshing,
      onSource,
      onRefresh,
      onCopy,
      isEmpty,
      isStale,
      filters,
      ...props
    },
    ref,
  ) => {
    const elementRef = useRef(null);
    const widgetRef = (ref || elementRef) as React.RefObject<HTMLInputElement>;
    const [sourcesOpen, setSourcesOpen] = useState(false);
    const [clipboard, setClipboard] = useState<ClipboardItem | null>(null);

    const handleSourceClick = (source: Citation) => {
      onSource?.(source);
      setSourcesOpen(false);
    };

    const content = useMemo(() => {
      if (loading) {
        return (
          <div className="flex-center p-8">
            <div className="absolute rounded inset-0 bg-secondary animate-pulse" />
            <Spinner />
          </div>
        );
      }

      if (isEmpty || !children) {
        if (refreshing) {
          return (
            <div className="flex-1 mt-1 flex flex-col min-h-16 border border-transparent gap-1">
              <div className="w-full flex-1 bg-selected animate-pulse rounded" />
              <div className="w-full flex-1 bg-secondary animate-pulse rounded" />
            </div>
          );
        }
        return <Empty />;
      }

      if (!children) return <Empty />;

      const entries = children({
        onRefresh,
        onSource,
        refreshing,
        loading,
        setClipboard,
      });

      return (
        <ScrollArea className="p-2">
          <AnimatePresence initial={false}>{entries}</AnimatePresence>
        </ScrollArea>
      );
    }, [onCopy, onRefresh, onSource, refreshing, loading, children, widgetRef]);

    const handleCopy = () => {
      if (clipboard) {
        onCopy?.(clipboard);
      }
      setClipboard(null);
    };

    return (
      <div
        ref={ref}
        className={cn(
          "widget flex flex-col p-1 w-full relative border rounded-layout bg",
          (refreshing || loading) && "border-subdued",
          className,
        )}
        {...props}
      >
        {!loading && (
          <header
            className={cn(
              "flex items-center pl-3 pr-1.5 h-11 gap-1.5 relative overflow-hidden rounded",
              (refreshing || loading) && "rounded-b-none",
              className,
            )}
          >
            <div
              className={cn(
                "absolute rounded inset-0 bg-secondary transition-colors",
                (refreshing || loading) && "animate-loading",
              )}
            />
            {refreshing && (
              <div className="absolute rounded inset-0 border border-subdued animate-pulse" />
            )}
            <Text
              strong
              className={cn("relative", refreshing && "animate-pulse")}
            >
              {title}
            </Text>
            <div className="relative">
              {isStale && (
                <Tooltip>
                  <TooltipTrigger>
                    <Icon
                      type="alertFilled"
                      size="xs"
                      className="text-gray-400 tooltip"
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    The widget template has been updated. Refresh the widget to
                    apply the latest template.
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
            <div className="flex-1" />
            <div className="flex gap-1.5 mix-blend-multiply">
              {filters && (
                <>
                  {filters}
                  <Divider direction="vertical" className="h-auto mx-px" />
                </>
              )}
              {!isEmpty && sources && sources?.length > 0 && (
                <Popover open={sourcesOpen} onOpenChange={setSourcesOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      className="relative"
                      iconRight="chevronDown"
                      disabled={refreshing || loading}
                    >
                      Sources
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    align="end"
                    side="bottom"
                    sideOffset={7}
                    className="p-2 flex flex-col"
                  >
                    {sources.map((source) => {
                      const label =
                        "fileName" in source.searchResult
                          ? source.searchResult.fileName
                          : source.searchResult.url;
                      return (
                        <Button
                          key={source.searchResult.id}
                          variant="ghost"
                          size="sm"
                          className="relative h-auto py-0.5"
                          onClick={() => handleSourceClick(source)}
                          fullWidth
                        >
                          <span className="text-left break-words whitespace-normal w-full">
                            {label}
                          </span>
                        </Button>
                      );
                    })}
                  </PopoverContent>
                </Popover>
              )}
              {onCopy && !isEmpty && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleCopy}
                      disabled={refreshing || loading}
                      iconLeft="copy"
                      className="relative"
                    />
                  </TooltipTrigger>
                  <TooltipContent>Copy</TooltipContent>
                </Tooltip>
              )}
              {onRefresh && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={onRefresh}
                      loading={refreshing || loading}
                      iconLeft="refresh"
                      className="relative"
                    />
                  </TooltipTrigger>
                  <TooltipContent>Refresh</TooltipContent>
                </Tooltip>
              )}
            </div>
          </header>
        )}
        {content}
      </div>
    );
  },
);
Widget.displayName = "Widget";
