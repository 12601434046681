import { Button } from "capsa-ui";
import { useReactFlow } from "@xyflow/react";

export const CustomControls = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();

  return (
    <div className="react-flow__panel horizontal bottom flex right gap-1">
      <Button
        size="lg"
        variant="outline"
        onClick={() => zoomIn()}
        iconLeft="zoomIn"
      />
      <Button
        size="lg"
        variant="outline"
        onClick={() => zoomOut()}
        iconLeft="zoomOut"
      />
      <Button
        size="lg"
        variant="outline"
        onClick={() => fitView()}
        iconLeft="fit"
      />
    </div>
  );
};
