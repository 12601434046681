import React from "react";
import { Divider } from "../../../ui";
import { cn } from "../../../../lib/utils";

export const SidebarSeparator = React.forwardRef<
  React.ElementRef<typeof Divider>,
  React.ComponentProps<typeof Divider>
>(({ className, ...props }, ref) => {
  return (
    <Divider
      ref={ref}
      data-sidebar="separator"
      className={cn("mx-2 w-auto bg-sidebar-border", className)}
      {...props}
    />
  );
});
SidebarSeparator.displayName = "SidebarSeparator";
