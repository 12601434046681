import {
  ContentType,
  WidgetTemplate,
  GenerationParams,
  TopicListParams,
  TimeSeriesParams,
  EventListParams,
  PersonRoleListParams,
} from "../types";
import TopicListForm from "./generationParams/TopicListForm";
import TimeSeriesForm from "./generationParams/TimeSeriesForm";
import EventListForm from "./generationParams/EventListForm";
import PersonRoleListForm from "./generationParams/PersonRoleListForm";

interface WidgetTemplateFormProps {
  widget: WidgetTemplate;
  onChange: (updates: Partial<WidgetTemplate>) => void;
}

export function WidgetTemplateForm({
  widget,
  onChange,
}: WidgetTemplateFormProps) {
  const handleContentTypeChange = (newContentType: ContentType) => {
    // Create default params based on the new content type
    let defaultParams: GenerationParams;
    switch (newContentType) {
      case "topic_list":
        defaultParams = {
          outputType: "topic_list",
          outputLanguage: "en",
          summaryPoints: [],
        };
        break;
      case "time_series":
        defaultParams = {
          outputType: "time_series",
          outputLanguage: "en",
          years: [],
          metrics: [],
        };
        break;
      case "event_list":
        defaultParams = { outputType: "event_list", outputLanguage: "en" };
        break;
      case "person_role_list":
        defaultParams = {
          outputType: "person_role_list",
          outputLanguage: "en",
        };
        break;
    }

    onChange({
      contentType: newContentType,
      contentGenerationParams: defaultParams,
    });
  };

  const renderParamsForm = () => {
    switch (widget.contentType) {
      case "topic_list":
        return (
          <TopicListForm
            params={widget.contentGenerationParams as TopicListParams}
            onChange={(params) => onChange({ contentGenerationParams: params })}
          />
        );
      case "time_series":
        return (
          <TimeSeriesForm
            params={widget.contentGenerationParams as TimeSeriesParams}
            onChange={(params) => onChange({ contentGenerationParams: params })}
          />
        );
      case "event_list":
        return (
          <EventListForm
            params={widget.contentGenerationParams as EventListParams}
            onChange={(params) => onChange({ contentGenerationParams: params })}
          />
        );
      case "person_role_list":
        return (
          <PersonRoleListForm
            params={widget.contentGenerationParams as PersonRoleListParams}
            onChange={(params) => onChange({ contentGenerationParams: params })}
          />
        );
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Display Name
        </label>
        <input
          type="text"
          value={widget.prettyName}
          onChange={(e) => onChange({ prettyName: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Content Type
        </label>
        <select
          value={widget.contentType}
          onChange={(e) =>
            handleContentTypeChange(e.target.value as ContentType)
          }
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="topic_list">Topic List</option>
          <option value="time_series">Time Series</option>
          <option value="event_list">Event List</option>
          <option value="person_role_list">Person Role List</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Output Language
        </label>
        <select
          value={widget.contentGenerationParams.outputLanguage}
          onChange={(e) =>
            onChange({
              contentGenerationParams: {
                ...widget.contentGenerationParams,
                outputLanguage: e.target.value as "en" | "de",
              },
            })
          }
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="en">English</option>
          <option value="de">German</option>
        </select>
      </div>

      <div className="border-t pt-4">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Content Generation Parameters
        </h3>
        {renderParamsForm()}
      </div>
    </div>
  );
}
