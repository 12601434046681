import React from "react";
import { EventListParams } from "../../types";

interface EventListFormProps {
  params: EventListParams;
  onChange: (params: EventListParams) => void;
}

const EventListForm: React.FC<EventListFormProps> = () => {
  return (
    <div className="text-sm text-gray-500 italic">
      No additional parameters required for Event List
    </div>
  );
};

export default EventListForm;
