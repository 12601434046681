import { useState } from "react";
import { TimeSeriesParams, FinancialMetric } from "../../types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

interface TimeSeriesFormProps {
  params: TimeSeriesParams;
  onChange: (params: TimeSeriesParams) => void;
}

export function TimeSeriesForm({ params, onChange }: TimeSeriesFormProps) {
  const currentYear = new Date().getFullYear();
  const [centerYear, setCenterYear] = useState(() => {
    // If there are selected years, try to center around them
    if (params.years.length > 0) {
      const lowestSelectedYear = Math.min(...params.years);
      // If lowest selected year would be out of view from default center,
      // adjust center to show it
      if (lowestSelectedYear < currentYear - 5) {
        return lowestSelectedYear + 4; // +4 to show the year with buffer on left
      }
    }
    return currentYear - 1;
  });

  const getYearRange = (center: number): number[] => {
    return Array.from({ length: 9 }, (_, i) => center - 4 + i);
  };

  const handleYearClick = (year: number) => {
    if (params.years.includes(year)) {
      onChange({
        ...params,
        years: params.years.filter((y) => y !== year),
      });
    } else {
      onChange({
        ...params,
        years: [...params.years, year].sort((a, b) => b - a),
      });
    }
  };

  const shiftYears = (direction: "left" | "right") => {
    setCenterYear((prev) => prev + (direction === "left" ? -1 : 1));
  };

  const handleAddMetric = () => {
    const newMetric: FinancialMetric = {
      metricName: "",
      metricPrompt: "",
      searchTerms: [],
    };
    onChange({
      ...params,
      metrics: [...params.metrics, newMetric],
    });
  };

  const handleUpdateMetric = (
    index: number,
    updates: Partial<FinancialMetric>,
  ) => {
    const newMetrics = [...params.metrics];
    newMetrics[index] = { ...newMetrics[index], ...updates };
    onChange({ ...params, metrics: newMetrics });
  };

  const handleRemoveMetric = (index: number) => {
    onChange({
      ...params,
      metrics: params.metrics.filter((_, i) => i !== index),
    });
  };

  return (
    <div className="space-y-6">
      <div>
        <h4 className="text-sm font-medium mb-4">Years</h4>
        <div className="flex items-center justify-center gap-2">
          <button
            type="button"
            onClick={() => shiftYears("left")}
            className="p-1 rounded-full hover:bg-gray-100"
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </button>

          <div className="flex gap-2">
            {getYearRange(centerYear).map((year) => (
              <button
                key={year}
                type="button"
                onClick={() => handleYearClick(year)}
                className={`
                  w-16 py-2 text-sm rounded-md transition-colors
                  ${
                    params.years.includes(year)
                      ? "bg-blue-500 text-white hover:bg-blue-600"
                      : "bg-gray-100 hover:bg-gray-200"
                  }
                `}
              >
                {year}
              </button>
            ))}
          </div>

          <button
            type="button"
            onClick={() => shiftYears("right")}
            className="p-1 rounded-full hover:bg-gray-100"
          >
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {params.metrics.map((metric, index) => (
          <div key={index} className="border p-4 rounded-md space-y-3">
            <div className="flex justify-between">
              <h4 className="text-sm font-medium">Metric {index + 1}</h4>
              <button
                type="button"
                onClick={() => handleRemoveMetric(index)}
                className="text-red-600 hover:text-red-800"
              >
                Remove
              </button>
            </div>

            <div>
              <label className="block text-sm text-gray-700">Metric Name</label>
              <input
                type="text"
                value={metric.metricName}
                onChange={(e) =>
                  handleUpdateMetric(index, { metricName: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm text-gray-700">
                Metric Prompt
              </label>
              <textarea
                value={metric.metricPrompt}
                onChange={(e) =>
                  handleUpdateMetric(index, { metricPrompt: e.target.value })
                }
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={handleAddMetric}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add Metric
        </button>
      </div>
    </div>
  );
}

export default TimeSeriesForm;
