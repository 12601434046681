import { Button } from "capsa-ui";
import { useUserOrgPermissionsQuery } from "../../hooks";
import { hasPermission } from "../../utils";
import { useNavigate } from "react-router-dom";
import { paths } from "../../config/paths";

interface Props {
  orgId: string;
}

export default function CreateAPIKeyButton({ orgId }: Props) {
  const permissionsQuery = useUserOrgPermissionsQuery(orgId);
  const navigate = useNavigate();
  switch (hasPermission(permissionsQuery, "invites.create")) {
    case "loading":
      return <></>;
    case "true":
      return (
        <Button
          onClick={() => {
            navigate(paths.orgs.selected.invites.create.getHref(orgId));
          }}
        >
          Invite
        </Button>
      );
    case "false":
      return <></>;
    default:
      return <></>;
  }
}
