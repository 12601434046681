import React from "react";
import Skeleton from "./base/Skeleton";

interface Props {
  columnHeaders: string[];
  data: React.ReactElement[][];
  noDataIcon: any; //eslint-disable-line
  noDataMessage: string;
  noDataButton?: React.ReactElement;
  isLoading?: boolean;
}

export default function Table(props: Props) {
  if (props.isLoading) {
    return (
      <div className="bg-gray-50 rounded-md outline outline-[1px] outline-gray-200 text-sm">
        <table className="w-full table-auto divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {props.columnHeaders.map((header) => (
                <th
                  key={header}
                  className="text-left pl-4 h-10 font-normal whitespace-normal"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {[...Array(3)].map((_, rowIndex) => (
              <tr key={rowIndex} className="h-10">
                {props.columnHeaders.map((_, cellIndex) => (
                  <td key={cellIndex} className="text-left px-2">
                    <Skeleton width="80%" height="20px" />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  if (props.data.length === 0) {
    return (
      <div className="rounded-md outline-dashed outline-2 outline-gray-200 min-h-[8rem] flex flex-row items-center justify-center p-4">
        <props.noDataIcon className="w-20 h-20 mx-4 text-gray-400 font-thin" />
        <div className="space-y-2 max-w-64 py-4">
          <p className="text-gray-600 text-sm">{props.noDataMessage}</p>
          {props.noDataButton}
        </div>
      </div>
    );
  }
  return (
    <div className="bg-gray-50 rounded-md outline outline-[1px] outline-gray-200 text-sm mb-1">
      <table className="w-full table-auto divide-y divide-gray-200">
        <thead className="bg-gray-50 w-full">
          <tr>
            {props.columnHeaders.map((header) => (
              <th
                key={header}
                className={`text-left px-4 h-10 font-semibold whitespace-pre-wrap}`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {props.data.map((row, i) => (
            <tr key={i} className="h-10">
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="text-left px-4">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
