import { create } from "zustand";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useUpload } from "../components/UploadProvider";
import { assertUserIsAuthenticated } from "../utils";
import { defaultRetryFunc, useAuth } from "./shared";

import {
  FileCreate,
  createDealFile,
  deleteDealFile,
  listDealFilePages,
  listDealFiles,
  readDealFile,
} from "../api/dealFiles";
import {
  CreateDealData,
  CreateDealResponse,
  DealUpdate,
  createDeal,
  deleteDeal,
  listDeals,
  readDeal,
  updateDeal,
} from "../api/deals";
import { ReactQueryConfig } from "@/types/query";

export const useDealsQuery = (orgId: string, config?: ReactQueryConfig) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals"],
    queryFn: () => listDeals(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
    ...config,
  });
};

export const useDealQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId],
    queryFn: () => readDeal(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateDealMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: CreateDealData): Promise<CreateDealResponse> =>
      createDeal(user, orgId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [orgId, "deals"] });
    },
  });
};

export const useUpdateDealMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealUpdate) => updateDeal(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "deals", dealId] });
    },
  });
};

export const useDeleteDealMutation = (orgId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ dealId }: { dealId: string }) =>
      deleteDeal(user, orgId, dealId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [orgId, "deals"] });
    },
  });
};

export const useDealFilesQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "files"],
    queryFn: () => listDealFiles(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.some((file) => file.ingestion_status === "pending")) {
        return 10000;
      }
      return false;
    },
  });
};

export const useDealFileQuery = (
  orgId: string,
  dealId: string,
  fileId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "files", fileId],
    queryFn: () => readDealFile(user, orgId, dealId, fileId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.ingestion_status === "pending") {
        return 10000;
      }
      return false;
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const useDealFilePagesQuery = (
  orgId: string,
  dealId: string,
  fileId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "files", fileId, "pages"],
    queryFn: () => listDealFilePages(user, orgId, dealId, fileId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateDealFileMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { updateUpload, removeUpload } = useUpload();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: FileCreate) =>
      createDealFile(
        user,
        orgId,
        dealId,
        data,
        () =>
          updateUpload(data.file.name, {
            loadedBytes: 0,
            totalBytes: data.file.size,
          }),
        (progress) =>
          updateUpload(data.file.name, {
            loadedBytes: progress.loadedBytes,
            totalBytes: data.file.size,
          }),
        () => removeUpload(data.file.name),
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "files"],
      });
    },
  });
};

export const useDeleteDealFileMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ fileId }: { fileId: string }) =>
      deleteDealFile(user, orgId, dealId, fileId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "files"],
      });
    },
  });
};

interface NamedRegistryFile {
  id: string;
  registryIdentifier: string;
  fileName: string;
}

export interface CreateDealStoreData {
  companyName: string;
  companyId: string;
  companyAliases: string[];
  website: string | null;
  registryFiles: NamedRegistryFile[];
  countryCode: string;
}

interface DealCreateDataStore {
  dealData: CreateDealStoreData | null;
  setDealData: (data: CreateDealStoreData) => void;
  getDealData: () => CreateDealStoreData | null;
  popDealData: () => CreateDealStoreData | null;
}

export const useDealCreateDataStore = create<DealCreateDataStore>(
  (set, get) => ({
    dealData: null,

    setDealData: (data: CreateDealStoreData) => set({ dealData: data }),
    getDealData: () => get().dealData,
    popDealData: () => {
      const currentData = get().dealData;
      set({ dealData: null });
      return currentData;
    },
  }),
);
