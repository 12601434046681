import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { getConfig } from "../api/config";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import { useEffect } from "react";
import { useValidatedParams } from "@/utils/router";

export const useGetSharepointConfigQuery = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "sharepoint", "getConfig"],
    queryFn: () => getConfig(user, orgId || ""),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useInitSharepoint = () => {
  const { setSharepointEnabled } = useSharepointStore();
  const { data } = useGetSharepointConfigQuery();
  useEffect(() => {
    setSharepointEnabled(data?.serviceEnabled || false);
  }, [data]);
};
