export const currency = {
  AED: { code: "AED", symbol: "د.إ" }, // United Arab Emirates Dirham
  AFN: { code: "AFN", symbol: "؋" }, // Afghan Afghani
  ALL: { code: "ALL", symbol: "Lek" }, // Albanian Lek
  AMD: { code: "AMD", symbol: "Դ" }, // Armenian Dram
  ANG: { code: "ANG", symbol: "ƒ" }, // Netherlands Antillean Guilder
  AOA: { code: "AOA", symbol: "Kz" }, // Angolan Kwanza
  ARS: { code: "ARS", symbol: "$" }, // Argentine Peso
  AUD: { code: "AUD", symbol: "$" }, // Australian Dollar
  AWG: { code: "AWG", symbol: "ƒ" }, // Aruban Florin
  AZN: { code: "AZN", symbol: "₼" }, // Azerbaijani Manat
  BAM: { code: "BAM", symbol: "KM" }, // Bosnia and Herzegovina Convertible Mark
  BBD: { code: "BBD", symbol: "$" }, // Barbadian Dollar
  BDT: { code: "BDT", symbol: "৳" }, // Bangladeshi Taka
  BGN: { code: "BGN", symbol: "лв" }, // Bulgarian Lev
  BHD: { code: "BHD", symbol: "ب.د" }, // Bahraini Dinar
  BIF: { code: "BIF", symbol: "Fr" }, // Burundian Franc
  BMD: { code: "BMD", symbol: "$" }, // Bermudian Dollar
  BND: { code: "BND", symbol: "$" }, // Brunei Dollar
  BOB: { code: "BOB", symbol: "Bs." }, // Bolivian Boliviano
  BRL: { code: "BRL", symbol: "R$" }, // Brazilian Real
  BSD: { code: "BSD", symbol: "$" }, // Bahamian Dollar
  BTN: { code: "BTN", symbol: "Nu." }, // Bhutanese Ngultrum
  BWP: { code: "BWP", symbol: "P" }, // Botswana Pula
  BYN: { code: "BYN", symbol: "Br" }, // Belarusian Ruble
  BZD: { code: "BZD", symbol: "$" }, // Belize Dollar
  CAD: { code: "CAD", symbol: "$" }, // Canadian Dollar
  CDF: { code: "CDF", symbol: "Fr" }, // Congolese Franc
  CHF: { code: "CHF", symbol: "Fr" }, // Swiss Franc
  CLP: { code: "CLP", symbol: "$" }, // Chilean Peso
  CNY: { code: "CNY", symbol: "¥" }, // Chinese Yuan
  COP: { code: "COP", symbol: "$" }, // Colombian Peso
  CRC: { code: "CRC", symbol: "₡" }, // Costa Rican Colón
  CUP: { code: "CUP", symbol: "$" }, // Cuban Peso
  CVE: { code: "CVE", symbol: "$" }, // Cape Verdean Escudo
  CZK: { code: "CZK", symbol: "Kč" }, // Czech Koruna
  DJF: { code: "DJF", symbol: "Fr" }, // Djiboutian Franc
  DKK: { code: "DKK", symbol: "kr" }, // Danish Krone
  DOP: { code: "DOP", symbol: "$" }, // Dominican Peso
  DZD: { code: "DZD", symbol: "د.ج" }, // Algerian Dinar
  EGP: { code: "EGP", symbol: "£" }, // Egyptian Pound
  ERN: { code: "ERN", symbol: "Nfk" }, // Eritrean Nakfa
  ETB: { code: "ETB", symbol: "Br" }, // Ethiopian Birr
  EUR: { code: "EUR", symbol: "€" }, // Euro
  FJD: { code: "FJD", symbol: "$" }, // Fijian Dollar
  GBP: { code: "GBP", symbol: "£" }, // British Pound Sterling
  GEL: { code: "GEL", symbol: "₾" }, // Georgian Lari
  GHS: { code: "GHS", symbol: "₵" }, // Ghanaian Cedi
  GIP: { code: "GIP", symbol: "£" }, // Gibraltar Pound
  GMD: { code: "GMD", symbol: "D" }, // Gambian Dalasi
  GNF: { code: "GNF", symbol: "Fr" }, // Guinean Franc
  GTQ: { code: "GTQ", symbol: "Q" }, // Guatemalan Quetzal
  GYD: { code: "GYD", symbol: "$" }, // Guyanese Dollar
  HKD: { code: "HKD", symbol: "$" }, // Hong Kong Dollar
  HNL: { code: "HNL", symbol: "L" }, // Honduran Lempira
  HRK: { code: "HRK", symbol: "kn" }, // Croatian Kuna
  HTG: { code: "HTG", symbol: "G" }, // Haitian Gourde
  HUF: { code: "HUF", symbol: "Ft" }, // Hungarian Forint
  IDR: { code: "IDR", symbol: "Rp" }, // Indonesian Rupiah
  ILS: { code: "ILS", symbol: "₪" }, // Israeli New Shekel
  INR: { code: "INR", symbol: "₹" }, // Indian Rupee
  IQD: { code: "IQD", symbol: "ع.د" }, // Iraqi Dinar
  IRR: { code: "IRR", symbol: "﷼" }, // Iranian Rial
  ISK: { code: "ISK", symbol: "kr" }, // Icelandic Króna
  JMD: { code: "JMD", symbol: "$" }, // Jamaican Dollar
  JOD: { code: "JOD", symbol: "د.ا" }, // Jordanian Dinar
  JPY: { code: "JPY", symbol: "¥" }, // Japanese Yen
  KES: { code: "KES", symbol: "Sh" }, // Kenyan Shilling
  KGS: { code: "KGS", symbol: "лв" }, // Kyrgyzstani Som
  KHR: { code: "KHR", symbol: "៛" }, // Cambodian Riel
  KMF: { code: "KMF", symbol: "Fr" }, // Comorian Franc
  KPW: { code: "KPW", symbol: "₩" }, // North Korean Won
  KRW: { code: "KRW", symbol: "₩" }, // South Korean Won
  KWD: { code: "KWD", symbol: "د.ك" }, // Kuwaiti Dinar
  KYD: { code: "KYD", symbol: "$" }, // Cayman Islands Dollar
  KZT: { code: "KZT", symbol: "₸" }, // Kazakhstani Tenge
  LAK: { code: "LAK", symbol: "₭" }, // Lao Kip
  LBP: { code: "LBP", symbol: "ل.ل" }, // Lebanese Pound
  LKR: { code: "LKR", symbol: "₨" }, // Sri Lankan Rupee
  LRD: { code: "LRD", symbol: "$" }, // Liberian Dollar
  LSL: { code: "LSL", symbol: "L" }, // Lesotho Loti
  LYD: { code: "LYD", symbol: "ل.د" }, // Libyan Dinar
  MAD: { code: "MAD", symbol: "د.م." }, // Moroccan Dirham
  MDL: { code: "MDL", symbol: "L" }, // Moldovan Leu
  MGA: { code: "MGA", symbol: "Ar" }, // Malagasy Ariary
  MKD: { code: "MKD", symbol: "ден" }, // Macedonian Denar
  MMK: { code: "MMK", symbol: "Ks" }, // Myanmar Kyat
  MNT: { code: "MNT", symbol: "₮" }, // Mongolian Tögrög
  MOP: { code: "MOP", symbol: "MOP$" }, // Macanese Pataca
  MRO: { code: "MRO", symbol: "UM" }, // Mauritanian Ouguiya
  MRU: { code: "MRU", symbol: "UM" }, // Mauritanian Ouguiya (updated)
  MUR: { code: "MUR", symbol: "₨" }, // Mauritian Rupee
  MVR: { code: "MVR", symbol: "ރ." }, // Maldivian Rufiyaa
  MWK: { code: "MWK", symbol: "MK" }, // Malawian Kwacha
  MXN: { code: "MXN", symbol: "$" }, // Mexican Peso
  MYR: { code: "MYR", symbol: "RM" }, // Malaysian Ringgit
  MZN: { code: "MZN", symbol: "MT" }, // Mozambican Metical
  NAD: { code: "NAD", symbol: "$" }, // Namibian Dollar
  NGN: { code: "NGN", symbol: "₦" }, // Nigerian Naira
  NIO: { code: "NIO", symbol: "C$" }, // Nicaraguan Córdoba
  NOK: { code: "NOK", symbol: "kr" }, // Norwegian Krone
  NPR: { code: "NPR", symbol: "₨" }, // Nepalese Rupee
  NZD: { code: "NZD", symbol: "$" }, // New Zealand Dollar
  OMR: { code: "OMR", symbol: "﷼" }, // Omani Rial
  PAB: { code: "PAB", symbol: "B/." }, // Panamanian Balboa
  PEN: { code: "PEN", symbol: "S/" }, // Peruvian Sol
  PGK: { code: "PGK", symbol: "K" }, // Papua New Guinean Kina
  PHP: { code: "PHP", symbol: "₱" }, // Philippine Peso
  PKR: { code: "PKR", symbol: "₨" }, // Pakistani Rupee
  PLN: { code: "PLN", symbol: "zł" }, // Polish Złoty
  PYG: { code: "PYG", symbol: "₲" }, // Paraguayan Guaraní
  QAR: { code: "QAR", symbol: "ر.ق" }, // Qatari Riyal
  RON: { code: "RON", symbol: "lei" }, // Romanian Leu
  RSD: { code: "RSD", symbol: "дин." }, // Serbian Dinar
  RUB: { code: "RUB", symbol: "₽" }, // Russian Ruble
  RWF: { code: "RWF", symbol: "Fr" }, // Rwandan Franc
  SAR: { code: "SAR", symbol: "﷼" }, // Saudi Riyal
  SBD: { code: "SBD", symbol: "$" }, // Solomon Islands Dollar
  SCR: { code: "SCR", symbol: "₨" }, // Seychellois Rupee
  SDG: { code: "SDG", symbol: "£" }, // Sudanese Pound
  SEK: { code: "SEK", symbol: "kr" }, // Swedish Krona
  SGD: { code: "SGD", symbol: "$" }, // Singapore Dollar
  SHP: { code: "SHP", symbol: "£" }, // Saint Helena Pound
  SLL: { code: "SLL", symbol: "Le" }, // Sierra Leonean Leone
  SOS: { code: "SOS", symbol: "Sh" }, // Somali Shilling
  SRD: { code: "SRD", symbol: "$" }, // Surinamese Dollar
  SSP: { code: "SSP", symbol: "£" }, // South Sudanese Pound
  STD: { code: "STD", symbol: "Db" }, // São Tomé and Príncipe Dobra
  STN: { code: "STN", symbol: "Db" }, // São Tomé and Príncipe Dobra (updated)
  SYP: { code: "SYP", symbol: "£" }, // Syrian Pound
  SZL: { code: "SZL", symbol: "L" }, // Eswatini Lilangeni
  THB: { code: "THB", symbol: "฿" }, // Thai Baht
  TJS: { code: "TJS", symbol: "ЅМ" }, // Tajikistani Somoni
  TMT: { code: "TMT", symbol: "m" }, // Turkmenistani Manat
  TND: { code: "TND", symbol: "د.ت" }, // Tunisian Dinar
  TOP: { code: "TOP", symbol: "T$" }, // Tongan Paʻanga
  TRY: { code: "TRY", symbol: "₺" }, // Turkish Lira
  TTD: { code: "TTD", symbol: "$" }, // Trinidad and Tobago Dollar
  TWD: { code: "TWD", symbol: "NT$" }, // New Taiwan Dollar
  TZS: { code: "TZS", symbol: "Sh" }, // Tanzanian Shilling
  UAH: { code: "UAH", symbol: "₴" }, // Ukrainian Hryvnia
  UGX: { code: "UGX", symbol: "Sh" }, // Ugandan Shilling
  USD: { code: "USD", symbol: "$" }, // United States Dollar
  UYU: { code: "UYU", symbol: "$U" }, // Uruguayan Peso
  UZS: { code: "UZS", symbol: "лв" }, // Uzbekistani Som
  VND: { code: "VND", symbol: "₫" }, // Vietnamese Đồng
  VUV: { code: "VUV", symbol: "Vt" }, // Vanuatu Vatu
  WST: { code: "WST", symbol: "T" }, // Samoan Tala
  XCD: { code: "XCD", symbol: "$" }, // East Caribbean Dollar
  XOF: { code: "XOF", symbol: "Fr" }, // West African CFA Franc
  YER: { code: "YER", symbol: "﷼" }, // Yemeni Rial
  ZAR: { code: "ZAR", symbol: "R" }, // South African Rand
  ZMW: { code: "ZMW", symbol: "ZK" }, // Zambian Kwacha
  ZWL: { code: "ZWL", symbol: "$" }, // Zimbabwean Dollar
};

export const currencyList = Object.entries(currency).map(
  ([, { code, symbol }]) => ({
    code,
    symbol,
  }),
);

export const commonCurrencyList = [currency.GBP, currency.EUR, currency.USD];

export const additionalCurrencyList = currencyList.filter(
  (currency) =>
    !commonCurrencyList.some((common) => common.code === currency.code),
);
