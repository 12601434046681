import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteDashboardTemplate } from "../api/dashboardTemplates";

export const useDeleteDashboardTemplateMutation = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation<
    void,
    Error,
    { orgId: string; dashboardTemplateId: string }
  >(
    ({ orgId, dashboardTemplateId }) =>
      deleteDashboardTemplate(user, orgId, dashboardTemplateId),
    {
      onSuccess: (_, { orgId }) => {
        queryClient.invalidateQueries({
          queryKey: [orgId, "dashboard_templates"],
        });
      },
    },
  );
};
