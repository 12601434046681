import React from "react";
import RegistrySearch from "@/features/registrySearch/pages/RegistrySearch";

export const RegistrySearchView = () => {
  return (
    <div className="bg min-h-screen">
      <div className="flex flex-col px-4 py-6 pb-24 gap-4">
        <RegistrySearch />
      </div>
    </div>
  );
};
