import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { UseMutationResult } from "@tanstack/react-query";
import { useUserDealPermissionsQuery } from "../hooks/userDealRoles";
import { hasPermission } from "../utils";
import Spinner from "./Spinner";

interface ModalContentProps {
  headingText: string;
  paragraphText: string;
  confirmationButtonText: string;
  setOpen: (value: boolean) => void;
  mutation: UseMutationResult<null, unknown, { dealId: string }, unknown>;
  mutationData: { dealId: string };
  onSuccess: () => void;
  orgId: string;
  dealId: string;
}
function ModalContents({
  headingText,
  paragraphText,
  confirmationButtonText,
  setOpen,
  mutation,
  mutationData,
  onSuccess,
  orgId,
  dealId,
}: ModalContentProps) {
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
  switch (hasPermission(permissionsQuery, "deals.delete")) {
    case "loading":
      return (
        <div className="flex flex-col items-center">
          <Spinner h="8" w="8" />
        </div>
      );
    case "false":
      return (
        <>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {headingText}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  You do not have permission to delete this deal.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </>
      );
    case "true":
      return (
        <>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {headingText}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">{paragraphText}</p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() =>
                mutation.mutate(mutationData, {
                  onSuccess: onSuccess,
                })
              }
              disabled={mutation.isLoading}
            >
              {confirmationButtonText}
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => setOpen(false)}
              disabled={mutation.isLoading}
            >
              Cancel
            </button>
          </div>
        </>
      );
    default:
      return (
        <div className="flex flex-col items-center">
          <p className="text-sm max-w-lg text-gray-700">
            Error loading permissions.
          </p>
        </div>
      );
  }
}

interface Props {
  headingText: string;
  paragraphText: string;
  confirmationButtonText: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  mutation: UseMutationResult<null, unknown, { dealId: string }, unknown>;
  mutationData: { dealId: string };
  onSuccess: () => void;
  orgId: string;
  dealId: string;
}

export default function DeletionModal({
  headingText,
  paragraphText,
  confirmationButtonText,
  open,
  setOpen,
  mutation,
  mutationData,
  onSuccess,
  orgId,
  dealId,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <ModalContents
                  headingText={headingText}
                  paragraphText={paragraphText}
                  confirmationButtonText={confirmationButtonText}
                  setOpen={setOpen}
                  mutation={mutation}
                  mutationData={mutationData}
                  onSuccess={onSuccess}
                  orgId={orgId}
                  dealId={dealId}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
