import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  createDocumentUploadJob,
  deleteDocumentUploadJob,
  listDocumentUploadJobs,
  readDocumentUploadJob,
  DocumentUploadJobData,
} from "../api/documentUploadJobs";
import { useValidatedParams } from "@/utils/router";

export const useDocumentUploadJobsQuery = (
  orgId: string,
  dealId: string,
  status?: ("pending" | "in_progress" | "completed" | "failed")[],
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "document-upload-jobs"],
    queryFn: () => listDocumentUploadJobs(user, orgId, dealId, status),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (
        data &&
        data.some((job) => ["pending", "in_progress"].includes(job.status))
      ) {
        return 1000;
      }
      return false;
    },
  });
};

export const useDocumentUploadJobQuery = (
  orgId: string,
  dealId: string,
  documentUploadJobId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "document-upload-jobs",
      documentUploadJobId,
    ],
    queryFn: () =>
      readDocumentUploadJob(user, orgId, dealId, documentUploadJobId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && ["pending", "in_progress"].includes(data.status)) {
        return 10000;
      }
      return false;
    },
  });
};

export const useCreateDocumentUploadJobMutation = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  return useMutation({
    mutationFn: (data: DocumentUploadJobData) =>
      createDocumentUploadJob(user, orgId || "", dealId || "", data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "document-upload-jobs"],
      });
    },
  });
};

export const useDeleteDocumentUploadJobMutation = (
  orgId: string,
  dealId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ documentUploadJobId }: { documentUploadJobId: string }) =>
      deleteDocumentUploadJob(user, orgId, dealId, documentUploadJobId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "document-upload-jobs"],
      });
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "files"],
      });
    },
  });
};
