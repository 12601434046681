import React, { useState, useEffect } from "react";
import { useDashboardTemplatesQuery } from "../hooks/useDashboardTemplates";
import { DashboardTemplate, WidgetDetails } from "../types";
import { useUpdateDashboardTemplateMutation } from "../hooks/useUpdateDashboardTemplate";
import { useDeleteDashboardTemplateMutation } from "../hooks/useDeleteDashboardTemplate";
import { useCreateDashboardTemplateMutation } from "../hooks/useCreateDashboardTemplate";
import { TrashIcon, PencilIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  DragAndDropZone,
  DragAndDropItem,
} from "capsa-ui/src/components/layout/DragAndDrop";
import { WidgetSelectionPopup } from "../../widgetTemplates/components/WidgetSelectionPopup";
import { WidgetTemplate } from "../../widgetTemplates/types";

interface ErrorBannerProps {
  error: Error;
  onClose: () => void;
}

const ErrorBanner: React.FC<ErrorBannerProps> = ({ error, onClose }) => {
  return (
    <div
      className="transform transition-all duration-300 ease-in-out bg-red-50 
    border border-red-200 rounded-md p-4 mb-4 flex justify-between items-center 
    animate-in fade-in slide-in-from-top-4"
    >
      <div className="flex items-center gap-2">
        <span className="text-red-800 font-medium">{error.name}:</span>
        <span className="text-red-600">{error.message}</span>
      </div>
      <button onClick={onClose} className="text-red-500 hover:text-red-700">
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
  );
};

interface DashboardRowProps {
  dashboard: DashboardTemplate;
  orgId: string;
  onDuplicate: (dashboard: DashboardTemplate) => void;
  onDelete: (dashboardId: string) => void;
}

function DashboardRow({
  dashboard,
  orgId,
  onDuplicate,
  onDelete,
}: DashboardRowProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [widgets, setWidgets] = useState(dashboard.widgetsTemplates);
  const [editingName, setEditingName] = useState(false);
  const [newName, setNewName] = useState(dashboard.name);
  const [showWidgetPopup, setShowWidgetPopup] = useState(false);
  const [updateError, setUpdateError] = useState<Error | null>(null);
  const updateDashboardTemplateMutation = useUpdateDashboardTemplateMutation();

  useEffect(() => {
    setWidgets(dashboard.widgetsTemplates);
  }, [dashboard.widgetsTemplates]);

  useEffect(() => {
    if (updateDashboardTemplateMutation.error) {
      setUpdateError(updateDashboardTemplateMutation.error);
    }
  }, [updateDashboardTemplateMutation.error]);

  const handleDeleteWidget = (widgetId: string) => {
    setUpdateError(null);
    const updatedWidgets = widgets.filter((widget) => widget.id !== widgetId);
    setWidgets(updatedWidgets);
    updateDashboardTemplateMutation.mutate({
      orgId,
      dashboardTemplateId: dashboard.id,
      body: { widgetTemplateIds: updatedWidgets.map((widget) => widget.id) },
    });
  };

  const handleSaveName = () => {
    setUpdateError(null);
    updateDashboardTemplateMutation.mutate({
      orgId,
      dashboardTemplateId: dashboard.id,
      body: { name: newName },
    });
    setEditingName(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSaveName();
    }
  };

  const handleAddWidgets = (selectedWidgets: WidgetTemplate[]) => {
    setUpdateError(null);
    const widgetDetails: WidgetDetails[] = selectedWidgets.map((widget) => ({
      id: widget.id,
      name: widget.name,
      prettyName: widget.prettyName,
      outputType: widget.contentType,
    }));
    const updatedWidgets = [...widgets, ...widgetDetails];
    setWidgets(updatedWidgets);
    updateDashboardTemplateMutation.mutate({
      orgId,
      dashboardTemplateId: dashboard.id,
      body: { widgetTemplateIds: updatedWidgets.map((widget) => widget.id) },
    });
    setShowWidgetPopup(false);
  };

  return (
    <div className="mb-4">
      {updateError && (
        <ErrorBanner error={updateError} onClose={() => setUpdateError(null)} />
      )}
      <table className="min-w-full bg-white border border-gray-200">
        <tbody>
          <tr>
            <td className="px-4 py-2 border-b text-center w-1/12">
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="mr-2"
              >
                {isCollapsed ? "▶" : "▼"}
              </button>
            </td>
            <td className="px-4 py-2 border-b text-center w-3/12">
              <div className="flex items-center justify-start group">
                <strong>Dashboard:</strong>
                {editingName ? (
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleSaveName}
                    className="flex-grow pl-2 focus:outline-none"
                    autoFocus
                  />
                ) : (
                  <span className="flex-grow pl-2 text-left">
                    {dashboard.name}
                    {dashboard.editable && (
                      <PencilIcon
                        className="inline-block w-4 h-4 ml-2 text-gray-500 hover:text-gray-700 group-hover:inline-block"
                        onClick={() => setEditingName(true)}
                      />
                    )}
                  </span>
                )}
              </div>
            </td>
            <td className="px-4 py-2 border-b text-center w-1/12">
              <label className="flex items-center justify-center">
                <strong>Active</strong>
                <input
                  type="checkbox"
                  checked={dashboard.active}
                  onChange={() =>
                    updateDashboardTemplateMutation.mutate({
                      orgId,
                      dashboardTemplateId: dashboard.id,
                      body: { active: !dashboard.active },
                    })
                  }
                  className="form-checkbox h-5 w-5 fill-current ml-2 cursor-pointer hover:shadow-md focus:ring focus:ring-grey-300 rounded"
                />
              </label>
            </td>
            <td className="px-4 py-2 border-b text-center w-1/12">
              <label className="flex items-center justify-center">
                <strong>Default</strong>
                <input
                  type="checkbox"
                  checked={dashboard.default}
                  onChange={() =>
                    updateDashboardTemplateMutation.mutate({
                      orgId,
                      dashboardTemplateId: dashboard.id,
                      body: { default: !dashboard.default },
                    })
                  }
                  className="form-checkbox h-5 w-5 fill-current ml-2 cursor-pointer hover:shadow-md focus:ring focus:ring-grey-300 rounded"
                />
              </label>
            </td>
            <td className="px-4 py-2 border-b text-center w-2/12">
              {dashboard.systemGenerated ? "System Generated" : ""}
            </td>
            <td className="px-4 py-2 border-b text-center w-5/12">
              <div className="flex items-center justify-center space-x-4">
                <button
                  className="text-blue-500 hover:underline px-2"
                  onClick={() => onDuplicate(dashboard)}
                >
                  Duplicate
                </button>
                {dashboard.editable && (
                  <>
                    <span className="border-l border-gray-300 h-4" />
                    <button
                      className="text-blue-500 hover:underline px-2"
                      onClick={() => onDelete(dashboard.id)}
                    >
                      Delete
                    </button>
                  </>
                )}
                {!dashboard.systemGenerated && (
                  <>
                    {dashboard.editable && (
                      <span className="border-l border-gray-300 h-4" />
                    )}
                    <button
                      className="text-blue-500 hover:underline px-2"
                      onClick={() => setShowWidgetPopup(true)}
                    >
                      Add Widget
                    </button>
                  </>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={7} className="px-4 py-2">
              {!isCollapsed &&
                (dashboard.systemGenerated ? (
                  <div className="space-y-1">
                    {widgets.map((widget) => (
                      <div
                        key={widget.id}
                        className="flex justify-between items-center py-1 px-2 w-full"
                      >
                        <div className="flex items-center gap-4 w-full">
                          <span className="flex-grow pl-2">
                            {widget.prettyName}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <DragAndDropZone
                    className="w-full"
                    items={widgets.map(
                      (widget) => `${dashboard.id}__${widget.id}`,
                    )}
                    onDrop={(newOrder: string[]) => {
                      const originalWidgetIds = newOrder.map(
                        (compositeId) => compositeId.split("__")[1],
                      );
                      const currentOrder = widgets.map(
                        (widget) => `${dashboard.id}__${widget.id}`,
                      );
                      const changed = newOrder.some(
                        (id, index) => id !== currentOrder[index],
                      );
                      if (!changed) {
                        return;
                      }
                      const updatedWidgets = originalWidgetIds
                        .map((id) => widgets.find((widget) => widget.id === id))
                        .filter(
                          (widget): widget is WidgetDetails =>
                            widget !== undefined,
                        );

                      setWidgets(updatedWidgets);
                      updateDashboardTemplateMutation.mutate({
                        orgId,
                        dashboardTemplateId: dashboard.id,
                        body: {
                          widgetTemplateIds: updatedWidgets.map(
                            (widget) => widget.id,
                          ),
                        },
                      });
                    }}
                  >
                    {widgets.map((widget) => (
                      <DragAndDropItem
                        key={`${dashboard.id}__${widget.id}`}
                        id={`${dashboard.id}__${widget.id}`}
                        dragPreview={
                          <div className="bg p-2 rounded border">
                            {widget.prettyName}
                          </div>
                        }
                      >
                        <div className="flex justify-between items-center hover:bg-gray-200 group px-2 w-full">
                          <div className="flex items-center gap-4 w-full">
                            <span className="flex-grow pl-2">
                              {widget.prettyName}
                            </span>
                          </div>
                          {!dashboard.systemGenerated && (
                            <button
                              className="text-red-600 hover:text-red-700 hidden group-hover:block"
                              onClick={() => handleDeleteWidget(widget.id)}
                            >
                              <TrashIcon className="text-red-600 h-4 w-4" />
                            </button>
                          )}
                        </div>
                      </DragAndDropItem>
                    ))}
                  </DragAndDropZone>
                ))}
            </td>
          </tr>
        </tbody>
      </table>
      {showWidgetPopup && (
        <WidgetSelectionPopup
          orgId={orgId}
          existingWidgetIds={widgets.map((w) => w.id)}
          onCancel={() => setShowWidgetPopup(false)}
          onAdd={handleAddWidgets}
        />
      )}
    </div>
  );
}

const DashboardTable: React.FC<{ orgId: string }> = ({ orgId }) => {
  const {
    data: initialDashboardTemplates,
    isLoading,
    error: fetchError,
  } = useDashboardTemplatesQuery(orgId);
  const [dashboardTemplates, setDashboardTemplates] = useState<
    DashboardTemplate[]
  >(initialDashboardTemplates || []);
  const createDashboardTemplateMutation = useCreateDashboardTemplateMutation();
  const deleteDashboardTemplateMutation = useDeleteDashboardTemplateMutation();

  useEffect(() => {
    if (initialDashboardTemplates) {
      setDashboardTemplates(initialDashboardTemplates);
    }
  }, [initialDashboardTemplates]);

  const handleDuplicateDashboard = (dashboard: DashboardTemplate) => {
    createDashboardTemplateMutation.mutate({
      orgId,
      body: {
        name: `${dashboard.name} (Copy)`,
        widgetTemplateIds: dashboard.widgetsTemplates.map(
          (widget) => widget.id,
        ),
      },
    });
  };

  const handleDeleteDashboard = (dashboardId: string) => {
    deleteDashboardTemplateMutation.mutate({
      orgId,
      dashboardTemplateId: dashboardId,
    });
  };

  if (isLoading) return <div>Loading...</div>;
  if (fetchError) return <div>Error loading dashboards</div>;

  return (
    <div>
      {dashboardTemplates?.map((dashboard) => (
        <DashboardRow
          key={dashboard.id}
          dashboard={dashboard}
          orgId={orgId}
          onDuplicate={handleDuplicateDashboard}
          onDelete={handleDeleteDashboard}
        />
      ))}
    </div>
  );
};

export default DashboardTable;
