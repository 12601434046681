import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";

const initialState = {
  refreshing: false,
  exportDialogOpen: false,
};

export const useDashboardStore = create(
  devtools(
    subscribeWithSelector(
      combine(initialState, (set) => ({
        reset: () => set(initialState),
        setRefreshing: (refreshing: boolean) => set(() => ({ refreshing })),
        setExportDialogOpen: (exportDialogOpen: boolean) =>
          set(() => ({ exportDialogOpen })),
      })),
    ),
    {
      name: "DashboardStore",
    },
  ),
);
