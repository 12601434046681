import { TopicListParams, SummaryPoint } from "../../types";

interface TopicListFormProps {
  params: TopicListParams;
  onChange: (params: TopicListParams) => void;
}

const TopicListForm: React.FC<TopicListFormProps> = ({ params, onChange }) => {
  const handleAddPoint = () => {
    const newPoint: SummaryPoint = {
      topicName: "",
      topicPrompt: "",
      maxBullets: 3,
    };
    onChange({
      ...params,
      summaryPoints: [...params.summaryPoints, newPoint],
    });
  };

  const handleUpdatePoint = (index: number, updates: Partial<SummaryPoint>) => {
    const newPoints = [...params.summaryPoints];
    newPoints[index] = { ...newPoints[index], ...updates };
    onChange({ ...params, summaryPoints: newPoints });
  };

  const handleRemovePoint = (index: number) => {
    const newPoints = params.summaryPoints.filter((_, i) => i !== index);
    onChange({ ...params, summaryPoints: newPoints });
  };

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <div className="space-y-4">
      {params.summaryPoints.map((point, index) => (
        <div key={index} className="border p-4 rounded-md space-y-3">
          <div className="flex justify-between">
            <h4 className="text-sm font-medium">Summary Point {index + 1}</h4>
            <button
              type="button"
              onClick={() => handleRemovePoint(index)}
              className="text-red-600 hover:text-red-800"
            >
              Remove
            </button>
          </div>

          <div className="flex gap-4">
            <div className="flex-grow">
              <label className="block text-sm text-gray-700">Topic Name</label>
              <input
                type="text"
                value={point.topicName}
                onChange={(e) =>
                  handleUpdatePoint(index, { topicName: e.target.value })
                }
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div className="w-24">
              <label className="block text-sm text-gray-700">Max Bullets</label>
              <input
                type="number"
                value={point.maxBullets}
                onChange={(e) =>
                  handleUpdatePoint(index, {
                    maxBullets: parseInt(e.target.value),
                  })
                }
                min={1}
                className="mt-1 block w-full rounded-md border-gray-300
                shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm text-gray-700">Topic Prompt</label>
            <textarea
              value={point.topicPrompt}
              onChange={(e) => {
                handleUpdatePoint(index, { topicPrompt: e.target.value });
                adjustTextareaHeight(e.target);
              }}
              onFocus={(e) => adjustTextareaHeight(e.target)}
              rows={1}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
              focus:border-blue-500 focus:ring-blue-500"
              style={{ minHeight: "2.5rem", overflow: "hidden" }}
            />
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={handleAddPoint}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Add Summary Point
      </button>
    </div>
  );
};

export default TopicListForm;
