import NavigationIconCard from "../base/NavigationIconCard";
import { useParams } from "react-router-dom";
import {
  DocumentPlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { usePostHog } from "posthog-js/react";
import { DashboardLayout } from "../layout/DashboardLayout";
import { paths } from "@/config/paths";

type URLParams = {
  orgId: string;
};

export default function Templates() {
  const { orgId } = useParams() as URLParams;
  const posthog = usePostHog();

  return (
    <DashboardLayout
      title="Templates"
      description="Create, customise, and edit templates for the Data Extraction tool, or request for the Capsa team to design bespoke templates tailored for your use via Support."
    >
      <div className="h-full overflow-auto p-4">
        <div className="flex flex-row flex-wrap">
          <NavigationIconCard
            name="Data Extraction Templates"
            icon={DocumentPlusIcon}
            description="Create and manage Data Extraction Templates."
            href={paths.orgs.selected.templates.companyProfileTemplates.getHref(
              orgId,
            )}
          />
          {posthog.isFeatureEnabled("question_threads") && (
            <NavigationIconCard
              name="Question Thread Templates"
              icon={QuestionMarkCircleIcon}
              description="Create and manage question thread templates."
              href={paths.orgs.selected.templates.questionTemplates.getHref(
                orgId,
              )}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
