import { CompanyProfileTemplateRead } from "../../api/companyProfileTemplates";
import { useUserOrgPermissionsQuery } from "../../hooks";
import { hasPermission } from "../../utils";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { paths } from "@/config/paths";

interface Props {
  orgId: string;
  companyProfileTemplate: CompanyProfileTemplateRead;
}

export default function UpdateCompanyProfileTemplateButton({
  orgId,
  companyProfileTemplate,
}: Props) {
  const permissionsQuery = useUserOrgPermissionsQuery(orgId);
  const navigate = useNavigate();

  switch (hasPermission(permissionsQuery, "company_profile_templates.update")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner h="4" w="4" />
        </button>
      );
    case "true":
      return (
        <button
          type="button"
          onClick={() =>
            navigate(
              paths.orgs.selected.templates.companyProfileTemplates.selected.update.getHref(
                companyProfileTemplate.org_id,
                companyProfileTemplate.id,
              ),
            )
          }
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
        >
          <PencilSquareIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact admin."
        >
          <PencilSquareIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <PencilSquareIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
  }
}
