import { User } from "../../../types";
import { baseURL, mapToSnakeCase, resolveFetch } from "../../../api/shared";

export type JobStatus = "pending" | "in_progress" | "completed" | "failed";

export interface WidgetGenerationJobRead {
  id: string;
  widgetId: string;
  status: JobStatus;
  errorMessage: string | null;
  errorCount: number;
}

export const listWidgetGenerationJobs = async (
  user: User,
  orgId: string,
  dealId: string,
  status?: JobStatus[],
): Promise<WidgetGenerationJobRead[]> => {
  const url = new URL(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/widget-generation-jobs`,
  );
  if (status) {
    status.forEach((s) => url.searchParams.append("status", s));
  }
  return await resolveFetch(
    fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export const readWidgetGenerationJob = async (
  user: User,
  orgId: string,
  dealId: string,
  jobId: string,
): Promise<WidgetGenerationJobRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/widget-generation-jobs/${jobId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};

export interface WidgetGenerationJobCreate {
  widgetId: string;
}

export const createWidgetGenerationJob = async (
  user: User,
  orgId: string,
  dealId: string,
  data: WidgetGenerationJobCreate,
): Promise<WidgetGenerationJobRead> => {
  const body = mapToSnakeCase<typeof data>(data);
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/widget-generation-jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(body),
    }),
    true,
  );
};
