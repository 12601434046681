import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { RegistrySearchView } from "@/features/registrySearch/pages/RegistrySearchView";
import { createHandle } from "@/utils/router";

export const legalEntitySearchRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.legalEntitySearch.path,
    handle: createHandle("Legal Entity Search"),
    children: [{ path: "", element: <RegistrySearchView /> }],
  },
];
