import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { DashboardTemplatesConfigurationView } from "@/features/dashboardTemplates/pages/DashboardTemplatesConfigurationView";

export const dashboardTemplatesRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.dashboardTemplates.path,
    handle: createHandle("Dashboard Templates", "dashboard"),
    children: [
      {
        path: "",
        element: <DashboardTemplatesConfigurationView />,
      },
    ],
  },
];
