import React, { ReactElement, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import { Icon } from "../../icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../Command";
import { cn } from "../../../lib/utils";

export type ComboboxOption = {
  label: string;
  value: string;
  isDivider?: boolean;
};

export interface ComboboxProps {
  options: ComboboxOption[];
  selected: string;
  onSelect: (value: string) => void;
  placeholder?: string;
  searchPlaceholder?: string;
  width?: string;
  children?: ReactElement;
  avatar?: ReactElement;
  onOpen?: () => void;
  inline?: boolean;
  emptyText?: string;
  minWidth?: string;
}

export const Combobox = ({
  options,
  selected,
  onSelect,
  placeholder,
  searchPlaceholder = "Search...",
  width = "200px",
  children,
  avatar,
  onOpen,
  inline = false,
  emptyText = "No options found.",
  minWidth = "200px",
}: ComboboxProps) => {
  const [open, setOpen] = useState(false);

  const onOpenChange = (state: boolean) => {
    setOpen(state);
    if (onOpen && state) {
      onOpen();
    }
  };

  const Dropdown = () => (
    <Command>
      <CommandInput placeholder={searchPlaceholder} autoFocus />
      <CommandList>
        <CommandEmpty>{emptyText}</CommandEmpty>
        <CommandGroup>
          {options.map((option) => (
            <>
              {option.isDivider ? (
                <CommandSeparator />
              ) : (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  keywords={[option.label]}
                  onSelect={(currentValue) => {
                    onSelect(currentValue === selected ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <span>{option.label}</span>
                  {selected === option.value && (
                    <Icon
                      type="check"
                      size="sm"
                      className={cn("ml-auto, mt-0.5")}
                    />
                  )}
                </CommandItem>
              )}
            </>
          ))}
        </CommandGroup>
      </CommandList>
      {children}
    </Command>
  );

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger
        className={cn(
          "focus-outer pressable clickable inline-flex items-center whitespace-nowrap rounded transition-colors bg-transparent overflow-hidden",
          "text font-medium border",
          "h-10 px-3 gap-2 text-size-body",
        )}
        style={{ width }}
      >
        {avatar && <div className="-ml-[3px]">{avatar}</div>}
        {selected ? (
          <span className="w-full text-left truncated min-w-0">
            {options.find((option) => option.value === selected)?.label}
          </span>
        ) : (
          <span className="w-full text-left truncated min-w-0 text-subdued">
            {placeholder}
          </span>
        )}
        <Icon type="chevronUpDown" size="sm" className="text-label" />
      </PopoverTrigger>
      {inline && open && (
        <div className="p-0 -mt-2 w-[var(--radix-popover-trigger-width)] rounded border outline-none">
          <Dropdown />
        </div>
      )}
      {!inline && (
        <PopoverContent
          className="p-0 w-[var(--radix-popover-trigger-width)]"
          style={{ minWidth }}
          align="start"
        >
          <Dropdown />
        </PopoverContent>
      )}
    </Popover>
  );
};
