import React from "react";
import { cn } from "../../../../lib/utils";

export const SidebarFooter = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      data-sidebar="footer"
      className={cn("flex flex-col border-t", className)}
      {...props}
    />
  );
});
SidebarFooter.displayName = "SidebarFooter";
