import { useNavigate } from "react-router-dom";
import { useDealQuestionThreadQuery } from "../../hooks";
import { hasPermission } from "../../utils";
import Spinner from "../Spinner";
import { useUserDealPermissionsQuery } from "../../hooks/userDealRoles";
import { paths } from "@/config/paths";

interface Props {
  orgId: string;
  dealId: string;
  dealQuestionThreadId: string;
}

export default function CreateDealQuestionThreadExportButton({
  orgId,
  dealId,
  dealQuestionThreadId,
}: Props) {
  const dealQuestionThreadQuery = useDealQuestionThreadQuery(
    orgId,
    dealId,
    dealQuestionThreadId,
  );
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
  const navigate = useNavigate();

  switch (
    hasPermission(permissionsQuery, "deal_question_thread_exports.create")
  ) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner w="4" h="4" />
        </button>
      );
    case "true":
      return (
        <>
          <input className="hidden" type="file" id="file" multiple />
          <button
            type="button"
            onClick={() =>
              navigate(
                paths.orgs.selected.deals.selected.questionThreads.selected.export.getHref(
                  orgId,
                  dealId,
                  dealQuestionThreadId,
                ),
              )
            }
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled={
              dealQuestionThreadQuery.data?.response_status !== "completed"
            }
          >
            Export
          </button>
        </>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          Update
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          Update
        </button>
      );
  }
}
