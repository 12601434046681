import React, { forwardRef } from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { cn } from "../../../lib/utils";
import { cva, VariantProps } from "class-variance-authority";

export const dividerStyles = cva("shrink-0", {
  variants: {
    variant: {
      default: "",
      padded: "",
    },
    direction: {
      horizontal: "h-[1px] w-full border-b",
      vertical: "h-full w-[1px] border-r",
    },
  },
  defaultVariants: {
    variant: "default",
    direction: "horizontal",
  },
  compoundVariants: [
    { variant: "padded", direction: "horizontal", class: "my-2" },
    { variant: "padded", direction: "vertical", class: "mx-2" },
  ],
});

export type DividerVariant = VariantProps<typeof dividerStyles>["variant"];
export type DividerDirection = VariantProps<typeof dividerStyles>["direction"];

export interface DividerProps
  extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>,
    VariantProps<typeof dividerStyles> {}

export const Divider = forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  DividerProps
>(
  (
    {
      className,
      direction = "horizontal",
      variant,
      decorative = true,
      ...props
    },
    ref,
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={direction!}
      className={cn(dividerStyles({ variant, direction }), className)}
      {...props}
    />
  ),
);
Divider.displayName = SeparatorPrimitive.Root.displayName;
