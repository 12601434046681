import { MultiSelect, Widget } from "capsa-ui";
import { useEffect, useMemo, useState } from "react";
import { WidgetContentBulletPoints } from "@/features/widgets/components/WidgetContentBulletPoints";
import { WidgetContentTimeline } from "@/features/widgets/components/WidgetContentTimeline";
import { WidgetContentTable } from "@/features/widgets/components/WidgetContentTable";
import {
  TimeSeries,
  Topic,
  WidgetCitation,
  Event,
  Person,
  Widget as WidgetType,
} from "@/types/widgets";
import { WidgetContentPersonList } from "@/features/widgets/components/WidgetContentPersonList";
import { QueryObserverResult } from "@tanstack/react-query";
import { useGlobalStore } from "@/store";
import { copyToClipboard } from "@/utils/copy";
import { useDealFilesQuery } from "@/hooks/deals";
import { useValidatedParams } from "@/utils/router";
import { useUpdateWidgetMutation } from "@/features/widgets/hooks/widgets";
import { useWebsitesQuery } from "@/hooks/websites";

export const DashboardWidgetContent = ({
  query,
  onRefresh,
  refreshing,
}: {
  query: QueryObserverResult<WidgetType, Error>;
  onRefresh: () => void;
  refreshing: boolean;
}) => {
  const { data, isLoading } = query;
  const { setDashboardDrawerContent, openDashboardDrawer } = useGlobalStore();
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const filesQuery = useDealFilesQuery(orgId, dealId);
  const websitesQuery = useWebsitesQuery(orgId, dealId);
  const [filteredSources, setFilteredSources] = useState<string[]>([]);
  const updateWidget = useUpdateWidgetMutation(orgId, dealId, data?.id || "");

  useEffect(() => {
    // set initial filteredSources
    if (data && data.localContentGenerationParams) {
      const { fileIds, websiteIds } = data.localContentGenerationParams;
      const sources = [...(fileIds || []), ...(websiteIds || [])];
      setFilteredSources(sources);
    }
  }, [data]);

  const handleSourceClick = (citation: WidgetCitation) => {
    setDashboardDrawerContent(citation);
    openDashboardDrawer();
  };

  const title = useMemo(() => {
    if (!data) return "";
    return data.prettyName;
  }, [data]);

  const handleCopy = (items: ClipboardItem) => {
    copyToClipboard(items);
  };

  const handleFilterChange = async (ids: string[]) => {
    if (!data) return;
    try {
      const sources = ids
        .map((id) => filterableSources.find((source) => source.value === id))
        .filter((source) => !!source);
      const fileIds = sources
        .filter((source) => source && source?.type === "file")
        .map((source) => source?.value);
      const websiteIds =
        sources
          .filter((source) => source?.type === "website")
          .map((source) => source?.value) || [];
      updateWidget.mutateAsync({
        localContentGenerationParams: {
          ...data.localContentGenerationParams,
          fileIds,
          websiteIds,
        },
      });
    } catch (error) {
      console.error(error);
    }
    setFilteredSources(ids);
    onRefresh();
  };

  const filterableSources = useMemo(() => {
    const filesList =
      filesQuery.data?.map((item) => ({
        value: item.id,
        label: item.name,
        type: "file",
      })) || [];
    const websitesList =
      websitesQuery.data?.map((item) => ({
        value: item.id,
        label: item.url.replace("https://", "").replace(/\/$/, ""),
        type: "website",
      })) || [];
    return [...filesList, ...websitesList];
  }, [filesQuery.data, websitesQuery.data]);

  if (!data) return null;

  return (
    <Widget
      loading={isLoading}
      refreshing={refreshing || (data && data.status === "refreshing")}
      title={title}
      onSource={handleSourceClick}
      onRefresh={onRefresh}
      onCopy={handleCopy}
      isEmpty={data.empty}
      isStale={
        data.contentGenerationParamsHash !=
        data.widgetTemplateContentGenerationParamsHash
      }
      filters={
        <MultiSelect
          options={filterableSources}
          onValueChange={handleFilterChange}
          updateOnSave
          selected={filteredSources}
          label="Filter Sources"
          size="sm"
          disabled={refreshing || (data && data.status === "refreshing")}
          popoverContentProps={{
            side: "bottom",
            align: "end",
            className: "min-w-[350px] p-0",
          }}
        />
      }
    >
      {({ ...props }) => (
        <>
          {data.content && data.contentType === "topic_list" && (
            <WidgetContentBulletPoints
              content={data.content as Topic[]}
              widgetId={data.id}
              {...props}
            />
          )}
          {data.content && data.contentType === "event_list" && (
            <WidgetContentTimeline
              content={data.content as Event[]}
              widgetId={data.id}
              {...props}
            />
          )}
          {data.content && data.contentType === "time_series" && (
            <WidgetContentTable
              content={data.content as TimeSeries[]}
              widgetId={data.id}
              {...props}
            />
          )}
          {data.content && data.contentType === "person_role_list" && (
            <WidgetContentPersonList
              content={data.content as Person[]}
              widgetId={data.id}
              {...props}
            />
          )}
        </>
      )}
    </Widget>
  );
};
