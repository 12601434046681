import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "capsa-ui";
import SharepointBrowser from "@/features/sharepoint/components/SharepointBrowser";
import { useCreateSyncJobMutation } from "@/features/sharepoint/hooks/syncJobs";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import React, { FC } from "react";

type Props = {
  handleImport?: (selected: { folders: string[]; files: string[] }) => void;
};

const SyncSharepointModal: FC<Props> = ({ handleImport }) => {
  const {
    modalOpen,
    closeModal,
    selectedIds,
    selectedFileIds,
    selectedFolderIds,
  } = useSharepointStore();
  const { mutate, isLoading } = useCreateSyncJobMutation();

  const handleSync = () => {
    if (handleImport) {
      handleImport({
        folders: selectedFolderIds,
        files: selectedFileIds,
      });
      closeModal();
    } else {
      mutate(
        {
          externalFolderIds: selectedFolderIds,
          externalFileIds: selectedFileIds,
        },
        { onSuccess: () => closeModal() },
      );
    }
  };

  const handleModalChange = (state: boolean) => {
    if (!state) {
      closeModal();
    }
  };

  return (
    <Dialog open={modalOpen} onOpenChange={handleModalChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Import Sharepoint Files</DialogTitle>
          <DialogDescription>
            Choose sharepoint files or folders to import to the deal.
          </DialogDescription>
        </DialogHeader>
        <SharepointBrowser />
        <DialogFooter>
          <Button
            onClick={handleSync}
            size="lg"
            loading={isLoading}
            disabled={selectedIds.length === 0}
          >
            Import
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SyncSharepointModal;
