import { Person, PartialDate } from "@/types/widgets";
import {
  Button,
  Divider,
  Text,
  TextArea,
  TextField,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  WidgetEntry,
  WidgetRenderProps,
} from "capsa-ui";
import { useEffect, useMemo, useState } from "react";
import {
  deletePersonEntry,
  personTransformer,
  updatePersonEntry,
} from "../utils/person";
import { useUpdateWidgetMutation } from "../hooks/widgets";
import { useValidatedParams } from "@/utils/router";

export const formatDateJoined = (dateJoined: PartialDate) => {
  if (!dateJoined) return null;
  const parts = [dateJoined.year, dateJoined.month].filter(Boolean);
  return parts.join("-");
};

interface Props extends WidgetRenderProps {
  content: Person[];
  widgetId: string;
}

export const WidgetContentPersonList = ({
  content,
  onSource,
  setClipboard,
  widgetId,
  refreshing,
}: Props) => {
  const entries = useMemo(() => personTransformer(content), [content]);

  useEffect(() => {
    if (setClipboard) {
      setClipboard(
        new ClipboardItem({
          "text/plain": new Blob(["Person"], { type: "text/plain" }),
        }),
      );
    }
  }, [content]);

  return (
    <div className="flex flex-col gap-2">
      {entries.length > 0 &&
        entries.map(
          (
            { entry, name, position, period, background, age, option },
            index,
          ) => {
            return (
              <WidgetEntry
                key={`${entry.identifiers.id}-${name}-${position}-${period}-${background}-${age}`}
                index={index}
                citations={option.citations}
                refreshing={entry.refreshing}
                widgetRefreshing={refreshing}
                onSourceClick={onSource}
                editablePopover={({ onClose }) => (
                  <WidgetContentPersonEditor
                    onClose={onClose}
                    id={entry.identifiers.id}
                    name={name}
                    position={position}
                    age={age}
                    background={background}
                    widgetId={widgetId}
                    data={content}
                  />
                )}
                editablePopoverProps={{
                  sideOffset: 0,
                  collisionBoundary: document.getElementById("dashboard-grid"),
                }}
              >
                <div className="flex flex-col gap-1">
                  <div className="flex gap-2 items-center flex-wrap">
                    <Text strong>{name}</Text>
                    {age && (
                      <>
                        <Divider direction="vertical" className="h-3" />
                        <Text color="label">({age})</Text>
                      </>
                    )}
                    {position && (
                      <>
                        <Divider direction="vertical" className="h-3" />
                        <Text color="label">{position}</Text>
                      </>
                    )}
                    {period && (
                      <>
                        <Divider direction="vertical" className="h-3" />
                        <Text color="label">{period}</Text>
                      </>
                    )}
                  </div>
                  {background && <Text color="label">{background}</Text>}
                </div>
              </WidgetEntry>
            );
          },
        )}
    </div>
  );
};

const WidgetContentPersonEditor = ({
  onClose,
  id,
  name,
  position,
  background,
  age,
  widgetId,
  data,
}: {
  onClose: () => void;
  id: string;
  name: string;
  position: string;
  background: string;
  age: string;
  widgetId: string;
  data: Person[];
}) => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { mutateAsync } = useUpdateWidgetMutation(orgId, dealId, widgetId);
  const [backgroundInput, setBackgroundInput] = useState(background);
  const [nameInput, setNameInput] = useState(name);
  const [positionInput, setPositionInput] = useState(position);
  const [ageInput, setAgeInput] = useState(age);
  const [updating, setUpdating] = useState(false);
  const [removing, setRemoving] = useState(false);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement> | null,
    onClose: () => void,
    remove: boolean = false,
  ) => {
    try {
      e?.preventDefault();

      if (remove) {
        setUpdating(false);
        setRemoving(true);

        await mutateAsync({ content: deletePersonEntry(data, id) });
      } else {
        setUpdating(true);
        setRemoving(false);

        await mutateAsync({
          content: updatePersonEntry(
            data,
            id,
            nameInput,
            positionInput,
            backgroundInput,
            ageInput,
          ),
        });
      }

      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
      setRemoving(false);
    }
  };

  const isUpdated = useMemo(() => {
    return (
      nameInput !== name ||
      positionInput !== position ||
      backgroundInput !== background ||
      ageInput !== age
    );
  }, [
    nameInput,
    positionInput,
    backgroundInput,
    ageInput,
    name,
    position,
    background,
    age,
  ]);

  return (
    <form
      onSubmit={(e) => onSubmit(e, onClose)}
      className="relative flex flex-col gap-3"
    >
      <fieldset className="flex gap-2 flex-wrap">
        <TextField
          label="Name"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          disabled={updating}
          autoFocus
          className="flex-[2] min-w-48"
        />
        <TextField
          label="Position"
          value={positionInput}
          onChange={(e) => setPositionInput(e.target.value)}
          disabled={updating}
          className="flex-[2] min-w-48"
        />
        <TextField
          label="Age"
          value={ageInput}
          onChange={(e) => setAgeInput(e.target.value)}
          disabled={updating}
          className="flex-1 min-w-24"
          type="number"
        />
      </fieldset>
      <TextArea
        value={backgroundInput}
        onChange={(e) => setBackgroundInput(e.target.value)}
        className="relative w-full resize-none"
        autoSize
        autoSizePadding={44}
        minHeight={0}
        disabled={updating}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (background === backgroundInput) return;
            onSubmit(null, onClose);
          }
        }}
        label="Background"
      />
      <div className="absolute bottom-0 w-full flex justify-end gap-1 p-3 z-[1] pointer-events-none">
        <Button
          variant="outline"
          onClick={onClose}
          disabled={updating || removing}
          type="reset"
          className="pointer-events-auto"
        >
          Cancel
        </Button>
        <div className="flex-1" />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              onClick={() => {
                onSubmit(null, onClose, true);
              }}
              disabled={updating}
              type="reset"
              loading={removing}
              iconLeft="delete"
              className="pointer-events-auto"
            />
          </TooltipTrigger>
          <TooltipContent>Remove bullet point</TooltipContent>
        </Tooltip>
        <Button
          type="submit"
          loading={updating}
          disabled={!isUpdated}
          className="pointer-events-auto"
        >
          Update
        </Button>
      </div>
    </form>
  );
};

export const WidgetContentPersonListToMarkdown = (content: Person[]) => {
  const entries = personTransformer(content);

  if (entries.length === 0) return "";
  return entries
    .map(({ name, position, period, background, age }) => {
      const fields = [];
      if (age) fields.push(`(${age})`);
      if (position) fields.push(position);
      if (period) fields.push(period);
      const heading =
        fields.length > 0
          ? `**${name}** | ${fields.join(" | ")}`
          : `**${name}**`;
      if (background) return `${heading}\n\n---\n\n${background}`;
      return `${heading}`;
    })
    .join("\n\n\u00A0\n\n");
};
