import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateDashboardTemplate } from "../api/dashboardTemplates";
import { UpdateDashboardTemplateBody, DashboardTemplate } from "../types";

export const useUpdateDashboardTemplateMutation = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation<
    DashboardTemplate,
    Error,
    {
      orgId: string;
      dashboardTemplateId: string;
      body: UpdateDashboardTemplateBody;
    }
  >(
    ({ orgId, dashboardTemplateId, body }) =>
      updateDashboardTemplate(user, orgId, dashboardTemplateId, body),
    {
      onSuccess: (_, { orgId }) => {
        queryClient.invalidateQueries({
          queryKey: [orgId, "dashboard_templates"],
        });
      },
    },
  );
};
