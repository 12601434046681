import { User } from "../../../types";
import { baseURL, mapToSnakeCase, resolveFetch } from "../../../api/shared";
import { BrandingConfig, UpdateBrandingConfigBody } from "../types";

export const getBrandingConfig = async (
  user: User,
  orgId: string,
): Promise<BrandingConfig> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/branding-configs/current`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export const updateBrandingConfig = async (
  user: User,
  orgId: string,
  body: UpdateBrandingConfigBody,
): Promise<void> => {
  const snake_case_body = mapToSnakeCase(body);
  await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/branding-configs/current`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(snake_case_body),
    }),
    true,
  );
};
