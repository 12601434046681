import { useMemo, useRef } from "react";
import { useCreateDealFileMutation } from "../../hooks/deals";
import { useUserDealPermissionsQuery } from "../../hooks/userDealRoles";
import { checkForPassword, hasPermission } from "../../utils";
import { Button } from "capsa-ui";

interface Props {
  orgId: string;
  dealId: string;
}

declare module "react" {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    webkitdirectory?: string;
  }
}

export default function UploadFolderButton({ orgId, dealId }: Props) {
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
  const mutation = useCreateDealFileMutation(orgId, dealId);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    // Map from filename to paths.
    const fileNameToPaths = new Map<string, string[]>();
    Array.from(e.target.files).forEach((file) => {
      if (file.name.startsWith(".")) {
        return;
      }
      const paths = fileNameToPaths.get(file.name);
      if (paths) {
        paths.push(file.webkitRelativePath);
      } else {
        fileNameToPaths.set(file.name, [file.webkitRelativePath]);
      }
    });

    // Alert on filenames with multiple paths.
    let err = false;
    Array.from(fileNameToPaths.entries()).forEach(([, paths]) => {
      if (paths.length > 1) {
        err = true;
        alert(
          `Error: The following files have the same filename:\n    - ${paths.join(
            "\n    - ",
          )}`,
        );
      }
    });

    // Return if there was an error.
    if (err) {
      e.target.value = "";
      return;
    }

    // Otherwise upload
    Array.from(e.target.files).forEach(async (file) => {
      if (!file.name.startsWith(".")) {
        try {
          const password = await checkForPassword(file);
          mutation.mutate({
            file: file,
            password: password,
          });
        } catch (e) {
          console.error(e);
          alert(`"${file.name}" could not be uploaded.`);
        }
      }
    });
  };

  const status = useMemo(
    () => hasPermission(permissionsQuery, "files.create"),
    [permissionsQuery.status],
  );

  return (
    <>
      {status === "true" && (
        <input
          className="hidden"
          type="file"
          webkitdirectory=""
          id="file"
          ref={inputRef}
          onChange={handleChange}
        />
      )}
      <Button
        onClick={handleClick}
        loading={status === "loading"}
        disabled={status === "false" || status === "unknown"}
        variant="outline"
      >
        Upload Folder
      </Button>
    </>
  );
}
