import React from "react";
import { Button } from "../../../ui";
import { useSidebar } from "./useSidebar";

interface SidebarTriggerProps extends React.ComponentProps<typeof Button> {
  side?: "left" | "right";
}

export const SidebarTrigger = React.forwardRef<
  React.ElementRef<typeof Button>,
  SidebarTriggerProps
>(({ onClick, side = "left", ...props }, ref) => {
  const { toggleSidebar, state } = useSidebar();

  return (
    <Button
      ref={ref}
      data-sidebar="trigger"
      variant="ghost"
      onClick={(event) => {
        onClick?.(event);
        toggleSidebar();
      }}
      iconLeft={
        side === "left"
          ? state === "expanded"
            ? "sidebarClose"
            : "sidebarOpen"
          : state === "expanded"
            ? "sidebarClose"
            : "sidebarOpen"
      }
      {...props}
    />
  );
});
SidebarTrigger.displayName = "SidebarTrigger";
