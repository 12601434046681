import React from "react";
import { cn } from "../../../../lib/utils";

export const SidebarMenu = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    data-sidebar="menu"
    className={cn("flex w-full min-w-0 flex-col gap-px", className)}
    {...props}
  />
));
SidebarMenu.displayName = "SidebarMenu";
