import { Event } from "@/types/widgets";
import { copyToClipboard } from "@/utils/copy";
import {
  Button,
  Text,
  TextArea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  WidgetEntry,
  WidgetRenderProps,
} from "capsa-ui";
import { useEffect, useMemo, useState } from "react";
import {
  deleteTimelineEntry,
  timelineTransformer,
  updateTimelineEntry,
} from "../utils/timeline";
import { useUpdateWidgetMutation } from "../hooks/widgets";
import { useValidatedParams } from "@/utils/router";

interface Props extends WidgetRenderProps {
  content: Event[];
  widgetId: string;
}

export const WidgetContentTimeline = ({
  content,
  onSource,
  setClipboard,
  widgetId,
  refreshing,
}: Props) => {
  const entries = useMemo(() => timelineTransformer(content), [content]);

  useEffect(() => {
    if (!setClipboard) return;

    const html = entries
      .map((entry) => `<b>${entry?.dateStr}</b>\n${entry?.description}`)
      .join("\n");
    const plain = entries
      .map((entry) => `${entry?.dateStr} - ${entry?.description}`)
      .join("\n");

    setClipboard(
      new ClipboardItem({
        "text/html": new Blob([html], { type: "text/html" }),
        "text/plain": new Blob([plain], { type: "text/plain" }),
      }),
    );
  }, [content]);

  return (
    <div className="flex flex-col gap-2">
      {entries.map(({ dateStr, description, option, entry }, index) => {
        const handleCopy = () => {
          copyToClipboard(
            new ClipboardItem({
              "text/html": new Blob([`<b>${dateStr}</b>\n${description}`], {
                type: "text/html",
              }),
              "text/plain": new Blob([`${dateStr} - ${description}`], {
                type: "text/plain",
              }),
            }),
          );
        };

        return (
          <WidgetEntry
            key={`${entry.identifiers.id}-${dateStr}-${description}`}
            index={index}
            citations={option.citations}
            onSourceClick={onSource}
            onCopy={handleCopy}
            refreshing={entry.refreshing}
            widgetRefreshing={refreshing}
            editablePopover={({ onClose }) => (
              <WidgetContentTimelineEditor
                onClose={onClose}
                id={entry.identifiers.id}
                description={description}
                widgetId={widgetId}
                data={content}
              />
            )}
            editablePopoverProps={{
              sideOffset: 0,
              collisionBoundary: document.getElementById("dashboard-grid"),
            }}
          >
            <div className="flex flex-col gap-1">
              <Text type="label" strong>
                {dateStr}
              </Text>
              <Text>{description}</Text>
            </div>
          </WidgetEntry>
        );
      })}
    </div>
  );
};

const WidgetContentTimelineEditor = ({
  onClose,
  id,
  description,
  widgetId,
  data,
}: {
  onClose: () => void;
  id: string;
  description: string;
  widgetId: string;
  data: Event[];
}) => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { mutateAsync } = useUpdateWidgetMutation(orgId, dealId, widgetId);
  const [descriptionInput, setDescription] = useState(description);
  const [updating, setUpdating] = useState(false);
  const [removing, setRemoving] = useState(false);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement> | null,
    onClose: () => void,
    remove: boolean = false,
  ) => {
    try {
      e?.preventDefault();

      const content = data[0];

      const entry = content.entry;
      const option = entry?.options[0];

      if (!option) throw new Error("Option not found");

      if (remove) {
        setUpdating(false);
        setRemoving(true);

        await mutateAsync({ content: deleteTimelineEntry(data, id) });
      } else {
        setUpdating(true);
        setRemoving(false);

        const newDescription = remove ? "" : descriptionInput;
        if (newDescription === description) return;
        await mutateAsync({
          content: updateTimelineEntry(data, id, newDescription),
        });
      }

      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
      setRemoving(false);
    }
  };

  return (
    <form
      onSubmit={(e) => onSubmit(e, onClose)}
      className="relative flex flex-col gap-1.5"
    >
      <TextArea
        id="editable-value"
        value={descriptionInput}
        onChange={(e) => setDescription(e.target.value)}
        autoFocus
        className="relative w-full resize-none"
        autoSize
        autoSizePadding={44}
        minHeight={0}
        disabled={updating}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (description === descriptionInput) return;
            onSubmit(null, onClose);
          }
        }}
        label="Description"
      />
      <div className="absolute bottom-0 w-full flex justify-end gap-1 p-3 z-[1] pointer-events-none">
        <Button
          variant="outline"
          onClick={onClose}
          disabled={updating || removing}
          type="reset"
          className="pointer-events-auto"
        >
          Cancel
        </Button>
        <div className="flex-1" />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              onClick={() => {
                onSubmit(null, onClose, true);
              }}
              disabled={updating}
              type="reset"
              loading={removing}
              iconLeft="delete"
              className="pointer-events-auto"
            />
          </TooltipTrigger>
          <TooltipContent>Remove bullet point</TooltipContent>
        </Tooltip>
        <Button
          type="submit"
          loading={updating}
          disabled={description === descriptionInput || removing}
          className="pointer-events-auto"
        >
          Update
        </Button>
      </div>
    </form>
  );
};

export const WidgetContentTimelineToMarkdown = (content: Event[]) => {
  const entries = timelineTransformer(content);

  if (entries.length === 0) return "";
  return entries
    .map(({ dateStr, description }) => {
      return `**${dateStr}**\n${description}`;
    })
    .join("\n\n\u00A0\n\n");
};
