import CreateCompanyProfileTemplateButton from "./CreateCompanyProfileTemplateButton";
import { CompanyProfileTemplatesList } from "./CompanyProfileTemplatesList";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "../layout/DashboardLayout";

export default function CompanyProfileTemplatesTab() {
  const { orgId } = useValidatedParams(["orgId"]);

  return (
    <DashboardLayout
      title="Data Extraction Templates"
      actions={[
        <CreateCompanyProfileTemplateButton key={orgId} orgId={orgId} />,
      ]}
    >
      <CompanyProfileTemplatesList orgId={orgId} />
    </DashboardLayout>
  );
}
