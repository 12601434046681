import React, { ReactNode, useMemo } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { Icon, IconType } from "../../icons";
import { Spinner } from "../Spinner";
import { cn } from "../../../lib/utils";

export const buttonOuter = cva(
  "focus-outer pressable clickable flex items-center justify-center whitespace-nowrap rounded shrink-0 overflow-hidden",
  {
    variants: {
      variant: {
        default:
          "bg-primary-inverse text-oncolor font-strong border border-primary-strong pb-0.5 active:pb-px",
        outline:
          "bg-selected text-label border font-medium pb-0.5 active:pb-px",
        ghost:
          "bg-transparent bg-opacity-0 text-label hover:bg-secondary font-medium",
        dark: "bg-inverted text-label font-medium",
        danger:
          "bg-danger-inverse text-oncolor font-strong border border-danger-strong pb-0.5 active:pb-px",
      },
      size: {
        sm: "h-7 gap-1 text-size-label",
        default: "h-8 gap-1.5 text-size-body",
        lg: "h-10 gap-2 text-size-subheading",
      },
      loading: {
        true: "pointer-events-none",
      },
      disabled: {
        true: "disabled",
      },
      iconOnly: {
        true: "",
      },
      fullWidth: {
        true: "w-full",
        false: "w-fit-content",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      fullWidth: false,
    },
    compoundVariants: [
      { iconOnly: true, size: "sm", class: "w-7 h-7 min-w-7 min-h-7" },
      { iconOnly: true, size: "default", class: "w-8 h-8 min-w-8 min-h-8" },
      { iconOnly: true, size: "lg", class: "w-10 h-10 min-w-10 min-h-10" },
      { iconOnly: true, fullWidth: true, class: "w-full" },
    ],
  },
);

export const buttonInner = cva(
  "flex items-center justify-center whitespace-nowrap rounded shrink-0 h-full shadow-[0_0_0_3px_rgba(0,0,0,0.1)]",
  {
    variants: {
      variant: {
        default:
          "bg-primary-inverse text-oncolor font-strong border-b border-primary-strong",
        outline: "bg text-label border-b font-medium shadow-none",
        ghost:
          "bg-transparent bg-opacity-0 text-label hover:bg-secondary font-medium shadow-none",
        dark: "bg-white/0 bg-opacity-0 text-oncolor hover:bg-white/10 font-medium shadow-none",
        danger:
          "bg-danger-inverse text-oncolor font-strong border-b border-danger-strong",
      },
      size: {
        sm: "px-1.5 gap-1.5 text-size-label",
        default: "px-2 gap-2 text-size-body",
        lg: "px-3 gap-3 text-size-subheading",
      },
      loading: {
        true: "pointer-events-none",
      },
      iconOnly: {
        true: "w-full",
      },
      fullWidth: {
        true: "w-full",
        false: "w-fit-content",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ButtonVariant = VariantProps<typeof buttonOuter>["variant"];
export type ButtonSize = VariantProps<typeof buttonOuter>["size"];

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonOuter> {
  disabled?: boolean;
  loading?: boolean;
  value?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  fullWidth?: boolean;
  loadingText?: ReactNode;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant = "default",
      size = "default",
      iconLeft,
      iconRight,
      loading,
      disabled,
      fullWidth,
      loadingText,
      ...props
    },
    ref,
  ) => {
    const iconSize = useMemo(() => {
      switch (size) {
        case "default":
          return "sm";
        case "sm":
          return "xs";
        case "lg":
          return "default";
      }
    }, [size]);

    const spinnerVariant = useMemo(() => {
      switch (variant) {
        case "default":
        case "danger":
        case "dark":
          return "oncolor";
        default:
          return "default";
      }
    }, [variant]);

    const iconOnly = useMemo(
      () =>
        (!children && (!!iconLeft || !!iconRight)) || (!loadingText && loading),
      [children, iconLeft, iconRight, loadingText, loading],
    );

    return (
      <button
        className={cn(
          buttonOuter({
            variant,
            size,
            className,
            disabled,
            loading,
            iconOnly,
            fullWidth,
          }),
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        <span
          className={cn(
            "pointer-events-none",
            buttonInner({
              variant,
              size,
              loading,
              iconOnly,
              fullWidth,
            }),
          )}
        >
          {loading && <Spinner variant={spinnerVariant} size={iconSize} />}
          {iconLeft && !loading && <Icon type={iconLeft} size={iconSize} />}
          {children && !loading && children}
          {loadingText && loading && loadingText}
          {iconRight && !loading && <Icon type={iconRight} size={iconSize} />}
        </span>
      </button>
    );
  },
);
Button.displayName = "Button";
