import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { marked, Token, Tokens } from "marked";
import { pxToPt } from "./units";
import { decodeHtmlEntities } from "@/utils";

export const markdownToPdf = (
  markdown: string,
  options?: {
    accentColor?: string;
  },
) => {
  const tokens = marked.lexer(markdown);

  const tokensToPdfElements = (tokens: Token[]) => {
    return tokens.map((token, index) => renderToken(token, index));
  };

  const headingStyles = StyleSheet.create({
    base: {
      color: "#3d3d43",
      fontWeight: "semibold",
    },
    h1: {
      fontSize: pxToPt(24),
      lineHeight: pxToPt(40),
    },
    h2: {
      fontSize: pxToPt(18),
      lineHeight: pxToPt(26),
      paddingBottom: options?.accentColor ? pxToPt(8) : undefined,
      borderBottom: options?.accentColor
        ? `${pxToPt(2)} solid ${options.accentColor}`
        : undefined,
      marginBottom: pxToPt(16),
    },
    h3: {
      fontSize: pxToPt(16),
      lineHeight: pxToPt(24),
    },
  });

  const paragraphStyles = StyleSheet.create({
    base: {
      fontSize: pxToPt(14),
      lineHeight: pxToPt(20),
      color: "#3d3d43",
    },
    strong: {
      fontWeight: "medium",
    },
    subdued: {
      color: "#a1a1aa",
    },
  });

  const layoutStyles = StyleSheet.create({
    hr: {
      borderTop: "1px solid #dfdfe2",
      marginVertical: pxToPt(6),
    },
  });

  const listStyles = StyleSheet.create({
    list: {
      flexDirection: "column",
      gap: pxToPt(6),
      marginLeft: pxToPt(6),
    },
    listItem: {
      flexDirection: "row",
      gap: pxToPt(6),
      width: "100%",
    },
    nestedItem: {
      marginBottom: pxToPt(6),
    },
  });

  const tableStyles = StyleSheet.create({
    table: {
      flexDirection: "column",
      width: "100%",
    },
    row: {
      flexDirection: "row",
      width: "100%",
    },
    cell: {
      flex: 1,
      paddingVertical: pxToPt(12),
      paddingHorizontal: pxToPt(6),
      borderTop: `1px solid #dfdfe2`,
    },
    headerCell: {
      flex: 1,
      paddingVertical: pxToPt(12),
      paddingHorizontal: pxToPt(6),
      fontWeight: "semibold",
      fontSize: pxToPt(12),
      lineHeight: pxToPt(16),
      color: "#878792",
    },
    firstCell: {
      flex: 3,
    },
  });

  const renderToken = (token: Token, index: number) => {
    switch (token.type) {
      case "heading":
        return (
          <Text
            key={index}
            style={{
              ...headingStyles.base,
              ...headingStyles[
                `h${token.depth || 1}` as keyof typeof headingStyles
              ],
            }}
          >
            {tokensToPdfElements(token.tokens || [])}
          </Text>
        );

      case "paragraph":
        return (
          <Text key={index} style={paragraphStyles.base}>
            {tokensToPdfElements(token.tokens || [])}
          </Text>
        );

      case "space": {
        const newlineCount = (token.raw?.match(/\n\n/g) || []).length;
        const height = pxToPt(newlineCount > 1 ? newlineCount * 20 : 0);
        return <View style={{ height: height }} />;
      }

      case "list":
        return (
          <View key={index} style={listStyles.list}>
            {token.items.map((item: Tokens.ListItem, idx: number) => {
              return (
                <View key={idx} style={listStyles.listItem}>
                  <Text style={[paragraphStyles.base, paragraphStyles.subdued]}>
                    {token.ordered ? `${idx + 1}.` : "•"}
                  </Text>
                  <View style={[paragraphStyles.base, { flex: 1 }]}>
                    {tokensToPdfElements(item.tokens)}
                  </View>
                </View>
              );
            })}
          </View>
        );

      case "table":
        return (
          <View key={index} style={tableStyles.table}>
            <View style={tableStyles.row}>
              {token.header.map((cell: Tokens.TableCell, idx: number) => (
                <Text
                  key={idx}
                  style={[
                    { textAlign: cell.align || "left" },
                    tableStyles.headerCell,
                    idx === 0 ? tableStyles.firstCell : {},
                  ]}
                >
                  {cell.text}
                </Text>
              ))}
            </View>

            {/* Data rows */}
            {token.rows.map((row: Tokens.TableCell[], idx: number) => (
              <View key={idx} style={tableStyles.row}>
                {row.map((cell: Tokens.TableCell, cellIdx: number) => (
                  <Text
                    key={cellIdx}
                    style={[
                      tableStyles.cell,
                      {
                        textAlign: cell.align || "left",
                      },
                      cellIdx === 0 ? tableStyles.firstCell : {},
                      paragraphStyles.base,
                    ]}
                  >
                    {tokensToPdfElements(cell.tokens)}
                  </Text>
                ))}
              </View>
            ))}
          </View>
        );

      case "list_item":
        return null;

      case "hr":
        return <View key={index} style={layoutStyles.hr} />;

      case "strong":
      case "text":
      case "em":
        return renderInlineToken(token, index);

      default:
        return null;
    }
  };

  const renderInlineToken = (token: Token, index: number) => {
    switch (token.type) {
      case "strong":
        return (
          <Text
            key={index}
            style={[paragraphStyles.base, paragraphStyles.strong]}
          >
            {tokensToPdfElements(token.tokens || [])}
          </Text>
        );

      case "em":
        return (
          <Text key={index} style={[paragraphStyles.base]}>
            {tokensToPdfElements(token.tokens || [])}
          </Text>
        );

      case "text":
        if (
          (token as Tokens.Text).tokens &&
          (token as Tokens.Text).tokens?.length
        ) {
          return (
            <Text key={index} style={listStyles.nestedItem}>
              {tokensToPdfElements((token as Tokens.Text).tokens || [])}
            </Text>
          );
        } else {
          return (
            <Text key={index}>{decodeHtmlEntities(token.text || "")}</Text>
          );
        }

      default:
        return null;
    }
  };

  return tokensToPdfElements(tokens);
};
