import { WebsiteRead } from "@/api/websites";

export const getCompanyLogoFromWebsites = (
  websites: WebsiteRead[],
): string | undefined => {
  if (!websites?.length) return undefined;

  try {
    const firstWebsite = websites.reduce((oldest, current) =>
      new Date(current.created_at) < new Date(oldest.created_at)
        ? current
        : oldest,
    );
    const hostname = new URL(firstWebsite.url).hostname.replace(/^www\./, "");
    return `https://img.logo.dev/${hostname}?token=pk_Z5UAqFRyRn-zOfmEUCyzww`;
  } catch (error) {
    console.warn("Invalid URL for company logo:", error);
    return undefined;
  }
};

export const getCompanyLogoDomain = (
  websites: WebsiteRead[] | undefined,
): string | undefined => {
  if (!websites?.length) return undefined;

  try {
    const firstWebsite = websites.reduce((oldest, current) =>
      new Date(current.created_at) < new Date(oldest.created_at)
        ? current
        : oldest,
    );
    return new URL(firstWebsite.url).hostname.replace(/^www\./, "");
  } catch (error) {
    console.warn("Invalid URL for company logo:", error);
    return undefined;
  }
};
