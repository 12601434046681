import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { SettingsView } from "@/features/settings/pages/SettingsView";

export const settingsRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.settings.path,
    handle: createHandle("Settings", "settings"),
    children: [{ path: "", element: <SettingsView /> }],
  },
];
