import { toast } from "capsa-ui";

export const copyToClipboard = async (items: ClipboardItem) => {
  try {
    await navigator.clipboard.write([items]);
    toast("Copied to clipboard");
  } catch (error) {
    console.error(error);
    toast.error("Failed to copy to clipboard");
  }
};
