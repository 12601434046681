import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useWizard } from "react-use-wizard";
import { useDealFilesQuery } from "../../../../hooks/deals";
import { useNavigate } from "react-router-dom";
import { FileRead, PartialDate } from "@/api/dealFiles";
import { Icon } from "capsa-ui";
import { paths } from "@/config/paths";

export interface SelectedFile {
  name: string;
  id: string;
}

type SortBy = "name" | "period_ending" | "custom";

interface FileWithIndexAndPeriod extends FileRead {
  index: number;
  period_ending_sortable: string;
  period_ending_readable: string;
}

function partialDateToSortableString(date: PartialDate | null) {
  // Return a string that can be sorted lexicographically
  if (!date) {
    return "0000-00-00";
  }
  const year = date.year ? date.year.toString().padStart(4, "0") : "0000";
  const month = date.month ? date.month.toString().padStart(2, "0") : "00";
  const day = date.day ? date.day.toString().padStart(2, "0") : "00";
  return `${year}-${month}-${day}`;
}

function partialDateToReadableString(date: PartialDate | null) {
  // Return a string that can be displayed to the user
  if (!date) {
    return "-";
  }
  if (!date.year) {
    return "-";
  }
  let string = date.year.toString().padStart(4, "0");
  if (!date.month) {
    return string;
  }
  string += `-${date.month.toString().padStart(2, "0")}`;
  if (!date.day) {
    return string;
  }
  string += `-${date.day.toString().padStart(2, "0")}`;
  return string;
}

function getLatestDate(partialDates: PartialDate[] | null) {
  // Given an array of PartialDate objects, return the latest date
  if (!partialDates) {
    return null;
  }
  return partialDates.sort((a, b) => {
    const aString = partialDateToSortableString(a);
    const bString = partialDateToSortableString(b);
    return aString.localeCompare(bString);
  })[partialDates.length - 1];
}

function getFilesWithIndexAndPeriods(
  files: FileRead[],
): FileWithIndexAndPeriod[] {
  // Add period_ending_sortable and period_ending_readable fields to each file
  return files.map((file, index) => {
    const periodEnding = getLatestDate(file.file_metadata.name_dates);
    return {
      ...file,
      index,
      period_ending_readable: partialDateToReadableString(periodEnding),
      period_ending_sortable: partialDateToSortableString(periodEnding),
    };
  });
}

function sortFiles(
  files: FileWithIndexAndPeriod[],
  sortBy: SortBy,
): FileWithIndexAndPeriod[] {
  // Sort files according to the selected sorting method
  if (sortBy === "name") {
    files = files.sort((a, b) => a.name.localeCompare(b.name));
  } else if (sortBy === "period_ending") {
    files = files.sort((a, b) =>
      a.period_ending_sortable.localeCompare(b.period_ending_sortable),
    );
  }
  return files.map((file, index) => ({
    ...file,
    index,
  }));
}

function filterFiles(
  files: FileWithIndexAndPeriod[],
  query: string,
): FileWithIndexAndPeriod[] {
  // Filter files based on the query
  return files.filter((file) =>
    file.name.toLowerCase().includes(query.toLowerCase()),
  );
}

function SelectFilesTable({
  files,
  onSelectFiles,
}: {
  files: FileRead[];
  onSelectFiles: (files: SelectedFile[]) => void;
}) {
  const [sortBy, setSortBy] = useState<SortBy>("name");
  const [query, setQuery] = useState("");
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(
    null,
  );
  const [shiftPressed, setShiftPressed] = useState(false);
  const [sortedFiles, setSortedFiles] = useState<FileWithIndexAndPeriod[]>(
    sortFiles(getFilesWithIndexAndPeriods(files), sortBy),
  );

  // Listen to shift key events
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        e.preventDefault();
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        e.preventDefault();
        setShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    setSortedFiles((prev) => sortFiles(prev, sortBy));
  }, [sortBy]);

  const idToFile = useMemo(
    () => new Map(sortedFiles.map((file) => [file.id, file])),
    [sortedFiles],
  );

  const filteredFiles = useMemo(
    () => filterFiles(sortedFiles, query),
    [sortedFiles, query],
  );

  useEffect(() => {
    onSelectFiles(
      sortedFiles
        .filter((file) => selectedRows.has(file.id))
        .map((file) => ({ name: file.name, id: file.id })),
    );
  }, [sortedFiles, selectedRows, idToFile]);

  const selectRange = (start: number, end: number) => {
    const [low, high] = start < end ? [start, end] : [end, start];
    const idsToSelect = filteredFiles
      .slice(low, high + 1)
      .map((file) => file.id);
    return new Set(idsToSelect);
  };

  const handleSelection = (index: number, event: React.MouseEvent) => {
    event.preventDefault();

    setSelectedRows((prevSelected) => {
      const id = filteredFiles[index].id;

      // If Shift is pressed, select a range
      if (shiftPressed && lastSelectedIndex !== null) {
        const rangeSelection = selectRange(lastSelectedIndex, index);
        return new Set([...prevSelected, ...rangeSelection]);
      }

      // Toggle selection for the current row (without clearing others)
      const newSelectedRows = new Set(prevSelected);
      if (newSelectedRows.has(id)) {
        newSelectedRows.delete(id);
      } else {
        newSelectedRows.add(id);
      }

      return newSelectedRows;
    });

    setLastSelectedIndex(index);
  };

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  // Prevent accidental text selection
  const preventHighlighting = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div className="space-y-2">
      <div>
        <input
          className="border-base text-size-body rounded-layout p-2 min-w-96"
          placeholder="Search"
          onChange={handleQueryChange}
        />
      </div>
      <div className="max-h-[calc(100vh-18*var(--unit))] overflow-y-auto border rounded-layout">
        <table
          onMouseDown={preventHighlighting}
          onMouseUp={preventHighlighting}
          className="w-full table-fixed border-collapse text-left text-sm"
        >
          <thead>
            <tr className="bg-gray-50 sticky top-0 z-10 border-b">
              <th className="p-2 w-2/3" onClick={() => setSortBy("name")}>
                <div className="flex flex-row space-x-2 items-center group cursor-pointer">
                  <div>Name</div>
                  {sortBy === "name" ? (
                    <Icon type="chevronDown" size="sm" />
                  ) : (
                    <Icon
                      type="chevronDown"
                      size="sm"
                      className="h-4 w-4 group-hover:opacity-100 opacity-0 transition-opacity duration-200 text-indigo-700"
                    />
                  )}
                </div>
              </th>
              <th className="p-2">
                <div onClick={() => setSortBy("period_ending")}>
                  <div className="flex flex-row space-x-2 items-center group cursor-pointer">
                    <div>Period Ending</div>
                    {sortBy === "period_ending" ? (
                      <Icon type="chevronDown" size="sm" className="h-4 w-4" />
                    ) : (
                      <Icon
                        type="chevronDown"
                        size="sm"
                        className="h-4 w-4 group-hover:opacity-100 opacity-0 transition-opacity duration-100 text-indigo-700"
                      />
                    )}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredFiles.map((file, index) => (
              <tr
                key={file.id}
                onClick={(e) => handleSelection(index, e)}
                className={`cursor-pointer ${selectedRows.has(file.id) ? "bg-primary-selected" : "hover:bg-secondary"}`}
              >
                <td className="p-2 border-b">{file.name}</td>
                <td className="p-2 border-b">{file.period_ending_readable}</td>
              </tr>
            ))}
            {filteredFiles.length === 0 && (
              <tr>
                <td colSpan={2} className="p-2">
                  No files found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default function SelectFilesPage({
  orgId,
  dealId,
  selectedFiles,
  setSelectedFiles,
}: {
  orgId: string;
  dealId: string;
  selectedFiles: SelectedFile[];
  setSelectedFiles: Dispatch<SetStateAction<SelectedFile[]>>;
}) {
  const { nextStep } = useWizard();
  const filesQuery = useDealFilesQuery(orgId, dealId);
  const navigate = useNavigate();

  const handleNextStep = () => {
    if (selectedFiles.length === 0) {
      alert("Please select at least one file.");
      return;
    }
    nextStep();
  };

  if (filesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (filesQuery.isError) {
    return <div>Error loading files.</div>;
  }

  let files = filesQuery.data.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  files = files.filter((file) => file.ingestion_status == "completed");

  return (
    <div>
      <h1 className="text-sm font-semibold mb-2">Select Files</h1>
      <SelectFilesTable files={files} onSelectFiles={setSelectedFiles} />
      <div className="mt-4 flex flex-row grow justify-end space-x-2">
        <button
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          onClick={() =>
            navigate(
              paths.orgs.selected.deals.selected.tableAggregations.getHref(
                orgId,
                dealId,
              ),
            )
          }
        >
          Cancel
        </button>
        <button
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          onClick={() => handleNextStep()}
        >
          Next
        </button>
      </div>
    </div>
  );
}
