import { Link } from "react-router-dom";
import { APIError } from "../../api/shared";
import { useCompanyProfileTemplatesQuery } from "../../hooks/companyProfileTemplates";
import DeleteCompanyProfileTemplateButton from "./DeleteCompanyProfileTemplateButton";
import UpdateCompanyProfileTemplateButton from "./UpdateCompanyProfileTemplateButton";
import Table from "../Table";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import CreateCompanyProfileTemplateButton from "./CreateCompanyProfileTemplateButton";
import { paths } from "@/config/paths";

interface Props {
  orgId: string;
}

export function CompanyProfileTemplatesList({ orgId }: Props) {
  const companyProfileTemplatesQuery = useCompanyProfileTemplatesQuery(orgId);

  // Insufficient permissions
  if (
    companyProfileTemplatesQuery.error instanceof APIError &&
    companyProfileTemplatesQuery.error.type === "PermissionError"
  ) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to list Data Extraction Templates."}
        </p>
      </ul>
    );
  }

  // Error
  if (companyProfileTemplatesQuery.isError) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          Error listing Data Extraction Templates.
        </p>
      </ul>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!companyProfileTemplatesQuery.isLoading) {
    data = companyProfileTemplatesQuery.data.map((companyProfileTemplate) => {
      return [
        <Link
          key={`link-${companyProfileTemplate.id}`}
          to={paths.orgs.selected.templates.companyProfileTemplates.selected.getHref(
            orgId,
            companyProfileTemplate.id,
          )}
          className="text-indigo-600"
        >
          {companyProfileTemplate.name}
        </Link>,
        <time
          key={`time-${companyProfileTemplate.id}`}
          dateTime={companyProfileTemplate.created_at}
        >
          {new Date(companyProfileTemplate.created_at).toLocaleString()}
        </time>,
        <div
          key={`actions-${companyProfileTemplate.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <UpdateCompanyProfileTemplateButton
            orgId={orgId}
            companyProfileTemplate={companyProfileTemplate}
          />
          <DeleteCompanyProfileTemplateButton
            orgId={orgId}
            companyProfileTemplate={companyProfileTemplate}
          />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Name", "Created", ""]}
      data={data}
      noDataIcon={DocumentPlusIcon}
      noDataMessage="You don't have any Data Extraction Templates yet."
      noDataButton={<CreateCompanyProfileTemplateButton orgId={orgId} />}
      isLoading={companyProfileTemplatesQuery.isLoading}
    />
  );
}
