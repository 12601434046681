import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useOrgInviteQuery, useUpdateOrgInviteMutation } from "../hooks";
import RolesMultiSelect from "./RolesMultiSelect";
import { RoleRead } from "../api/roles";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "./layout/DashboardLayout";

export default function OrgInvitesUpdate() {
  const { orgId, inviteId } = useValidatedParams(["orgId", "inviteId"]);
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm();
  const mutation = useUpdateOrgInviteMutation(orgId as string);
  const inviteQuery = useOrgInviteQuery(orgId, inviteId);

  return (
    <DashboardLayout loading={inviteQuery.isLoading}>
      {inviteQuery.isError && (
        <div className="flex flex-col items-center justify-center h-full">
          Error
        </div>
      )}
      {inviteQuery.data && (
        <form
          onSubmit={handleSubmit((values) => {
            const roles = values.roles as RoleRead[];
            mutation.mutate(
              {
                inviteId: inviteId as string,
                data: { roles: roles.map((r) => r.id) },
              },
              {
                onSuccess: () => {
                  navigate(paths.orgs.selected.users.getHref(orgId));
                  reset();
                },
              },
            );
          })}
        >
          <div className="flex border-b-[1px] border-gray-200 pb-4">
            <p className="text-sm max-w-lg text-gray-700">
              {`Edit ${inviteQuery.data.user_email}'s invite roles.`}
            </p>
          </div>
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="roles"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Roles
                  </label>
                  <div className="mt-1">
                    <Controller
                      name="roles"
                      control={control}
                      render={({ field }) => (
                        <RolesMultiSelect
                          orgId={orgId as string}
                          type="org"
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-6">
            <div className="flex justify-end">
              <button
                onClick={() =>
                  navigate(paths.orgs.selected.users.getHref(orgId))
                }
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      )}
    </DashboardLayout>
  );
}
