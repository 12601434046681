import { Icon, Button, Text, Spinner } from "capsa-ui";
import {
  useDeleteWebsiteCrawlJobMutation,
  useWebsiteCrawlJobsQuery,
} from "@/hooks/websiteCrawlJobs";
import { useEffect } from "react";
import { useWebsitesQuery } from "@/hooks/websites";
import { WebsiteCrawlJobRead } from "@/api/websiteCrawlJobs";
import { useValidatedParams } from "@/utils/router";

export enum WebsiteCrawlJobStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}

const WebsiteCrawlJobInfo = (props: {
  websiteCrawlJob: WebsiteCrawlJobRead;
  onDelete: () => void;
}) => {
  if (props.websiteCrawlJob.status === WebsiteCrawlJobStatus.COMPLETED) {
    return (
      <div
        key={props.websiteCrawlJob.id}
        className="flex gap-2 items-center border rounded p-4"
      >
        <Icon type="checkFilled" className="text-success" />
        <Text>{`Crawled ${props.websiteCrawlJob.url}, found ${props.websiteCrawlJob.items_crawled} webpages.`}</Text>
        <div className="flex-1" />
      </div>
    );
  }
  if (props.websiteCrawlJob.status === WebsiteCrawlJobStatus.FAILED) {
    return (
      <div
        key={props.websiteCrawlJob.id}
        className="flex gap-2 items-center border rounded p-4"
      >
        <Icon type="alertFilled" className="text-warning" />
        <Text>{`Crawl of ${props.websiteCrawlJob.url} failed.`}</Text>
        <div className="flex-1" />
        <Button iconLeft="delete" variant="outline" onClick={props.onDelete} />
      </div>
    );
  }
  return (
    <div
      key={props.websiteCrawlJob.id}
      className="flex gap-2 items-center border rounded p-4"
    >
      <Spinner />
      <Text>{`Crawling ${props.websiteCrawlJob.url}, found ${props.websiteCrawlJob.items_crawled} webpages...`}</Text>
      <div className="flex-1" />
    </div>
  );
};

export const WebsiteCrawlActiveJobs = () => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const crawlJobsQuery = useWebsiteCrawlJobsQuery({
    status: ["pending", "failed"],
  });
  const deleteCrawlJobMutation = useDeleteWebsiteCrawlJobMutation();
  const websitesQuery = useWebsitesQuery(orgId || "", dealId || "");

  const jobs = crawlJobsQuery.data || [];

  useEffect(() => {
    websitesQuery.refetch();
  }, [jobs]);

  return (
    <>
      {jobs.length > 0 && (
        <div className="flex flex-col gap-1">
          {jobs.map((crawlJob) => {
            return (
              <WebsiteCrawlJobInfo
                key={crawlJob.id}
                websiteCrawlJob={crawlJob}
                onDelete={() => {
                  deleteCrawlJobMutation.mutate({
                    websiteCrawlJobId: crawlJob.id,
                  });
                }}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
