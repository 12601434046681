import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  WidgetGenerationJobCreate,
  createWidgetGenerationJob,
  listWidgetGenerationJobs,
  readWidgetGenerationJob,
  JobStatus,
} from "../api/widgetGenerationJobs";

export const useListWidgetGenerationJobs = (
  orgId: string,
  dealId: string,
  status?: JobStatus[],
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "widget-generation-jobs"],
    queryFn: () => listWidgetGenerationJobs(user, orgId, dealId, status),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (
        data &&
        data.some((job) => ["pending", "in_progress"].includes(job.status))
      ) {
        return 1000;
      }
      return false;
    },
  });
};

export const useGetWidgetGenerationJob = (
  orgId: string,
  dealId: string,
  jobId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "widget-generation-jobs", jobId],
    queryFn: () => readWidgetGenerationJob(user, orgId, dealId, jobId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && ["pending", "in_progress"].includes(data.status)) {
        return 1000;
      }
      return false;
    },
  });
};

export const useRefreshWidgetJob = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: WidgetGenerationJobCreate) =>
      createWidgetGenerationJob(user, orgId, dealId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "widget-generation-jobs"],
      });
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "widgets"],
        refetchType: "active",
        exact: false,
      });
    },
  });
};
