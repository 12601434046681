import React from "react";
import { Button } from "capsa-ui";
import { RegistryDocument } from "@/components/CompanySearch/api/listRegistryDocuments";
import { useCreateDocumentUploadJobMutation } from "@/hooks/documentUploadJobs";
import { DocumentUploadJobData } from "@/api/documentUploadJobs";
import { useNavigate } from "react-router-dom";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";

const CreateRegistryFilesSyncJobButton = ({
  companyName,
  companyIdInRegistry,
  files,
  disabled,
}: {
  companyName: string;
  companyIdInRegistry: string;
  files: RegistryDocument[];
  disabled: boolean;
}): React.JSX.Element => {
  const createSyncJobQuery = useCreateDocumentUploadJobMutation();
  const { dealId, orgId } = useValidatedParams(["dealId", "orgId"]);
  const navigate = useNavigate();

  const onClick = () => {
    const data: DocumentUploadJobData = {
      company_name: companyName,
      company_id_in_registry: companyIdInRegistry,
      documents_to_sync: files.map((file) => ({
        id: file.documentId,
        registry_identifier: file.registryIdentifier,
      })),
      documents_synced: [],
    };
    createSyncJobQuery.mutate(data);
    navigate(
      paths.orgs.selected.deals.selected.registrySearchJobs.getHref(
        orgId || "",
        dealId || "",
      ),
    );
  };

  return (
    <Button onClick={onClick} disabled={disabled}>
      Add Registry Search
    </Button>
  );
};

export default CreateRegistryFilesSyncJobButton;
