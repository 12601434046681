import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { getDomainLogo } from "../api/dashboards";

export const useDomainLogoQuery = (domain: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: ["domain-logo", domain],
    queryFn: () => getDomainLogo(user, domain),
    enabled: !!user && !!domain,
    retry: defaultRetryFunc,
  });
};
