import { User } from "../../../types";
import { baseURL, mapToSnakeCase, resolveFetch } from "../../../api/shared";
import {
  DashboardTemplate,
  CreateDashboardTemplateBody,
  UpdateDashboardTemplateBody,
} from "../types";

export const listDashboardTemplates = async (
  user: User,
  orgId: string,
): Promise<DashboardTemplate[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/dashboard_templates`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export const createDashboardTemplate = async (
  user: User,
  orgId: string,
  body: CreateDashboardTemplateBody,
): Promise<DashboardTemplate> => {
  const snake_case_body = mapToSnakeCase(body);
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/dashboard_templates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(snake_case_body),
    }),
    true,
  );
};

export const updateDashboardTemplate = async (
  user: User,
  orgId: string,
  dashboardTemplateId: string,
  body: UpdateDashboardTemplateBody,
): Promise<DashboardTemplate> => {
  const snake_case_body = mapToSnakeCase(body);
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/dashboard_templates/${dashboardTemplateId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(snake_case_body),
      },
    ),
    true,
  );
};

export const deleteDashboardTemplate = async (
  user: User,
  orgId: string,
  dashboardTemplateId: string,
): Promise<void> => {
  await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/dashboard_templates/${dashboardTemplateId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};
