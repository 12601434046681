import React, { useEffect } from "react";
import { Text, Spinner, ListItem, FilterOptionCheckbox } from "capsa-ui";
import { useRegistryDocumentsQuery } from "@/components/CompanySearch/hooks/registryDocuments";
import { useRegistrySearchStore } from "@/features/registrySearch/store/useRegistrySearchStore";
import { useValidatedParams } from "@/utils/router";

export const RegistryDocuments = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const {
    selectedCompanySuggestion,
    filteredDocuments,
    legalEntity,
    setRegistryDocuments,
    setFilteredDocuments,
    setLoading,
    setFilterOptions,
  } = useRegistrySearchStore();

  const docs = useRegistryDocumentsQuery(
    orgId || "",
    legalEntity?.name || "",
    legalEntity?.id === selectedCompanySuggestion?.id
      ? selectedCompanySuggestion?.countryCode || ""
      : "",
    legalEntity?.id || "",
  );

  useEffect(() => {
    if (docs.data) {
      setRegistryDocuments(docs.data || []);
      setFilteredDocuments(docs.data || []);
      setFilterOptions(
        docs.data.reduce((acc, doc) => {
          const year = doc.effectiveDate?.substring(0, 4) || "";
          if (!acc.some((option) => option.label === year)) {
            const mostRecentYear = Math.max(
              ...docs.data.map((doc) =>
                parseInt(doc.effectiveDate?.substring(0, 4) || "0"),
              ),
            );
            acc.push({
              type: "checkbox",
              label: year,
              selected: mostRecentYear - parseInt(year) <= 4,
            });
          }
          return acc;
        }, [] as FilterOptionCheckbox[]),
      );
    } else {
      setRegistryDocuments([]);
      setFilteredDocuments([]);
      setFilterOptions([]);
    }
  }, [docs.data]);

  useEffect(() => {
    setLoading(docs.isLoading);
  }, [docs.isLoading]);

  if (docs.isLoading) {
    return (
      <div className="flex flex-col gap-4 justify-center items-center w-full h-full">
        <Spinner size="lg" />
        <Text color="label" type="label">
          Finding documents...
        </Text>
      </div>
    );
  }

  if (docs.error || filteredDocuments.length === 0) {
    return (
      <div className="px-3 py-2">
        <Text type="label" color="label">
          No documents found.
        </Text>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        {filteredDocuments.map((doc) => (
          <ListItem
            key={doc.documentId}
            label={doc.documentName}
            suffix={doc.effectiveDate?.substring(0, 4)}
          />
        ))}
      </div>
    </>
  );
};
