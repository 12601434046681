import { Link } from "react-router-dom";
import CreateTableAggregationButton from "./CreateTableAggregationButton";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import Table from "../../Table";
import DeleteTableAggregationButton from "./DeleteTableAggregationButton";
import { APIError } from "../../../api/shared";
import { useTableAggregationsQuery } from "../../../hooks/tableAggregations";
import UpdateTableAggregationIconButton from "./UpdateTableAggregationIconButton";
import { useValidatedParams } from "@/utils/router";
import { paths } from "@/config/paths";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

function StatusTag({ status }: { status: string }) {
  if (status === "completed") {
    return (
      <div className="bg-white w-fit p-1 rounded-md">
        <p className="">Processed</p>
      </div>
    );
  }
  if (status === "failed") {
    return (
      <div className="border-red-500 border w-fit p-1 rounded-md">
        <p className=" text-red-500">Failed</p>
      </div>
    );
  }
  return (
    <div className="border-orange-500 border w-fit p-1 rounded-md">
      <p className=" text-orange-500">Processing</p>
    </div>
  );
}

function _TableAggregationsList({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const tableAggregationsQuery = useTableAggregationsQuery(orgId, dealId);

  // Insufficient permissions
  if (
    tableAggregationsQuery.error instanceof APIError &&
    tableAggregationsQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list files."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (tableAggregationsQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Error listing files.</p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!tableAggregationsQuery.isLoading) {
    data = tableAggregationsQuery.data.map((tableAggregation) => {
      return [
        <Link
          key={`link-${tableAggregation.id}`}
          to={paths.orgs.selected.deals.selected.tableAggregations.selected.getHref(
            orgId,
            dealId,
            tableAggregation.id,
          )}
          className="text-indigo-600"
        >
          {tableAggregation.name || "Unnamed"}
        </Link>,
        <time
          key={`time-${tableAggregation.id}`}
          dateTime={tableAggregation.created_at}
        >
          {new Date(tableAggregation.created_at).toLocaleString()}
        </time>,
        <StatusTag
          key={`status-${tableAggregation.id}`}
          status={tableAggregation.status}
        />,
        <div
          key={`actions-${tableAggregation.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <UpdateTableAggregationIconButton
            orgId={orgId}
            dealId={dealId}
            tableAggregationId={tableAggregation.id}
          />
          <DeleteTableAggregationButton
            orgId={orgId}
            dealId={dealId}
            tableAggregationId={tableAggregation.id}
          />
        </div>,
      ];
    });
  }
  return (
    <Table
      columnHeaders={["Name", "Created", "Status", ""]}
      data={data}
      noDataIcon={TableCellsIcon}
      noDataMessage={"You don't have any table aggregations yet."}
      noDataButton={
        <CreateTableAggregationButton orgId={orgId} dealId={dealId} />
      }
      isLoading={tableAggregationsQuery.isLoading}
    />
  );
}

export default function TableAggregationsList() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  return (
    <DashboardLayout
      title="Table Aggregations"
      description="Combine financial statements from “Files”, merging them horizontally/vertically into a single table. All outputs are traceable with citations. Export your results as an Excel file or copy from the table directly."
      actions={[
        <CreateTableAggregationButton
          key={orgId}
          orgId={orgId}
          dealId={dealId}
        />,
      ]}
    >
      <_TableAggregationsList orgId={orgId} dealId={dealId} />
    </DashboardLayout>
  );
}
