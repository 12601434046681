import { Topic, TextEntry, WidgetOptionText } from "@/types/widgets";
import { marked } from "marked";

export interface BulletPoint {
  topic: string;
  points: string[];
}

function htmlToStrings(markdown: string): BulletPoint[] {
  const html = marked.parse(markdown);
  const parser = new DOMParser();
  const doc = parser.parseFromString(html as string, "text/html");
  const topLevelItems = doc.querySelectorAll("body > ul > li");

  return Array.from(topLevelItems).map((li) => {
    const subList = li.querySelector("ul");
    const points = subList
      ? Array.from(subList.querySelectorAll("li")).map(
          (sub) => sub.textContent || "",
        )
      : [];

    // Remove the sublist from consideration when getting main text
    if (subList) {
      subList.remove();
    }

    const topic = li.textContent?.trim() || "";

    return {
      topic,
      points,
    };
  });
}

export interface BulletRenderData {
  entry: TextEntry;
  option: WidgetOptionText;
  topics: BulletPoint[];
}

export const bulletTransformer = (content: Topic[]): BulletRenderData[] => {
  return content
    .filter(
      (item) =>
        !!item.entry?.options?.length && !!item.entry?.options[0]?.content,
    )
    .map((bullet) => {
      const option = bullet.entry!.options[0];
      const topics = htmlToStrings(option.content);
      return {
        entry: bullet.entry!,
        option,
        topics,
      };
    });
};

export const updateBulletPoints = (
  content: Topic[],
  sectionIndex: number,
  pointIndex: number,
  newValue: string,
  level: number,
  remove: boolean = false,
): Topic[] => {
  return content.map((topic) => {
    const option = topic.entry?.options[0];
    if (!option?.content) return topic;

    const topics = htmlToStrings(option.content);
    const updatedTopics = topics
      .map((bulletPoint, topicIndex) => {
        if (topicIndex === sectionIndex) {
          if (level === 0) {
            if (remove) return null;
            return {
              ...bulletPoint,
              topic: newValue,
            };
          } else {
            return {
              ...bulletPoint,
              points: bulletPoint.points
                .map((point, idx) => (idx === pointIndex ? newValue : point))
                .filter(Boolean),
            };
          }
        }
        return bulletPoint;
      })
      .filter(Boolean);

    const editedContent = updatedTopics
      .map((bulletPoint) => {
        if (!bulletPoint) return "";
        const { topic, points } = bulletPoint;
        const subPoints =
          points.length > 0
            ? "\n" + points.map((point: string) => `  - ${point}`).join("\n")
            : "";
        return `- ${topic}${subPoints}`;
      })
      .filter(Boolean)
      .join("\n");

    return {
      ...topic,
      entry: {
        ...topic.entry!,
        options: [{ ...option, content: editedContent }],
      },
    };
  });
};
