import type React from "react";
import styles from "./Heading.styles";

export type HeadingType = "hero" | "h1" | "h2" | "h3";

export interface HeadingProps {
  children: React.ReactNode;
  type?: HeadingType;
  truncated?: boolean;
  className?: string;
  slot?: string;
}

export const Heading = ({
  children,
  type = "h1",
  truncated,
  className = "",
  slot,
}: HeadingProps) => {
  const HeadingTag = type === "hero" ? "h1" : type;
  return (
    <HeadingTag
      slot={slot}
      className={`${styles({ type, truncated })} ${className}`.trim()}
    >
      {children}
    </HeadingTag>
  );
};
