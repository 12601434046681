import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import Search from "@/components/DealTab/SearchSubTab/Search";
import { createHandle } from "@/utils/router";

export const searchRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.search.path,
    handle: createHandle("Search", "search"),
    children: [
      {
        path: "",
        element: <Search />,
      },
    ],
  },
];
