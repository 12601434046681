import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@/config/paths";
import { GlobalLoader } from "./layout/GlobalLoader";

interface Props {
  children: ReactElement;
}

export default function UnauthenticatedRoute({ children }: Props) {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <GlobalLoader />;
  }

  if (isAuthenticated) {
    return <Navigate to={paths.root.getHref()} />;
  }

  return children;
}
