import React from "react";
import { PersonRoleListParams } from "../../types";

interface PersonRoleListFormProps {
  params: PersonRoleListParams;
  onChange: (params: PersonRoleListParams) => void;
}

const PersonRoleListForm: React.FC<PersonRoleListFormProps> = () => {
  return (
    <div className="text-sm text-gray-500 italic">
      No additional parameters required for Person Role List
    </div>
  );
};

export default PersonRoleListForm;
