import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { listDashboardTemplates } from "../api/dashboardTemplates";

export const useDashboardTemplatesQuery = (orgId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "dashboard_templates"],
    queryFn: () => listDashboardTemplates(user, orgId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
