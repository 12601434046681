import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteWidgetTemplate } from "../api/widgetTemplates";

interface DeleteWidgetTemplateVariables {
  orgId: string;
  widgetTemplateId: string;
}

export const useDeleteWidgetTemplateMutation = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ orgId, widgetTemplateId }: DeleteWidgetTemplateVariables) =>
      deleteWidgetTemplate(user, orgId, widgetTemplateId),
    onSuccess: (_, { orgId, widgetTemplateId }) => {
      queryClient.invalidateQueries({ queryKey: [orgId, "widget_templates"] });
      queryClient.invalidateQueries({
        queryKey: [orgId, "widget_templates", widgetTemplateId],
      });
    },
  });
};
