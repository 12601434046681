import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import TableAggregationsList from "@/components/DealTab/TableAggregrationsSubTab/TableAggregationsList";
import TableAggregationsCreate from "@/components/DealTab/TableAggregrationsSubTab/TableAggregationsCreate";
import TableAggregation from "@/components/DealTab/TableAggregrationsSubTab/TableAggregation";
import TableAggregationsUpdate from "@/components/DealTab/TableAggregrationsSubTab/TableAggregationsUpdate";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";

export const tableAggregationRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.tableAggregations.path,
    handle: createHandle("Table Aggregations", "table"),
    children: [
      {
        path: "",
        element: <TableAggregationsList />,
      },
      {
        path: paths.orgs.selected.deals.selected.tableAggregations.create.path,
        element: <TableAggregationsCreate />,
        handle: createHandle("Create"),
      },
      {
        path: paths.orgs.selected.deals.selected.tableAggregations.selected
          .path,
        handle: createHandle(MatchParam.tableAggregationId),
        children: [
          {
            path: "",
            element: <TableAggregation />,
          },
          {
            path: paths.orgs.selected.deals.selected.tableAggregations.selected
              .update.path,
            element: <TableAggregationsUpdate />,
            handle: createHandle("Update"),
          },
        ],
      },
    ],
  },
];
