import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { readWidget, updateWidget } from "../api/widgets";
import {
  Event,
  LocalContentGenerationParams,
  Person,
  TimeSeries,
  Topic,
  Widget,
} from "../../../types/widgets";
import { User } from "@/types";

const WidgetQuery = (
  user: User,
  orgId: string,
  dealId: string,
  widgetId: string,
) => ({
  queryKey: [orgId, "deals", dealId, "widgets", widgetId],
  queryFn: () => readWidget(user, orgId, dealId, widgetId),
  enabled: !!user,
  retry: defaultRetryFunc,
  refetchInterval: (data: Widget | undefined) => {
    if (data && ["pending", "scheduled", "refreshing"].includes(data.status)) {
      return 2000;
    }
    return 10000;
  },
});

export const useWidgetQuery = (
  orgId: string,
  dealId: string,
  widgetId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery(WidgetQuery(user, orgId, dealId, widgetId));
};

export const useWidgetListQuery = (
  orgId: string,
  dealId: string,
  widgetIds: string[],
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQueries({
    queries: widgetIds.map((widgetId) =>
      WidgetQuery(user, orgId, dealId, widgetId),
    ),
  });
};

export const useUpdateWidgetMutation = (
  orgId: string,
  dealId: string,
  widgetId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      content?: TimeSeries[] | Person[] | Event[] | Topic[];
      localContentGenerationParams?: LocalContentGenerationParams;
    }) => updateWidget(user, orgId, dealId, widgetId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "widgets", widgetId],
      });
    },
  });
};
