import { useNavigate } from "react-router-dom";
import { useDealQuestionThreadQuery } from "../../../hooks";
import { APIError } from "../../../api/shared";
import { useForm } from "react-hook-form";
import { DealQuestionThreadRead } from "../../../api/dealQuestionThreads";
import { DealQuestionThreadExportFileFormat } from "../../../api/dealQuestionThreadExports";
import { useCreateDealQuestionThreadExportMutation } from "../../../hooks/dealQuestionThreadExports";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

function downloadFromURL(url: string) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = url;
  anchor.click();
}

interface FormProps {
  orgId: string;
  dealId: string;
  dealQuestionThread: DealQuestionThreadRead;
}

interface DealQuestionThreadExportCreateFormValues {
  fileFormat: DealQuestionThreadExportFileFormat;
  responseFormat: "short" | "long";
  includeCitations: "true" | "false";
}

function DealQuestionThreadExportsCreateForm({
  orgId,
  dealId,
  dealQuestionThread,
}: FormProps) {
  const navigate = useNavigate();
  const { handleSubmit, reset, register } =
    useForm<DealQuestionThreadExportCreateFormValues>({
      defaultValues: {
        fileFormat: "pdf",
        responseFormat: "short",
        includeCitations: "true",
      },
    });

  const mutation = useCreateDealQuestionThreadExportMutation(
    orgId,
    dealId,
    dealQuestionThread.id,
  );

  return (
    <form
      onSubmit={handleSubmit((values) => {
        mutation.mutate(
          {
            file_format: values.fileFormat,
            response_format: values.responseFormat,
            include_citations: values.includeCitations === "true",
          },
          {
            onSuccess: (dealQuestionThreadExport) => {
              if (process.env.NODE_ENV === "development") {
                downloadFromURL(
                  dealQuestionThreadExport.download_url.replace(
                    "http://storage",
                    "http://localhost",
                  ),
                );
              } else {
                downloadFromURL(dealQuestionThreadExport.download_url);
              }
              navigate(
                paths.orgs.selected.deals.selected.questionThreads.selected.getHref(
                  orgId,
                  dealId,
                  dealQuestionThread.id,
                ),
              );
              reset();
            },
          },
        );
      })}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="File Format"
                className="block text-sm font-medium text-gray-700"
              >
                File Format
              </label>
              <div className="mt-1">
                <select
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-50"
                  {...register("fileFormat")}
                  disabled={mutation.isLoading}
                >
                  <option value={"pdf"}>{"pdf"}</option>
                  <option value={"docx"}>{"docx"}</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="Response Format"
                className="block text-sm font-medium text-gray-700"
              >
                Response Format
              </label>
              <div className="mt-1">
                <select
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-50"
                  {...register("responseFormat")}
                  disabled={mutation.isLoading}
                >
                  <option value={"short"}>Short</option>
                  <option value={"long"}>Long</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="Include Citations"
                className="block text-sm font-medium text-gray-700"
              >
                Include Citations
              </label>
              <div className="mt-1">
                <select
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-50"
                  {...register("includeCitations")}
                  disabled={mutation.isLoading}
                >
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="flex justify-end">
          <button
            onClick={() =>
              navigate(
                paths.orgs.selected.deals.selected.questionThreads.selected.getHref(
                  orgId,
                  dealId,
                  dealQuestionThread.id,
                ),
              )
            }
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled={mutation.isLoading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled={mutation.isLoading}
          >
            Export
          </button>
        </div>
      </div>
    </form>
  );
}

export default function DealQuestionThreadExportsCreate() {
  const { orgId, dealId, dealQuestionThreadId } = useValidatedParams([
    "orgId",
    "dealId",
    "dealQuestionThreadId",
  ]);
  const questionThreadQuery = useDealQuestionThreadQuery(
    orgId,
    dealId,
    dealQuestionThreadId,
  );

  // Loading
  if (questionThreadQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Loading question thread.</p>
        </ul>
      </div>
    );
  }

  // Insufficient permissions
  if (
    questionThreadQuery.error instanceof APIError &&
    questionThreadQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to view this question thread."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (questionThreadQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error viewing this question thread.
          </p>
        </ul>
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DealQuestionThreadExportsCreateForm
        orgId={orgId}
        dealId={dealId}
        dealQuestionThread={questionThreadQuery.data}
      />
    </DashboardLayout>
  );
}
