import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createSharepointSyncJob,
  deleteSharepointSyncJob,
  listSharepointSyncJobs,
} from "../api/syncJobs";
import {
  CreateSharepointSyncJobResponse,
  SharepointSyncJobStatus,
} from "../types/sharepoint";
import { useValidatedParams } from "@/utils/router";

export const useCreateSyncJobMutation = () => {
  const { orgId, dealId } = useValidatedParams([]);
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({
      externalFolderIds,
      externalFileIds,
      externalDealId,
    }: {
      externalFolderIds: string[];
      externalFileIds: string[];
      externalDealId?: string;
    }) =>
      createSharepointSyncJob(
        user,
        orgId || "",
        externalDealId || dealId || "",
        externalFolderIds,
        externalFileIds,
      ),

    onSuccess: (data: CreateSharepointSyncJobResponse) => {
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "syncJobs"],
      });
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "syncJobs", data.jobId],
      });
    },
  });
};

export const useListSyncJobsQuery = (
  orgId: string,
  dealId: string,
  jobStatus?: SharepointSyncJobStatus[],
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "syncJobs"],
    queryFn: () => listSharepointSyncJobs(user, orgId, dealId, jobStatus),
    refetchInterval: (data) => {
      if (
        data?.filter(
          ({ jobStatus }) =>
            jobStatus === SharepointSyncJobStatus.COMPLETED ||
            jobStatus === SharepointSyncJobStatus.FAILED,
        ).length === data?.length
      ) {
        return false;
      }
      return 1000;
    },
  });
};

export const useDeleteSharepointSyncJobMutation = () => {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ jobId }: { jobId: string }) =>
      deleteSharepointSyncJob(user, orgId, dealId, jobId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "syncJobs"],
      });
    },
  });
};
