import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../ui";
import { useSidebar } from "./useSidebar";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "../../../../lib/utils";

export const SidebarMenuButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    asChild?: boolean;
    isActive?: boolean;
    tooltip?: string | React.ComponentProps<typeof TooltipContent>;
  }
>(({ asChild = false, isActive = false, tooltip, children, ...props }, ref) => {
  const Comp = asChild ? Slot : "button";
  const { state } = useSidebar();

  const button = (
    <Comp
      ref={ref}
      data-sidebar="menu-button"
      data-active={isActive}
      className={cn(
        "peer/menu-button flex w-full h-9 rounded pb-0.5 border active:pb-px disabled:disabled focus-inner relative",
        isActive
          ? "border-base bg-selected"
          : "border-transparent bg-transparent [&:hover>*]:bg-secondary-selected",
      )}
      {...props}
    >
      <span
        className={cn(
          "flex border-b w-full h-full items-center gap-2 overflow-hidden rounded p-2 text-left text-sm outline-none ring-sidebar-ring transition-[width,height,padding] group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 group-data-[collapsible=icon]:!p-2 [&>span:last-child]:truncate [&>svg]:shrink-0 [&>svg:first-child]:text-subdued pointer-events-none",
          isActive
            ? "bg border-b-base font-medium text [&>svg:first-child]:text-primary"
            : "bg-secondary border-b-transparent",
        )}
      >
        {children}
      </span>
    </Comp>
  );

  if (!tooltip) {
    return button;
  }

  if (typeof tooltip === "string") {
    tooltip = {
      children: tooltip,
    };
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>{button}</TooltipTrigger>
      <TooltipContent
        side="right"
        align="center"
        hidden={state !== "collapsed"}
        {...tooltip}
      />
    </Tooltip>
  );
});
SidebarMenuButton.displayName = "SidebarMenuButton";
