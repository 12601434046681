import { defaultRetryFunc, useAuth } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  TableAggregationLayoutCreate,
  createTableAggregationLayout,
  PeriodsPerFile,
  TablesPerPeriod,
} from "../api/tableAggregationLayouts";

export const useCreateTableAggregationLayoutQuery = (
  orgId: string,
  dealId: string,
  fileIds: string[],
  periodsPerFile: PeriodsPerFile,
  tablesPerPeriod: TablesPerPeriod,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "table-aggregation-layouts", ...fileIds],
    queryFn: () =>
      createTableAggregationLayout(user, orgId, dealId, {
        file_ids: fileIds,
        periods_per_file: periodsPerFile,
        tables_per_period: tablesPerPeriod,
      }),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateTableAggregationLayoutMutation = (
  orgId: string,
  dealId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: TableAggregationLayoutCreate) =>
      createTableAggregationLayout(user, orgId, dealId, data),
    onSuccess: (tableAggregationLayout) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          dealId,
          "tableAggregationLayouts",
          ...tableAggregationLayout.files.map((file) => file.id),
        ],
      });
    },
  });
};
