import type React from "react";
import { useState } from "react";
import { Tag, Tags } from "../Tags";
import { TextField } from "../TextField";

export interface TagFieldProps
  extends Omit<React.ComponentPropsWithoutRef<"input">, "size" | "onChange"> {
  label: string;
  description?: string;
  tags: Tag[];
  onChange?: (value: string) => void;
  onRemoveTag: (id: string) => void;
  onAddTag: (label: string) => void;
  onEmptyEnter?: () => void;
  tooltip?: string;
}

export const TagField = ({
  description = "Press enter to add a new tag",
  tags,
  onRemoveTag,
  onAddTag,
  onEmptyEnter,
  onChange,
  tooltip,
  ...props
}: TagFieldProps) => {
  const [inputValue, setInputValue] = useState(
    props.defaultValue?.toString() || props.value?.toString() || "",
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (inputValue.length > 0) {
        e.preventDefault();
        onAddTag(inputValue);
        setInputValue("");
        if (onChange) {
          onChange("");
        }
      } else if (onEmptyEnter) {
        onEmptyEnter();
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <TextField
        description={description}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        tooltip={tooltip}
        {...props}
      />
      {tags.length > 0 && (
        <Tags onRemove={props.disabled ? undefined : onRemoveTag} tags={tags} />
      )}
    </div>
  );
};
