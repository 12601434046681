import { APIError } from "../../../api/shared";
import { useDealChatThreadsQuery } from "../../../hooks";
import { Link } from "react-router-dom";
import DeleteDealUserChatThreadButton from "./DeleteChatThreadButton";
import Table from "../../Table";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import { useValidatedParams } from "@/utils/router";
import { paths } from "@/config/paths";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
// import DeleteFileButton from "./Del";

function _DealUserChatThreadList() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const dealChatThreadsQuery = useDealChatThreadsQuery(orgId, dealId);

  // Insufficient permissions
  if (
    dealChatThreadsQuery.error instanceof APIError &&
    dealChatThreadsQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list chat threads."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (dealChatThreadsQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error listing chat threads.
          </p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!dealChatThreadsQuery.isLoading) {
    data = dealChatThreadsQuery.data.map((thread) => {
      return [
        <Link
          key={thread.id}
          to={paths.orgs.selected.deals.selected.chatThreadsSu.selected.getHref(
            orgId,
            dealId,
            thread.id,
          )}
          className="text-indigo-600"
        >
          {thread.name || "New Thread"}
        </Link>,
        <p key={`user_email_${thread.id}`}>{thread.user_email}</p>,
        <time key={`created_at_${thread.id}`} dateTime={thread.created_at}>
          {new Date(thread.created_at).toLocaleString()}
        </time>,
        <div
          key={`actions_${thread.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteDealUserChatThreadButton
            orgId={orgId}
            dealId={dealId}
            dealChatThreadId={thread.id}
          />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Name", "User", "Created", ""]}
      data={data}
      noDataIcon={ChatBubbleLeftRightIcon}
      noDataMessage={"You don't have any chat threads yet."}
      noDataButton={<></>}
      isLoading={dealChatThreadsQuery.isLoading}
    />
  );
}

export default function DealUserChatThreadList() {
  return (
    <DashboardLayout
      title="Chat Threads"
      description="Utilise Capsa’s assistant to extract insights and answers from the content in your deal folder. All answers are traceable with citations. Export your results as a .pdf or .doc file."
    >
      <_DealUserChatThreadList />
    </DashboardLayout>
  );
}
