import type React from "react";
import styles from "./SearchField.styles";
import {
  Label,
  Input,
  Button,
  ComboBox,
  Popover,
  ListBox,
  ListBoxItem,
  Key,
} from "react-aria-components";
import { useEffect, useState } from "react";
import { Icon, IconType } from "../../icons";
import { Text } from "../Text";
import { Spinner } from "../Spinner";
import { TooltipIcon } from "../Tooltip";
import { useVisuallyHidden } from "react-aria";

export type SearchItem = {
  id: string;
  label: string;
  description?: string;
  icon?: IconType;
  avatar?: string;
};

export interface SearchFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  name?: string;
  placeholder?: string;
  searchItems: SearchItem[];
  onSearchSelection: (id: string) => void;
  fetching?: boolean;
  loadingText?: string;
  tooltip?: string;
  hideLabel?: boolean;
  minSearchLength?: number;
}

export const SearchField = ({
  label,
  value,
  onChange,
  disabled,
  autoFocus,
  name,
  placeholder,
  searchItems,
  onSearchSelection,
  fetching,
  loadingText = "Getting items...",
  tooltip,
  hideLabel,
  minSearchLength = 3,
}: SearchFieldProps) => {
  const [focused, setFocused] = useState(false);
  const {
    base,
    inputStyle,
    inputWrapper,
    labelStyle,
    cancelStyle,
    icon,
    popoverStyle,
    listStyle,
    itemStyle,
    listLabel,
    listDescription,
    loadingStyle,
  } = styles({ focused });
  const [list, setList] = useState<SearchItem[]>([]);
  const { visuallyHiddenProps } = useVisuallyHidden({});

  useEffect(() => {
    if (searchItems.length > 0) {
      setList(searchItems);
    }
  }, [searchItems, value]);

  const handleSelection = (key: Key | null) => {
    if (key) {
      onSearchSelection(key as string);
    }
    (document.activeElement as HTMLInputElement)?.blur();
  };

  return (
    <ComboBox
      menuTrigger="focus"
      isDisabled={disabled}
      className={({ isDisabled }) => base({ isDisabled })}
      onSelectionChange={handleSelection}
      autoFocus={autoFocus}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      name={name || label}
      items={fetching ? [] : list}
      allowsEmptyCollection={true}
      defaultFilter={() => true} // Disable filtering
    >
      <Label
        className={labelStyle()}
        {...(hideLabel ? visuallyHiddenProps : {})}
      >
        {label}
        {tooltip && <TooltipIcon>{tooltip}</TooltipIcon>}
      </Label>
      <div className={inputWrapper()}>
        <span className={icon()}>
          <Icon type="search" size="sm" />
        </span>
        <Input
          placeholder={placeholder}
          className={inputStyle()}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        {value && value.length > 0 && (
          <Button className={cancelStyle()} onPress={() => onChange("")}>
            <Icon size="default" type="cancelFilled" />
          </Button>
        )}
      </div>
      <Popover className={popoverStyle()} offset={4}>
        {value.length >= minSearchLength && (
          <ListBox
            selectionMode="single"
            className={listStyle()}
            aria-label="Search results"
            renderEmptyState={() => (
              <div className={loadingStyle()}>
                <Spinner size="sm" />
                <Text type="label" color="label">
                  {loadingText}
                </Text>
              </div>
            )}
          >
            {(item: SearchItem) => (
              <ListBoxItem
                key={item.id}
                className={({ isSelected }) => itemStyle({ isSelected })}
                textValue={item.label}
              >
                <div className={listLabel()}>{item.label}</div>
                <div className={listDescription()}>{item.description}</div>
              </ListBoxItem>
            )}
          </ListBox>
        )}
      </Popover>
    </ComboBox>
  );
};
