import { IconType } from "capsa-ui";

export interface Crumb {
  label: string;
  icon?: IconType;
}

export interface Handle {
  crumb: Crumb;
}

export const MatchParam = {
  orgId: ":orgId",
  dealId: ":dealId",
  widgetTemplateId: ":widgetTemplateId",
  fileId: ":fileId",
  websiteId: ":websiteId",
  tableAggregationId: ":tableAggregationId",
  dealQuestionThreadId: ":dealQuestionThreadId",
  dealChatThreadId: ":dealChatThreadId",
  userId: ":userId",
  inviteId: ":inviteId",
  companyProfileId: ":companyProfileId",
  companyProfileTemplateId: ":companyProfileTemplateId",
  questionTemplateId: ":questionTemplateId",
} as const;
