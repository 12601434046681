import { Person, PersonEntry, WidgetOptionPerson } from "@/types/widgets";
import { formatWidgetDate } from "@/utils/time";

export interface PersonRenderData {
  name: string;
  position: string;
  period: string | null;
  background: string;
  age: string;
  entry: PersonEntry;
  option: WidgetOptionPerson;
}

export const personTransformer = (content: Person[]): PersonRenderData[] => {
  return content
    .filter((item) => !!item.entry.options[0]?.content)
    .map((person) => {
      const option = person.entry.options[0];
      const { name, position, period, background, age } = option.content!;

      const periodStr = () => {
        if (!period) return null;
        if (period.fromDate && period.toDate) {
          return `${formatWidgetDate(period.fromDate, person.language)} to ${formatWidgetDate(period.toDate, person.language)}`;
        }
        if (period.fromDate) {
          return `Joined: ${formatWidgetDate(period.fromDate, person.language)}`;
        }
        if (period.toDate) {
          return `Left: ${formatWidgetDate(period.toDate, person.language)}`;
        }
        return null;
      };

      return {
        name,
        position,
        period: periodStr(),
        background,
        age,
        entry: person.entry,
        option,
      };
    });
};

export const updatePersonEntry = (
  content: Person[],
  entryId: string,
  newName: string,
  newPosition: string,
  newBackground: string,
  newAge: string,
): Person[] => {
  return content.map((person) => {
    if (person.entry.identifiers.id !== entryId) return person;

    const option = person.entry.options[0];
    if (!option?.content) return person;

    return {
      ...person,
      entry: {
        ...person.entry,
        options: [
          {
            ...option,
            content: {
              ...option.content,
              name: newName,
              position: newPosition,
              background: newBackground,
              age: newAge,
            },
          },
        ],
      },
    };
  });
};

export const deletePersonEntry = (
  content: Person[],
  entryId: string,
): Person[] => {
  return content.filter((person) => person.entry.identifiers.id !== entryId);
};
