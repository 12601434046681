import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import DealQuestionThreadList from "@/components/DealTab/QuestionThreadsSubTab/DealQuestionThreadList";
import DealQuestionThreadsCreate from "@/components/DealTab/DealQuestionThreadsCreate";
import DealQuestionThread from "@/components/DealTab/QuestionThreadsSubTab/DealQuestionThread";
import DealQuestionThreadsUpdate from "@/components/DealTab/QuestionThreadsSubTab/DealQuestionThreadsUpdate";
import DealQuestionThreadExportsCreate from "@/components/DealTab/QuestionThreadsSubTab/DealQuestionThreadExportsCreate";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";

export const questionThreadRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.questionThreads.path,
    handle: createHandle("Question Threads", "question"),
    children: [
      {
        path: "",
        element: <DealQuestionThreadList />,
      },
      {
        path: paths.orgs.selected.deals.selected.questionThreads.create.path,
        element: <DealQuestionThreadsCreate />,
        handle: createHandle("Create"),
      },
      {
        path: paths.orgs.selected.deals.selected.questionThreads.selected.path,
        handle: createHandle(MatchParam.dealQuestionThreadId),
        children: [
          {
            path: "",
            element: <DealQuestionThread />,
          },
          {
            path: paths.orgs.selected.deals.selected.questionThreads.selected
              .update.path,
            element: <DealQuestionThreadsUpdate />,
            handle: createHandle("Update"),
          },
          {
            path: paths.orgs.selected.deals.selected.questionThreads.selected
              .export.path,
            element: <DealQuestionThreadExportsCreate />,
            handle: createHandle("Export"),
          },
        ],
      },
    ],
  },
];
