import { withVariants } from "../../../lib/withVariants";

const styles = withVariants({
  slots: {
    treeStyle: "",
    itemStyle:
      "focus-outline clickable border-b shrink-0 last:border-b-0 items-stretch",
    contentStyle:
      "bg px-2 flex gap-2 shrink-0 transition items-stretch overflow-hidden",
    labelStyle: "text-size-body py-3 flex items-center gap-2 shrink-0 flex-1",
    chevronStyle:
      "text-label focus-outer transition duration-micro ease-in-out rounded shrink-0",
    textStyle: "flex-1 truncate",
    iconStyle: "text-label shrink-0",
  },
  variants: {
    isExpanded: {
      true: {
        chevronStyle: "transform rotate-90",
      },
    },
    isSelected: {
      true: {
        contentStyle: "bg-primary",
        iconStyle: "text-primary",
      },
    },
  },
});

export default styles;
