import { Spinner } from "capsa-ui";

export const GlobalLoader = () => {
  return (
    <div className="fixed flex-center h-screen w-screen">
      <span className="scale-150">
        <Spinner size="lg" />
      </span>
    </div>
  );
};
