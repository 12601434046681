import React, { forwardRef } from "react";
import { Icon } from "../../icons";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cn } from "../../../lib/utils";
import { cva } from "class-variance-authority";

const wrapperStyles = cva(
  "!outline-none clickable pressable flex gap-2 rounded-badge focus-visible:outline min-w-0 cursor-pointer",
  {
    variants: {
      disabled: {
        true: "disabled",
      },
      truncate: {
        true: "truncate",
      },
    },
  },
);

const checkboxStyles = cva(
  "focus-outer w-5 h-5 peer shrink-0 transition flex justify-center items-center bg text-oncolor rounded-badge border border-strong",
  {
    variants: {
      checked: {
        true: "bg-primary-inverse border-primary",
      },
      disabled: {
        true: "disabled",
      },
    },
  },
);

export interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  truncate?: boolean;
  checkboxClassName?: string;
}

export const Checkbox = forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, label, truncate, checkboxClassName, ...props }, ref) => (
  <label
    htmlFor={props.id || label}
    className={cn(
      wrapperStyles({
        className,
        disabled: props.disabled,
      }),
    )}
  >
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        checkboxStyles({
          checked: props.checked,
          disabled: props.disabled,
        }),
        checkboxClassName,
      )}
      id={props.id || label}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn("flex items-center justify-center text-current")}
      >
        <Icon type="check" className="!w-full !h-full" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    {label && <span className={truncate ? "truncate" : ""}>{label}</span>}
  </label>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;
