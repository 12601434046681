import { useState } from "react";
import { QuestionTemplateRead } from "../../api/questionTemplates";
import { useUserOrgPermissionsQuery } from "../../hooks";
import { useDeleteQuestionTemplateMutation } from "../../hooks/questionTemplates";
import { hasPermission } from "../../utils";
import DeletionModal from "../DeletionModal";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import { paths } from "@/config/paths";

interface Props {
  orgId: string;
  questionTemplate: QuestionTemplateRead;
}

export default function DeleteQuestionTemplateButton({
  orgId,
  questionTemplate,
}: Props) {
  const permissionsQuery = useUserOrgPermissionsQuery(orgId);
  const deleteQuestionTemplateMutation = useDeleteQuestionTemplateMutation(
    orgId,
    questionTemplate.id,
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();

  switch (hasPermission(permissionsQuery, "question_templates.delete")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner w="4" h="4" />
        </button>
      );
    case "true":
      return (
        <div>
          <DeletionModal
            headingText="Delete Question Template"
            paragraphText={`Are you sure you want to delete the ${questionTemplate.name} question template?`}
            confirmationButtonText="Delete"
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            mutation={deleteQuestionTemplateMutation}
            mutationData={{}}
            onSuccess={() =>
              navigate(
                paths.orgs.selected.templates.questionTemplates.getHref(orgId),
              )
            }
          />
          <button
            type="button"
            onClick={() => setOpenDeleteModal(true)}
            className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Delete
          </button>
        </div>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          Delete
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          Delete
        </button>
      );
  }
}
