import { useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateWidgetTemplate } from "../api/widgetTemplates";
import { UpdateWidgetTemplateBody } from "../types";

interface UpdateWidgetTemplateVariables {
  orgId: string;
  widgetTemplateId: string;
  body: UpdateWidgetTemplateBody;
}

export const useUpdateWidgetTemplateMutation = () => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      orgId,
      widgetTemplateId,
      body,
    }: UpdateWidgetTemplateVariables) =>
      updateWidgetTemplate(user, orgId, widgetTemplateId, body),
    onSuccess: (_, { orgId, widgetTemplateId }) => {
      queryClient.invalidateQueries({ queryKey: [orgId, "widget_templates"] });
      queryClient.invalidateQueries({
        queryKey: [orgId, "widget_templates", widgetTemplateId],
      });
    },
  });
};
