import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { companyProfileTemplateRoutes } from "./companyProfileTemplateRoutes";
import Templates from "@/components/TemplatesTab/Templates";
import { questionTemplateRoutes } from "./questionTemplateRoutes";

export const templateRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.templates.path,
    handle: createHandle("Templates", "documentation"),
    children: [
      {
        path: "",
        element: <Templates />,
      },
      ...companyProfileTemplateRoutes(),
      ...questionTemplateRoutes(),
    ],
  },
];
