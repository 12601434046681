import { useAuth0 } from "@auth0/auth0-react";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import info from "../info";
import { sendEvent } from "@/lib/posthog";
import { PostHogConfig } from "posthog-js";

const postHogConfig: Partial<PostHogConfig> = {
  api_host: info.postHogAPIHost,
  ui_host: info.postHogUIHost || null,
  capture_pageleave: false,
  capture_pageview: false,
  autocapture: {
    element_allowlist: [
      "button",
      "a",
      "input",
      "textarea",
      "select",
      "form",
      "label",
    ],
  },
};

function AnalyticsInner({ children }: { children: React.ReactNode }) {
  const { user } = useAuth0();
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    if (user) {
      posthog.identify(user.email, {
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
      posthog.group("org", info.orgId);
      posthog.register({
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
    } else {
      posthog.reset();
    }
  }, [user, info.orgId]);

  useEffect(() => {
    sendEvent("$pageview");
  }, [location]);

  return <>{children}</>;
}

export default function Analytics({ children }: { children: React.ReactNode }) {
  return (
    <PostHogProvider apiKey={info.postHogProjectAPIKey} options={postHogConfig}>
      <AnalyticsInner>{children}</AnalyticsInner>
    </PostHogProvider>
  );
}
