import OrgUsers from "./OrgUsers";
import OrgInvites from "./OrgInvites";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "../layout/DashboardLayout";
import CreateOrgInviteButton from "./CreateOrgInviteButton";

export default function UsersTab() {
  const { orgId } = useValidatedParams(["orgId"]);

  return (
    <DashboardLayout
      title="Users"
      description="View and manage platform users, assign roles, and remove access (admin access required). To add new users, contact your Capsa representative or request via Support."
      actions={[<CreateOrgInviteButton orgId={orgId} key={orgId} />]}
    >
      <OrgUsers orgId={orgId} />
      <OrgInvites orgId={orgId} />
    </DashboardLayout>
  );
}
