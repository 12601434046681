import { useAuth, defaultRetryFunc } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { getCurrentDashboard } from "../api/dashboards";

export const useCurrentDashboardQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "dashboards", "current"],
    queryFn: () => getCurrentDashboard(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
