import { useState } from "react";
import FileViewer, { FileViewerParams } from "../../FileViewer";
import CreateDealQuestionThreadExportButton from "../CreateDealQuestionThreadExportButton";
import UpdateDealQuestionThreadButton from "../UpdateDealQuestionThreadButton";
import DealQuestionThreadWindow from "./DealQuestionThreadWindow";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

export default function DealQuestionThread() {
  const { orgId, dealId, dealQuestionThreadId } = useValidatedParams([
    "orgId",
    "dealId",
    "dealQuestionThreadId",
  ]);
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  return (
    <DashboardLayout
      actions={[
        <UpdateDealQuestionThreadButton
          key="update-deal-question-thread-button"
          orgId={orgId}
          dealId={dealId}
          dealQuestionThreadId={dealQuestionThreadId}
        />,
        <CreateDealQuestionThreadExportButton
          key="create-deal-question-thread-export-button"
          orgId={orgId}
          dealId={dealId}
          dealQuestionThreadId={dealQuestionThreadId}
        />,
      ]}
    >
      <div className="flex flex-row p-4 h-[calc(100vh-7.5rem)] sm:h-[calc(100vh-3.5rem)]">
        <div className="w-1/2 h-full mr-2">
          <FileViewer
            orgId={orgId}
            dealId={dealId}
            {...fileViewerParams}
            setFileViewerParams={setFileViewerParams}
          />
        </div>
        <div className="w-1/2 h-full ml-2">
          <DealQuestionThreadWindow
            orgId={orgId}
            dealId={dealId}
            dealQuestionThreadId={dealQuestionThreadId}
            setFileViewerParams={setFileViewerParams}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}
