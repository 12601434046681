import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { DealRegistrySearchView } from "@/features/registrySearch/pages/DealRegistrySearchView";
import RegistrySearchJobList from "@/components/DealTab/RegistrySearchJobsSubTab/RegistrySearchJobList";
import { createHandle } from "@/utils/router";

export const registrySearchJobRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.registrySearchJobs.path,
    handle: createHandle("Registry Search Jobs", "registrySearch"),
    children: [
      {
        path: "",
        element: <RegistrySearchJobList />,
      },
      {
        path: paths.orgs.selected.deals.selected.registrySearchJobs.create.path,
        element: <DealRegistrySearchView />,
        handle: createHandle("Create"),
      },
    ],
  },
];
