import { APIError } from "../../../api/shared";
import { useDealUserChatThreadQuery } from "../../../hooks";
import ChatWindow from "./ChatWindow";
import { useState } from "react";
import FileViewer, { FileViewerParams } from "../../FileViewer";
import CreateDealChatThreadExportButton from "../CreateDealChatThreadExportButton";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

function _DealUserChatThread({
  orgId,
  dealId,
  dealChatThreadId,
}: {
  orgId: string;
  dealId: string;
  dealChatThreadId: string;
}) {
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });
  const dealChatThreadQuery = useDealUserChatThreadQuery(
    orgId,
    dealId,
    dealChatThreadId,
  );

  // Loading
  if (dealChatThreadQuery.isLoading) {
    return (
      <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">Loading chat threads.</p>
      </ul>
    );
  }

  // Insufficient permissions
  if (
    dealChatThreadQuery.error instanceof APIError &&
    dealChatThreadQuery.error.type === "PermissionError"
  ) {
    return (
      <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to list chat threads."}
        </p>
      </ul>
    );
  }

  // Error
  if (dealChatThreadQuery.isError) {
    return (
      <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          Error listing chat threads.
        </p>
      </ul>
    );
  }

  return (
    <div className="grid grid-cols-2 flex-1 min-h-0">
      <div className="h-full min-h-0">
        <FileViewer
          orgId={orgId}
          dealId={dealId}
          {...fileViewerParams}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
      <div className="h-full min-h-0">
        <ChatWindow
          orgId={orgId}
          dealId={dealId}
          dealChatThread={dealChatThreadQuery.data}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
    </div>
  );
}

export default function DealUserChatThread() {
  const { orgId, dealId, dealChatThreadId } = useValidatedParams([
    "orgId",
    "dealId",
    "dealChatThreadId",
  ]);
  const dealChatThreadQuery = useDealUserChatThreadQuery(
    orgId,
    dealId,
    dealChatThreadId,
  );

  return (
    <DashboardLayout
      title={dealChatThreadQuery.data?.name || "Chat Thread"}
      actions={[
        <CreateDealChatThreadExportButton
          key={orgId}
          orgId={orgId}
          dealId={dealId}
          dealChatThreadId={dealChatThreadId}
        />,
      ]}
      className="max-h-screen"
    >
      <_DealUserChatThread
        orgId={orgId}
        dealId={dealId}
        dealChatThreadId={dealChatThreadId}
      />
    </DashboardLayout>
  );
}
