import { Event, EventEntry, WidgetOptionCompanyEvent } from "@/types/widgets";
import { formatWidgetDate } from "@/utils/time";

export interface TimelineRenderData {
  dateStr: string;
  description: string;
  option: WidgetOptionCompanyEvent;
  entry: EventEntry;
}

export const timelineTransformer = (content: Event[]): TimelineRenderData[] => {
  return content
    .filter((item) => !!item.entry.options[0]?.content)
    .map((event) => {
      const option = event.entry.options[0];
      const { date, period, description } = option.content!;
      const dateStr = date
        ? formatWidgetDate(date, event.language)
        : period
          ? `${formatWidgetDate(period.fromDate, event.language)} to ${formatWidgetDate(period.toDate, event.language)}`
          : "";

      return { dateStr, description, option, entry: event.entry };
    });
};

export const updateTimelineEntry = (
  events: Event[],
  entryId: string,
  newDescription: string,
): Event[] => {
  return events.map((event) => {
    if (event.entry.identifiers.id !== entryId) return event;

    const option = event.entry.options[0];
    if (!option?.content) return event;

    return {
      ...event,
      entry: {
        ...event.entry,
        options: [
          {
            ...option,
            content: {
              ...option.content,
              description: newDescription,
            },
          },
        ],
      },
    };
  });
};

export const deleteTimelineEntry = (
  events: Event[],
  entryId: string,
): Event[] => {
  return events.filter((event) => event.entry.identifiers.id !== entryId);
};
