import {
  Button,
  cn,
  Dialog,
  DialogContent,
  Divider,
  Heading,
  Icon,
  MarkdownEditor,
  Text,
  toast,
} from "capsa-ui";
import { useDashboardStore } from "../store/useDashboardStore";
import { Event, Person, TimeSeries, Topic, Widget } from "@/types/widgets";
import { DashboardPDF } from "./DashboardPDF";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { WidgetContentBulletPointsToMarkdown } from "@/features/widgets/components/WidgetContentBulletPoints";
import { useBrandingConfigQuery } from "@/features/branding/hooks";
import { useValidatedParams } from "@/utils/router";
import { WidgetContentPersonListToMarkdown } from "@/features/widgets/components/WidgetContentPersonList";
import { WidgetContentTableToMarkdown } from "@/features/widgets/components/WidgetContentTable";
import { WidgetContentTimelineToMarkdown } from "@/features/widgets/components/WidgetContentTimeline";
import { WidgetContentTitleToMarkdown } from "@/features/widgets/components/WidgetContentTitle";
import { marked, Tokens } from "marked";
import { decodeHtmlEntities } from "@/utils";

export interface Props {
  companyLogo?: string;
  companyName: string;
  companyDescription: string;
  widgets: Widget[];
}

export const DashboardExport = ({
  companyLogo,
  companyName,
  companyDescription,
  widgets,
}: Props) => {
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useValidatedParams(["orgId"]);
  const { data: brandingConfig } = useBrandingConfigQuery(orgId!);
  const { setExportDialogOpen, exportDialogOpen } = useDashboardStore();
  const [exporting, setExporting] = useState(false);
  const [markdown, setMarkdown] = useState<string>("");

  useEffect(() => {
    if (!exportDialogOpen) {
      const markdown = widgets.map((widget) => {
        if (widget.empty) return "";

        const title = widget.prettyName;

        switch (widget.contentType) {
          case "topic_list":
            return `## ${title}\n${WidgetContentBulletPointsToMarkdown(
              widget.content as Topic[],
            )}`;
          case "person_role_list":
            return `## ${title}\n${WidgetContentPersonListToMarkdown(
              widget.content as Person[],
            )}`;
          case "time_series":
            return `## ${title}\n${WidgetContentTableToMarkdown(
              widget.content as TimeSeries[],
            )}`;
          case "event_list":
            return `## ${title}\n${WidgetContentTimelineToMarkdown(
              widget.content as Event[],
            )}`;
          default:
            return "";
        }
      });

      const title = WidgetContentTitleToMarkdown({
        title: companyName,
        description: companyDescription,
      });

      setMarkdown([title, ...markdown].join(`\n\n\u00A0\n\n\u00A0\n\n`));
    }
  }, [widgets, companyDescription]);

  const sections = useMemo(() => {
    const headingElements = marked
      .lexer(markdown)
      .filter((token) => token.type === "heading");
    return Array.from(headingElements || [])
      .slice(1)
      .map((heading) => decodeHtmlEntities((heading as Tokens.Heading).text))
      .filter(Boolean);
  }, [markdown]);

  const handleExport = async () => {
    try {
      setExporting(true);
      const data = await pdf(
        <DashboardPDF
          markdown={markdown}
          companyName={companyName}
          companyLogo={companyLogo}
          brandLogo={brandingConfig?.logoBase64}
          accentColor={brandingConfig?.accentColor}
        />,
      ).toBlob();
      const url = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${companyName} - Generated ${new Date().toLocaleDateString()} with Capsa AI.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      toast("Failed to export dashboard");
    } finally {
      setExporting(false);
    }
  };

  const scrollToSection = useCallback(
    (section: string) => {
      const headingElements = dialogContentRef.current?.querySelectorAll(
        "h1, h2, h3, h4, h5, h6",
      );
      const sectionElement = Array.from(headingElements || []).find(
        (element) => element.textContent === section,
      );

      if (!sectionElement) return;

      const yOffset = -20;
      const sectionTop = sectionElement.getBoundingClientRect().top;
      const containerTop =
        dialogContentRef.current?.getBoundingClientRect().top || 0;
      const relativePosition = sectionTop - containerTop;

      dialogContentRef.current?.scrollTo({
        top: dialogContentRef.current.scrollTop + relativePosition + yOffset,
        behavior: "smooth",
      });
    },
    [sections, dialogContentRef.current],
  );

  useEffect(() => {
    if (brandingConfig?.accentColor) {
      document.documentElement.style.setProperty(
        "--accent-color",
        brandingConfig.accentColor,
      );
    }
  }, [brandingConfig?.accentColor]);

  // For visually testing PDF output
  const displayPDFViewer = false;

  return (
    <Dialog open={exportDialogOpen} onOpenChange={setExportDialogOpen}>
      <DialogContent className="max-w-[1500px] h-full bg flex p-0 gap-0">
        <div className="flex flex-col w-[250px] min-w-[250px] bg border-r py-4 gap-3">
          <Heading type="h2" className="px-4">
            Export
          </Heading>
          <Divider />
          <div className="px-2">
            <div className="p-2 bg-primary-selected rounded flex gap-1">
              <Icon type="info" size="sm" className="text-primary" />
              <Text type="label">This is an editable text document.</Text>
            </div>
          </div>
          <Divider />
          <div className="flex flex-col gap-0.5 px-2">
            <Text type="label" color="subdued" strong className="px-2 mb-1">
              Sections
            </Text>
            {sections.map((section) => (
              <Button
                key={section}
                fullWidth
                variant="ghost"
                className="[&_>div]:justify-start"
                onClick={() => scrollToSection(section)}
              >
                {section}
              </Button>
            ))}
          </div>
          <div className="flex-1" />
          <Divider />
          <div className="px-4">
            <Button
              iconRight="export"
              size="lg"
              fullWidth
              onClick={handleExport}
              loading={exporting}
              loadingText="Exporting..."
            >
              Export PDF
            </Button>
          </div>
        </div>
        {displayPDFViewer ? (
          <PDFViewer width="100%" height="100%">
            <DashboardPDF
              markdown={markdown}
              companyName={companyName}
              companyLogo={companyLogo}
              brandLogo={brandingConfig?.logoBase64}
              accentColor={brandingConfig?.accentColor}
            />
          </PDFViewer>
        ) : (
          <div
            className="flex-1 flex justify-center h-full overflow-y-auto p-6 bg-secondary"
            ref={dialogContentRef}
          >
            <div className="p-16 w-full max-w-[820px] min-h-[1200px] bg h-fit flex flex-col gap-4 border rounded-layout">
              <header
                className={cn(
                  "flex justify-between items-center relative",
                  !companyLogo && "pl-4",
                )}
              >
                {companyLogo && (
                  <img
                    src={companyLogo}
                    alt={companyName}
                    className="max-h-[40px] max-w-[150px] w-auto h-auto object-contain"
                  />
                )}
                <div className="flex-1" />
                {brandingConfig?.logoBase64 && (
                  <img
                    src={brandingConfig.logoBase64}
                    alt={companyName}
                    className="max-h-[60px] max-w-[150px] w-auto h-auto object-contain"
                  />
                )}
              </header>
              <MarkdownEditor
                key="editor"
                markdown={markdown}
                onChange={setMarkdown}
                className={cn(
                  "[&_h2]:border-b-2 [&_h2]:pb-2 [&_h2]:!mb-4",
                  brandingConfig?.accentColor && {
                    "[&_h2]:border-b-[color:var(--accent-color)]": true,
                  },
                )}
              />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
