import { useGlobalStore } from "@/store";
import { Button, Sidebar, SidebarContent, SidebarHeader, Text } from "capsa-ui";
import FileViewer, { FileViewerParams } from "../FileViewer";
import { useValidatedParams } from "@/utils/router";
import { useMemo, useState } from "react";
import { useEffect } from "react";

export default function DashboardDrawer() {
  const { dealId, orgId } = useValidatedParams(["dealId", "orgId"]);
  const { dashboardDrawerContent, closeDashboardDrawer, dashboardDrawerOpen } =
    useGlobalStore();
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  useEffect(() => {
    if (dashboardDrawerContent) {
      if (dashboardDrawerContent.searchResult.type === "file") {
        setFileViewerParams({
          fileId: dashboardDrawerContent.searchResult.fileId,
          pageNumber: dashboardDrawerContent.searchResult.pageNumber,
          websiteId: null,
          webpageId: null,
          waiting: true,
        });
      } else {
        setFileViewerParams({
          fileId: null,
          pageNumber: null,
          websiteId: dashboardDrawerContent.searchResult.websiteId,
          webpageId: dashboardDrawerContent.searchResult.webpageId,
          waiting: true,
        });
      }
    } else {
      setFileViewerParams({
        fileId: null,
        pageNumber: null,
        websiteId: null,
        webpageId: null,
        waiting: true,
      });
    }
  }, [dashboardDrawerContent]);

  const title = useMemo(() => {
    if (!dashboardDrawerContent) return "";
    switch (dashboardDrawerContent.searchResult.type) {
      case "file":
        return dashboardDrawerContent.searchResult.fileName;
      case "webpage":
        return dashboardDrawerContent.searchResult.url;
      default:
        return "";
    }
  }, [dashboardDrawerContent]);

  return (
    <Sidebar side="right" transitionDuration="duration-300">
      <SidebarHeader>
        <div className="flex items-center gap-2 pl-2 min-w-0 pr-3">
          <div className="mix-blend-multiply">
            <Button
              variant="outline"
              iconLeft="cancel"
              onClick={closeDashboardDrawer}
            />
          </div>
          <Text truncated color="label">
            {title}
          </Text>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <div className="h-full w-full min-h-0 min-w-[575px] p-3">
          {dashboardDrawerContent && dashboardDrawerOpen && (
            <FileViewer
              orgId={orgId}
              dealId={dealId}
              {...fileViewerParams}
              setFileViewerParams={setFileViewerParams}
              loadDelay={400}
            />
          )}
        </div>
      </SidebarContent>
    </Sidebar>
  );
}
