import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useCreateDealQuestionThreadWithTemplateMutation } from "../../hooks";
import { useQuestionTemplatesQuery } from "../../hooks/questionTemplates";
import { APIError } from "../../api/shared";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "../layout/DashboardLayout";

export default function DealQuestionThreadsCreate() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);
  const navigate = useNavigate();
  const { control, handleSubmit, reset, register } = useForm();
  const questionTemplatesQuery = useQuestionTemplatesQuery(orgId);
  const mutation = useCreateDealQuestionThreadWithTemplateMutation(
    orgId,
    dealId,
  );

  // Loading
  if (questionTemplatesQuery.isLoading) {
    return (
      <DashboardLayout className="max-h-screen">
        <div className="h-full overflow-auto min-h-0">
          <p className="py-4 text-sm text-gray-500">Loading.</p>
        </div>
      </DashboardLayout>
    );
  }

  // Insufficient permissions
  if (
    questionTemplatesQuery.error instanceof APIError &&
    questionTemplatesQuery.error.type === "PermissionError"
  ) {
    return (
      <DashboardLayout className="max-h-screen">
        <div className="h-full overflow-auto min-h-0">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list question templates."}
          </p>
        </div>
      </DashboardLayout>
    );
  }

  // Error
  if (questionTemplatesQuery.isError) {
    return (
      <DashboardLayout className="max-h-screen">
        <div className="h-full overflow-auto min-h-0">
          <p className="py-4 text-sm text-gray-500">
            Error listing question templates.
          </p>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout className="max-h-screen">
      <div className="h-full px-4">
        <form
          onSubmit={handleSubmit((values) => {
            mutation.mutate(
              {
                name: values.name,
                questionTemplateId: values.questionTemplateId,
              },
              {
                onSuccess: (data) => {
                  navigate(
                    paths.orgs.selected.deals.selected.questionThreads.selected.getHref(
                      orgId,
                      dealId,
                      data.id,
                    ),
                  );
                  reset();
                },
              },
            );
          })}
        >
          <div className="flex border-b-[1px] border-gray-200 py-4">
            <p className="text-sm max-w-lg text-gray-700">
              Create a new question thread.
            </p>
          </div>
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input
                        id="name"
                        type="text"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="template"
                  className="block text-sm font-medium text-gray-700"
                >
                  Question Template
                </label>
                <div className="mt-1">
                  <select
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("questionTemplateId")}
                  >
                    {questionTemplatesQuery.data.map((template) => (
                      <option value={template.id} key={template.id}>
                        {template.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="py-6">
            <div className="flex justify-end">
              <button
                onClick={() =>
                  navigate(paths.orgs.selected.deals.getHref(orgId))
                }
                type="button"
                className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}
