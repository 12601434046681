import { cva, VariantProps } from "class-variance-authority";
import { cn } from "../../lib/utils";
import { IconVectors } from "./IconVectors";

export const iconStyles = cva("fill-current shrink-0", {
  variants: {
    size: {
      xs: "h-icon-xs w-icon-xs",
      sm: "h-icon-sm w-icon-sm",
      default: "h-icon-md w-icon-md",
      lg: "h-icon-lg w-icon-lg",
      hero: "h-10 w-10",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export const bgStyles = cva(
  "bg-primary flex justify-center items-center text-subdued w-fit h-fit",
  {
    variants: {
      size: {
        xs: "p-0.5 rounded-badge",
        sm: "p-1 rounded-badge",
        default: "p-1.5 rounded",
        lg: "p-2 rounded",
        hero: "p-2 rounded-layout",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);

export type IconType = keyof typeof IconVectors;
export type IconSize = VariantProps<typeof iconStyles>["size"];

export interface IconProps
  extends React.SVGAttributes<HTMLOrSVGElement>,
    VariantProps<typeof iconStyles> {
  type?: IconType;
  withBG?: boolean;
}

export const Icon = ({
  type = "info",
  size = "default",
  withBG,
  className = "",
}: IconProps) => {
  const RenderIcon = () => (
    <svg
      className={cn(
        iconStyles({
          size,
          className,
        }),
      )}
      data-size={size}
      viewBox="0 0 24 24"
    >
      {IconVectors[type]}
    </svg>
  );
  return (
    <>
      {withBG ? (
        <div className={cn(bgStyles({ size }))}>{RenderIcon()}</div>
      ) : (
        RenderIcon()
      )}
    </>
  );
};
