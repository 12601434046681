import { Button, TextField } from "capsa-ui";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import { sendEvent } from "@/lib/posthog";
import React, { useEffect } from "react";
import { Form } from "react-aria-components";

const TabCompany = () => {
  const { nextTab, setCompanyName, companyName, dealName, setDealName } =
    useCreateCompanyStore();

  useEffect(() => {
    sendEvent("viewed_company_tab", {
      feature: "create_company",
    });
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    nextTab();
    setCompanyName(companyName);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <TextField
          name="Deal Name"
          label="Deal Name"
          required
          value={dealName}
          onChange={(e) => setDealName(e.target.value)}
          autoFocus
          autoHighlight
        />
        <TextField
          name="Company Name"
          label="Company Name"
          required
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <div className="flex gap-1.5">
          <div className="flex-1" />
          <Button type="submit" iconRight="chevronRight" onClick={() => {}}>
            Next
          </Button>
        </div>
      </Form>
    </>
  );
};

export default TabCompany;
