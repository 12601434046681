import { LanguageCode } from "@/types/localisation";
import { PartialDate } from "@/types/widgets";

export const formatWidgetDate = (date: PartialDate, language: LanguageCode) => {
  const dateParts = [
    date.day,
    date.month
      ? new Intl.DateTimeFormat(language, { month: "short" })
          .format(new Date(0, Number(date.month) - 1))
          .toUpperCase()
      : null,
    date.year,
  ].filter((part) => part !== undefined && part !== null);
  return dateParts.join(" ");
};

export const compareWidgetDates = (a: PartialDate, b: PartialDate): number => {
  const aStr = [a.year, a.month, a.day].map((part) => part ?? "00").join("");
  const bStr = [b.year, b.month, b.day].map((part) => part ?? "00").join("");
  return aStr.localeCompare(bStr);
};
