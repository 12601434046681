import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { pxToPt } from "@/utils/units";
import CapsaLogo from "@/assets/images/capsaLogo.png";

// fonts
import InterRegular from "@/assets/fonts/Inter-Regular.woff";
import InterMedium from "@/assets/fonts/Inter-Medium.woff";
import InterSemiBold from "@/assets/fonts/Inter-SemiBold.woff";
import { markdownToPdf } from "@/utils/pdf";
import { useMemo } from "react";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: InterRegular,
      fontWeight: 400,
    },
    {
      src: InterMedium,
      fontWeight: 500,
    },
    {
      src: InterSemiBold,
      fontWeight: 600,
    },
  ],
});

export interface Props {
  markdown: string;
  companyName: string;
  companyLogo?: string;
  brandLogo?: string | null;
  accentColor?: string | null;
}

export const DashboardPDF = ({
  markdown,
  companyName,
  companyLogo,
  brandLogo,
  accentColor,
}: Props) => {
  const renderPage = useMemo(() => {
    return (
      <Page size="A4" style={documentStyles.page} wrap>
        <View
          style={{
            ...headerStyles.view,
            paddingLeft: !companyLogo ? pxToPt(16) : undefined,
          }}
        >
          {companyLogo && (
            <Image src={companyLogo} style={headerStyles.companyIcon} />
          )}
          <View style={headerStyles.spacer} />
          {brandLogo && (
            <Image src={brandLogo} style={headerStyles.brandLogo} />
          )}
        </View>
        {markdownToPdf(markdown, { accentColor: accentColor || undefined })}
        <View style={documentStyles.footer} fixed>
          <Text style={{ ...textStyles.label, flex: 1 }}>
            {`Generated ${new Date().toLocaleDateString()}`}
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: "center",
              fontSize: pxToPt(10),
              color: "#6d6d78",
            }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
          <View style={documentStyles.footerLogoContainer}>
            <Image src={CapsaLogo} style={documentStyles.footerLogo} />
          </View>
        </View>
      </Page>
    );
  }, []);

  return (
    <Document
      author="Capsa AI"
      title={`${companyName} - Generated ${new Date().toLocaleDateString()} with Capsa AI`}
    >
      {renderPage}
    </Document>
  );
};

const documentStyles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    paddingVertical: pxToPt(50),
    paddingHorizontal: pxToPt(60),
    fontFamily: "Inter",
    position: "relative",
    paddingBottom: pxToPt(100),
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    paddingHorizontal: pxToPt(50),
    alignItems: "center",
  },
  footerLogoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerLogo: {
    height: pxToPt(20),
    width: "auto",
    objectFit: "contain",
  },
});

const headerStyles = StyleSheet.create({
  view: {
    display: "flex",
    flexDirection: "row",
    gap: pxToPt(10),
    width: "100%",
    marginBottom: pxToPt(24),
    alignItems: "center",
  },
  spacer: {
    flex: 1,
  },
  companyIcon: {
    maxHeight: pxToPt(40),
    maxWidth: pxToPt(150),
    width: "auto",
    height: "auto",
    objectFit: "contain",
    borderRadius: pxToPt(6),
  },
  brandLogo: {
    maxHeight: pxToPt(60),
    maxWidth: pxToPt(150),
    width: "auto",
    height: "auto",
    objectFit: "contain",
  },
});

const textStyles = StyleSheet.create({
  heading: {
    fontSize: pxToPt(20),
    lineHeight: pxToPt(26),
    fontWeight: "semibold",
    color: "#242428",
    width: "100%",
  },
  subheading: {
    fontSize: pxToPt(12),
    lineHeight: pxToPt(18),
    color: "#6d6d78",
  },
  body: {
    fontSize: pxToPt(12),
    lineHeight: pxToPt(18),
    color: "#242428",
  },
  title: {
    fontSize: pxToPt(12),
    lineHeight: pxToPt(18),
    fontWeight: "semibold",
    color: "#242428",
  },
  label: {
    fontSize: pxToPt(10),
    lineHeight: pxToPt(16),
    color: "#6d6d78",
  },
});
