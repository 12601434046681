import React, { useEffect, useState } from "react";
import RegistrySearch from "@/features/registrySearch/pages/RegistrySearch";
import { RegistryDocument } from "@/components/CompanySearch/api/listRegistryDocuments";
import CreateRegistryFilesSyncJobButton from "@/features/registrySearch/components/CreateDocumentUploadJobButton";
import { Company } from "@/features/companies/types/company";
import { useRegistrySearchStore } from "../store/useRegistrySearchStore";
import { DashboardLayout } from "@/components/layout/DashboardLayout";

export const DealRegistrySearchView = () => {
  const [documents, setDocuments] = useState<RegistryDocument[]>([]);
  const [company, setCompany] = useState<Company | null>(null);
  const registrySearchStore = useRegistrySearchStore();

  useEffect(() => {
    registrySearchStore.reset();
  }, []);

  return (
    <DashboardLayout
      title="New Registry Search"
      actions={[
        <CreateRegistryFilesSyncJobButton
          key="delete"
          companyName={company?.name || ""}
          companyIdInRegistry={company?.id || ""}
          files={documents}
          disabled={documents.length === 0}
        />,
      ]}
    >
      <RegistrySearch
        onDocumentsChange={setDocuments}
        onLegalEntityChange={setCompany}
      />
    </DashboardLayout>
  );
};
