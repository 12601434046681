import { useNavigate } from "react-router-dom";
import { useUserOrgPermissionsQuery } from "../../hooks";
import { hasPermission } from "../../utils";
import Spinner from "../Spinner";
import { paths } from "@/config/paths";

interface Props {
  orgId: string;
}

export default function CreateCompanyProfileTemplateButton({ orgId }: Props) {
  const permissionsQuery = useUserOrgPermissionsQuery(orgId);
  const navigate = useNavigate();

  switch (hasPermission(permissionsQuery, "company_profile_templates.create")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner w="4" h="4" />
        </button>
      );
    case "true":
      return (
        <button
          type="button"
          onClick={() =>
            navigate(
              paths.orgs.selected.templates.companyProfileTemplates.create.getHref(
                orgId,
              ),
            )
          }
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
        >
          Create
        </button>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          Create
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          Create
        </button>
      );
  }
}
