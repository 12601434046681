import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import CompanyProfileTemplatesTab from "@/components/CompanyProfileTemplatesTab";
import CompanyProfileTemplatesCreate from "@/components/CompanyProfileTemplatesTab/CompanyProfileTemplatesCreate";
import CompanyProfileTemplatesUpdate from "@/components/CompanyProfileTemplatesTab/CompanyProfileTemplatesUpdate";
import CompanyProfileTemplateTab from "@/components/CompanyProfileTemplateTab";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";

export const companyProfileTemplateRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.templates.companyProfileTemplates.path,
    handle: createHandle("Data Extraction Templates", "extract"),
    children: [
      {
        path: "",
        element: <CompanyProfileTemplatesTab />,
      },
      {
        path: paths.orgs.selected.templates.companyProfileTemplates.create.path,
        element: <CompanyProfileTemplatesCreate />,
        handle: createHandle("Create"),
      },
      {
        path: paths.orgs.selected.templates.companyProfileTemplates.selected
          .path,
        handle: createHandle(MatchParam.companyProfileTemplateId),
        children: [
          {
            path: "",
            element: <CompanyProfileTemplateTab />,
          },
          {
            path: paths.orgs.selected.templates.companyProfileTemplates.selected
              .update.path,
            element: <CompanyProfileTemplatesUpdate />,
            handle: createHandle("Update"),
          },
        ],
      },
    ],
  },
];
