import { Link } from "react-router-dom";
import { APIError } from "../../api/shared";
import { useDealsQuery } from "../../hooks/deals";
import DeleteDealButton from "./DeleteDealButton";
import UpdateDealButton from "./UpdateDealButton";
import Table from "../Table";
import CreateDealButton from "./CreateDealsButton";
import { NewspaperIcon } from "@heroicons/react/24/outline";
import { DealRead } from "@/api/deals";
import { useGlobalStore } from "@/store";
import { paths } from "@/config/paths";

interface Props {
  orgId: string;
}

export function DealsList({ orgId }: Props) {
  const dealsQuery = useDealsQuery(orgId);

  // Insufficient permissions
  if (
    dealsQuery.error instanceof APIError &&
    dealsQuery.error.type === "PermissionError"
  ) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200">
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to list Deals."}
        </p>
      </ul>
    );
  }

  // Error
  if (dealsQuery.isError) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200">
        <p className="py-4 text-sm text-gray-500">Error listing Deals.</p>
      </ul>
    );
  }

  const { setSelectedCompany } = useGlobalStore();
  const selectDeal = (deal: DealRead) => {
    setSelectedCompany(deal);
  };

  // Loading
  // init data as the default of React.ReactElement[][];
  let data: React.ReactElement[][] = [];
  if (!dealsQuery.isLoading) {
    data = dealsQuery.data.map((deal) => {
      return [
        <Link
          key={deal.id}
          to={paths.orgs.selected.deals.selected.dashboards.current.getHref(
            orgId,
            deal.id,
          )}
          className="text-indigo-600"
          onClick={() => selectDeal(deal)}
        >
          {deal.name}
        </Link>,
        <time key={`created_at_${deal.id}`} dateTime={deal.created_at}>
          {new Date(deal.created_at).toLocaleString()}
        </time>,
        <div
          key={`actions_${deal.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <UpdateDealButton orgId={orgId} deal={deal} />
          <DeleteDealButton orgId={orgId} deal={deal} />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Name", "Created", ""]}
      data={data}
      noDataIcon={NewspaperIcon}
      noDataMessage="You don't have any Deals yet."
      noDataButton={<CreateDealButton orgId={orgId} />}
      isLoading={dealsQuery.isLoading}
    />
  );
}
