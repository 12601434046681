import {
  NumberEntry,
  PartialDate,
  TimeSeries,
  WidgetCitation,
} from "@/types/widgets";
import { currency } from "@/utils/currency";
import { formatNumber } from "@/utils/units";

const dateToStr = (date: PartialDate) => {
  const dateParts = [date.year, date.month, date.day].filter(
    (part) => part !== undefined && part !== null,
  );
  return dateParts.join("-");
};

export type TableValue = {
  displayValue: string;
  trueValue: number | string;
  unit?: string;
};

export const getTableRowUnit = (unit: string) => {
  try {
    const currencyInfo = currency[unit as keyof typeof currency];
    return currencyInfo?.symbol || unit;
  } catch (error) {
    console.error(error);
    return unit;
  }
};

export interface TableDict {
  value: TableValue;
  citations: WidgetCitation[];
  entry: NumberEntry;
  unit?: string;
}

export interface TimeSeriesRenderData {
  headers: string[];
  rows: string[];
  columns: string[];
  dataDict: Record<string, TableDict[]>;
}

export const timeSeriesTransformer = (
  content: TimeSeries[],
): TimeSeriesRenderData => {
  const rows = Array.from(new Set(content.map((series) => series.name)));
  const columns = Array.from(
    new Set(
      content.flatMap((series) =>
        series.values.map((value) => dateToStr(value.date)),
      ),
    ),
  ).sort();

  const headers = ["", ...columns];

  const dataDict: Record<
    string,
    {
      value: TableValue;
      citations: WidgetCitation[];
      entry: NumberEntry;
      unit?: string;
    }[]
  > = {};

  rows.forEach((row) => {
    const values = columns.map((col) => {
      const series = content.find((e) => e.name === row);
      const entry = series?.values.find(
        (v) => dateToStr(v.date) === col,
      )?.entry;
      const option = entry?.options[0];

      if (!option?.content) {
        return {
          value: { displayValue: "-", trueValue: "-" } as TableValue,
          citations: [] as WidgetCitation[],
          entry: {} as NumberEntry,
        };
      }

      const { value: numValue, unit } = option.content;
      const displayValue =
        unit === "%" ? numValue.toString() : formatNumber(numValue);

      return {
        value: {
          displayValue,
          unit: unit || undefined,
          trueValue: numValue,
        } as TableValue,
        citations: option.citations,
        entry: entry as NumberEntry,
      };
    });

    dataDict[row] = [
      {
        value: { displayValue: row, trueValue: row } as TableValue,
        citations: [] as WidgetCitation[],
        entry: {} as NumberEntry,
      },
      ...values,
    ];
  });

  return { headers, rows, columns, dataDict };
};

export const updateTimeSeries = (
  content: TimeSeries[],
  rowIndex: number,
  columnIndex: number,
  newValue: number | null,
  unit?: string | null,
): TimeSeries[] => {
  return content.map((series, i) => {
    if (i !== rowIndex) return series;

    const updatedValues = series.values.map((val, j) => {
      if (j !== columnIndex) return val;

      const currentOption = val.entry.options[0] || {
        type: "numeric",
        id: crypto.randomUUID(),
        citations: [],
      };

      return {
        ...val,
        entry: {
          ...val.entry,
          options: newValue
            ? [
                {
                  ...currentOption,
                  content: {
                    value: newValue,
                    unit: unit || null,
                  },
                },
                ...val.entry.options.slice(1),
              ]
            : [],
        },
      };
    });

    return { ...series, values: updatedValues };
  });
};
