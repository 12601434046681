import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { DashboardView } from "@/features/dashboards/pages/DashboardView";

export const dashboardRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.dashboards.current.path,
    element: <DashboardView />,
    handle: createHandle("Company Dashboard", "report"),
  },
];
