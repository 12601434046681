import { getCompanyWebsites } from "@/features/companies/api/companyWebsites";
import { defaultRetryFunc, useAuth } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useValidatedParams } from "@/utils/router";
import { useQuery } from "@tanstack/react-query";

export const useCompanyWebsites = (legalName: string) => {
  const { orgId } = useValidatedParams(["orgId"]);
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "companyUrls", legalName],
    queryFn: () => getCompanyWebsites(user, orgId || "", legalName),
    enabled: !!user && !!legalName,
    retry: defaultRetryFunc,
  });
};
