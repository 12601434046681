export const pxToPt = (px: number) => `${px * 0.75}pt`;

export const formatNumber = (value: number): string => {
  const formatted = new Intl.NumberFormat("en", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: 1,
    minimumFractionDigits: value >= 1000 ? 1 : 0,
  });
  return formatted.format(value);
};
