import { DealRead } from "@/api/deals";
import { WidgetCitation } from "@/types/widgets";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";

const initialState = {
  selectedCompany: undefined as DealRead | undefined,
  dashboardNavOpen: true,
  dashboardDrawerOpen: false,
  dashboardDrawerContent: undefined as WidgetCitation | undefined,
};

export const useGlobalStore = create(
  devtools(
    persist(
      subscribeWithSelector(
        combine(initialState, (set) => ({
          setSelectedCompany: (selectedCompany: DealRead) =>
            set({ selectedCompany }),
          resetSelectedCompany: () => set({ selectedCompany: undefined }),
          openDashboardDrawer: () => set({ dashboardDrawerOpen: true }),
          closeDashboardDrawer: () => set({ dashboardDrawerOpen: false }),
          setDashboardDrawerContent: (content: WidgetCitation) =>
            set({ dashboardDrawerContent: content }),
          resetDashboardDrawerContent: () =>
            set({ dashboardDrawerContent: undefined }),
          openDashboardNav: () => set({ dashboardNavOpen: true }),
          closeDashboardNav: () => set({ dashboardNavOpen: false }),
          setDashboardNavOpen: (open: boolean) =>
            set({ dashboardNavOpen: open }),
        })),
      ),
      {
        name: "GlobalStore",
      },
    ),
  ),
);

useGlobalStore.subscribe(
  (state) => state.selectedCompany,
  () => {
    const { closeDashboardDrawer } = useGlobalStore.getState();
    closeDashboardDrawer();
  },
);
