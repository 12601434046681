import type React from "react";
import styles from "./Spinner.styles";
import { ProgressBar } from "react-aria-components";

export type SpinnerSize = "xs" | "sm" | "default" | "lg";
export type SpinnerVariant = "default" | "oncolor";

export interface SpinnerProps {
  size?: SpinnerSize;
  variant?: SpinnerVariant;
}

export const Spinner = ({ size = "lg", variant = "default" }: SpinnerProps) => {
  return (
    <ProgressBar aria-label="loading...">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        className={styles({ size, variant })}
      >
        <circle
          cx="10"
          cy="10"
          r="9"
          strokeOpacity="0.1"
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19"
          strokeWidth="2"
          strokeLinecap="round"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </ProgressBar>
  );
};
