import type React from "react";
import styles from "./Text.styles";

export type TextType = "subheading" | "body" | "label" | "caption";
export type TextColor = "default" | "label" | "subdued" | "primary" | "danger";
export type TextStyle = "default" | "underline";

export interface TextProps {
  children: React.ReactNode;
  type?: TextType;
  truncated?: boolean;
  strong?: boolean;
  color?: TextColor;
  style?: TextStyle;
  className?: string;
}

export const Text = ({
  children,
  type = "body",
  truncated,
  strong,
  color = "default",
  style = "default",
  className = "",
}: TextProps) => {
  return (
    <span
      className={`${styles({ type, truncated, strong, color, style })} ${className}`.trim()}
    >
      {children}
    </span>
  );
};
