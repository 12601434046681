import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import OrgUsersUpdate from "@/components/OrgUsersUpdate";
import UsersTab from "@/components/UsersTab";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";

export const userRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.users.path,
    handle: createHandle("Users", "user"),
    children: [
      { path: "", element: <UsersTab /> },
      {
        path: paths.orgs.selected.users.selected.path,
        handle: createHandle(MatchParam.userId),
        children: [
          {
            path: paths.orgs.selected.users.selected.update.path,
            element: <OrgUsersUpdate />,
            handle: createHandle("Update"),
          },
        ],
      },
    ],
  },
];
