import { Link, useMatches, useNavigate, useParams } from "react-router-dom";
import { companyFeaturesNavigationItems, UserNavigationItem } from "./common";
import {
  Avatar,
  Button,
  CapsaLogo,
  Combobox,
  CommandGroup,
  CommandItem,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarTrigger,
  Text,
  useSidebar,
} from "capsa-ui";
import { useEffect, useMemo, useState } from "react";
import { useDealsQuery } from "@/hooks/deals";
import { usePostHog } from "posthog-js/react";
import { useAuth0 } from "@auth0/auth0-react";
import { show, hide, onHide, onShow } from "@intercom/messenger-js-sdk";
import { useGlobalStore } from "@/store";
import { useUpload } from "../UploadProvider";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { useWebsitesQuery } from "@/hooks/websites";
import { getCompanyLogoFromWebsites } from "@/utils/image";

function SidebarUploads() {
  const { uploads } = useUpload();
  const { state } = useSidebar();
  if (uploads.size === 0) {
    return <></>;
  }
  return (
    <SidebarGroup className="overflow-hidden">
      <SidebarGroupContent>
        <div className="flex flex-row items-center px-2 gap-2 pb-2 min-w-0 truncated ml-px">
          <Icon type="upload" className="text-subdued" />
          {state === "expanded" && (
            <Text color="label">Uploads ({uploads.size})</Text>
          )}
        </div>
        {state === "expanded" && (
          <div className="rounded bg-secondary-selected p-1 ">
            <div className="overflow-auto max-h-48">
              {Array.from(uploads.keys()).map((key) => {
                const upload = uploads.get(key);
                if (!upload) {
                  return <></>;
                }
                return (
                  <div
                    key={key}
                    className="flex flex-row flex-grow items-center space-x-3 p-1"
                  >
                    <p className="text-size-label truncated flex-1" title={key}>
                      {key}
                    </p>
                    <p className="text-size-label">
                      {Math.round(
                        (upload.loadedBytes * 100) / upload.totalBytes,
                      )}
                      %
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </SidebarGroupContent>
    </SidebarGroup>
  );
}

const DealSelector = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const { dealId } = useParams();
  const { setSelectedCompany, selectedCompany } = useGlobalStore();
  const navigate = useNavigate();
  const { data, refetch } = useDealsQuery(orgId, { staleTime: Infinity });
  const websites = useWebsitesQuery(orgId, selectedCompany?.id || "");

  const dealList = useMemo(
    () =>
      data?.map((deal) => ({
        label: deal.name,
        value: deal.id,
      })) || [],
    [data],
  );

  const selectedId = useMemo(
    () => selectedCompany?.id || "",
    [selectedCompany],
  );

  const handleSelect = (value: string) => {
    if (value) {
      const company = data?.find((deal) => deal.id === value);
      if (company) {
        setSelectedCompany(company);
        if (dealId) {
          if (location.pathname.endsWith("dashboards/current")) {
            navigate(
              paths.orgs.selected.deals.selected.dashboards.current.getHref(
                orgId,
                company.id,
              ),
            );
            return;
          }

          const currentPath = location.pathname.split(dealId);
          // Get route after dealId and only return feature path
          const featureRoute = currentPath[1].split("/").filter(Boolean)[0];
          navigate(
            `${paths.orgs.selected.deals.selected.getHref(orgId, value)}/${featureRoute}`,
          );
        } else {
          navigate(
            `${paths.orgs.selected.deals.selected.getHref(orgId, company.id)}`,
          );
        }
      }
    }
  };

  const logoUrl = useMemo(
    () => getCompanyLogoFromWebsites(websites.data || []),
    [websites.data],
  );

  if (!data) {
    return null;
  }

  const addNewCompany = () => {
    navigate(paths.orgs.selected.deals.create.getHref(orgId));
  };

  const onCompaniesOpen = () => {
    refetch();
  };

  return (
    <>
      <SidebarGroupLabel className="-mt-1">DEAL</SidebarGroupLabel>
      <Combobox
        options={dealList}
        selected={selectedId}
        onSelect={handleSelect}
        placeholder="Select a deal"
        width="100%"
        searchPlaceholder="Search deals..."
        avatar={
          selectedCompany ? (
            <Avatar initials={selectedCompany.name[0]} src={logoUrl} />
          ) : undefined
        }
        onOpen={onCompaniesOpen}
      >
        <>
          <CommandGroup forceMount className="border-t">
            <CommandItem key="add new deal" onSelect={addNewCompany}>
              <Icon type="add" />
              <span>Add new deal</span>
            </CommandItem>
          </CommandGroup>
        </>
      </Combobox>
      <div className="h-2" />
    </>
  );
};

interface DashboardLayoutProps {
  userNavigation: UserNavigationItem[];
}

export default function DashboardSidebar({
  userNavigation,
}: DashboardLayoutProps) {
  const { orgId } = useValidatedParams(["orgId"]);
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { user } = useAuth0();
  const [messengerVisible, setMessengerVisible] = useState(false);
  const deals = useDealsQuery(orgId, { staleTime: Infinity });
  const { selectedCompany, resetSelectedCompany } = useGlobalStore();
  const matches = useMatches();

  const dealIdString = useMemo(
    () => `/orgs/${orgId}/deals/${selectedCompany?.id}`,
    [orgId, selectedCompany?.id],
  );

  const isActive = (path: string): boolean => {
    return matches.some((match) => match.pathname === path);
  };

  useEffect(() => {
    if (deals.data?.length === 0) {
      resetSelectedCompany();
    }
  }, [deals.data]);

  const enabledFeatures = useMemo(
    () =>
      companyFeaturesNavigationItems.filter((item) => {
        if (item.feature) {
          return !!posthog.isFeatureEnabled(item.feature);
        }
        return true;
      }),
    [],
  );

  useEffect(() => {
    onShow(() => {
      setMessengerVisible(true);
    });
    onHide(() => {
      setMessengerVisible(false);
    });
  }, []);

  const toggleIntercomChat = () => {
    if (messengerVisible) {
      hide();
    } else {
      show();
    }
  };

  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem className="flex items-center overflow-hidden pr-1">
            <Link
              className="flex flex-1 items-center px-2 gap-2 cursor-pointer"
              to={paths.orgs.selected.getHref(orgId)}
            >
              <CapsaLogo
                height={20}
                variant="icon"
                className="ml-[3px] shrink-0"
              />
              <CapsaLogo height={20} variant="text" className="shrink-0" />
            </Link>
            <div className="mix-blend-multiply">
              <SidebarTrigger variant="outline" className="text-subdued" />
            </div>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() =>
                    navigate(paths.orgs.selected.deals.getHref(orgId))
                  }
                  isActive={isActive(paths.orgs.selected.deals.getHref(orgId))}
                  tooltip="Deals"
                >
                  <Icon type="company" />
                  <span>Deals</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        {deals.data && deals.data?.length > 0 && (
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <DealSelector />
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
            {selectedCompany?.id && (
              <SidebarGroupContent>
                <SidebarMenu>
                  {enabledFeatures.map((item) => {
                    if (item.type === "nav") {
                      return (
                        <SidebarMenuItem key={item.name}>
                          <SidebarMenuButton
                            onClick={() =>
                              navigate(`${dealIdString}/${item.href}`)
                            }
                            isActive={isActive(`${dealIdString}/${item.href}`)}
                            tooltip={item.name}
                          >
                            <Icon type={item.icon} />
                            <span>{item.name}</span>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      );
                    } else {
                      return (
                        <SidebarGroupLabel key={item.name}>
                          {item.name}
                        </SidebarGroupLabel>
                      );
                    }
                  })}
                </SidebarMenu>
              </SidebarGroupContent>
            )}
          </SidebarGroup>
        )}
      </SidebarContent>
      <SidebarFooter>
        <SidebarUploads />
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {posthog?.isFeatureEnabled("dashboard_templates_config") && (
                <>
                  <SidebarMenuItem>
                    <SidebarMenuButton
                      onClick={() =>
                        navigate(
                          paths.orgs.selected.dashboardTemplates.getHref(orgId),
                        )
                      }
                      isActive={isActive(
                        paths.orgs.selected.dashboardTemplates.getHref(orgId),
                      )}
                      tooltip="Dashboard Templates"
                    >
                      <Icon type="dashboard" />
                      <span>Dashboard Templates</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                  <SidebarMenuItem>
                    <SidebarMenuButton
                      onClick={() =>
                        navigate(
                          paths.orgs.selected.widgetTemplates.getHref(orgId),
                        )
                      }
                      isActive={isActive(
                        paths.orgs.selected.widgetTemplates.getHref(orgId),
                      )}
                      tooltip="Widgets Catalog"
                    >
                      <Icon type="dashboard" />
                      <span>Widgets Catalog</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                </>
              )}
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={toggleIntercomChat}
                  isActive={messengerVisible}
                  tooltip="Support"
                >
                  <Icon type="chat" />
                  <span>Support</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() =>
                    navigate(paths.orgs.selected.templates.getHref(orgId))
                  }
                  isActive={isActive(
                    paths.orgs.selected.templates.getHref(orgId),
                  )}
                  tooltip="Templates"
                >
                  <Icon type="documentation" />
                  <span>Templates</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {posthog?.isFeatureEnabled("settings") && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    onClick={() =>
                      navigate(paths.orgs.selected.settings.getHref(orgId))
                    }
                    isActive={isActive(
                      paths.orgs.selected.settings.getHref(orgId),
                    )}
                    tooltip="Settings"
                  >
                    <Icon type="settings" />
                    <span>Settings</span>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              <SidebarMenuItem>
                <SidebarMenuButton
                  onClick={() =>
                    navigate(paths.orgs.selected.users.getHref(orgId))
                  }
                  isActive={isActive(paths.orgs.selected.users.getHref(orgId))}
                  tooltip="Users"
                >
                  <Icon type="user" />
                  <span>Users</span>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <Popover>
                  <PopoverTrigger asChild>
                    <SidebarMenuButton tooltip="Account">
                      <Avatar initials={user?.name?.slice(0, 1)} />
                      <span>{user?.email}</span>
                    </SidebarMenuButton>
                  </PopoverTrigger>
                  <PopoverContent
                    side="top"
                    align="start"
                    className="p-2 w-[var(--radix-popover-trigger-width)] min-w-fit"
                  >
                    {userNavigation.map((item, index) => (
                      <Button
                        key={index}
                        onClick={item.callback}
                        className="w-full justify-start"
                        variant="ghost"
                      >
                        <span>{item.name}</span>
                      </Button>
                    ))}
                  </PopoverContent>
                </Popover>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarFooter>
    </Sidebar>
  );
}
