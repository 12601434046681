import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";
import { WidgetTemplatesConfigurationView } from "@/features/widgetTemplates/pages/WidgetTemplatesConfigurationView";
import { EditWidgetTemplatePage } from "@/features/widgetTemplates/pages/EditWidgetTemplatePage";

export const widgetTemplatesRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.widgetTemplates.path,
    handle: createHandle("Widget Templates", "dashboard"),
    children: [
      {
        path: "",
        element: <WidgetTemplatesConfigurationView />,
      },
      {
        path: paths.orgs.selected.widgetTemplates.selected.path,
        handle: createHandle(MatchParam.widgetTemplateId),
        children: [
          {
            path: paths.orgs.selected.widgetTemplates.selected.update.path,
            element: <EditWidgetTemplatePage />,
            handle: createHandle("Update"),
          },
        ],
      },
    ],
  },
];
