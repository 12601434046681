import AuthenticatedRoute from "@/components/AuthenticatedRoute";
import SingleTenantOrgInvite from "@/components/SingleTenantUserOrgs";
import UserDashboardLayout from "@/components/UserDashboardLayout";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { RouteObject } from "react-router-dom";

export const userRoutes = (): RouteObject[] => [
  {
    path: paths.users.path,
    element: (
      <AuthenticatedRoute>
        <UserDashboardLayout />
      </AuthenticatedRoute>
    ),
    children: [
      {
        path: paths.users.orgs.path,
        element: <SingleTenantOrgInvite />,
        handle: createHandle("Invite"),
      },
    ],
  },
];
