import { useValidatedParams } from "@/utils/router";
import { WidgetTable } from "../components/WidgetTable";
import { DashboardTemplatesConfigurationLayout } from "@/features/dashboardTemplates/pages/DashboardTemplatesConfigurationView";
export const WidgetTemplatesConfigurationLayout =
  DashboardTemplatesConfigurationLayout;

export const WidgetTemplatesConfigurationView = () => {
  const { orgId } = useValidatedParams(["orgId"]);

  return (
    <WidgetTemplatesConfigurationLayout
      title="Widgets Catalog"
      description={
        "\n\nConfigure widgets available for dashboards.\n\n" +
        "- **System Generated** widgets cannot be edited. If you'd like to create a " +
        "version of a system generated widget, duplicate it and edit the copy.\n\n" +
        "- Each widget has a specific content type that determines what kind of data " +
        "it will display and how it will be generated.\n\n" +
        "- The company one liner widget must be named exactly **'company one liner'** (without quotes) for the Dashboard View to identify it correctly."
      }
    >
      <WidgetTable orgId={orgId} />
    </WidgetTemplatesConfigurationLayout>
  );
};
