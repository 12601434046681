import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useValidatedParams } from "@/utils/router";
import { WidgetTemplateForm } from "../components/WidgetTemplateForm";
import { WidgetTemplate } from "../types";
import { Button } from "capsa-ui";
import { useWidgetTemplateQuery } from "../hooks/useWidgetTemplates";
import { useUpdateWidgetTemplateMutation } from "../hooks/useUpdateWidgetTemplate";
import { DashboardTemplatesConfigurationLayout } from "@/features/dashboardTemplates/pages/DashboardTemplatesConfigurationView";
import { paths } from "@/config/paths";

export const EditWidgetTemplatePage = () => {
  const { orgId } = useValidatedParams(["orgId"]);
  const { widgetTemplateId } = useParams<{ widgetTemplateId: string }>();
  const navigate = useNavigate();

  const { data: widget, isLoading } = useWidgetTemplateQuery(
    orgId,
    widgetTemplateId!,
  );
  const updateMutation = useUpdateWidgetTemplateMutation();

  const [localWidget, setLocalWidget] = useState<WidgetTemplate | null>(null);
  const [originalWidget, setOriginalWidget] = useState<WidgetTemplate | null>(
    null,
  );

  useEffect(() => {
    if (widget) {
      setLocalWidget(widget);
      setOriginalWidget(widget);
    }
  }, [widget]);

  const handleChange = (updates: Partial<WidgetTemplate>) => {
    if (!localWidget) return;
    setLocalWidget({ ...localWidget, ...updates });
  };

  const hasChanges =
    originalWidget &&
    localWidget &&
    JSON.stringify(originalWidget) !== JSON.stringify(localWidget);

  const handleSave = async () => {
    if (!localWidget || !widgetTemplateId) return;
    console.log("handleSave triggered with:", localWidget);

    updateMutation.mutate(
      {
        orgId,
        widgetTemplateId: widgetTemplateId,
        body: {
          name: null,
          prettyName: localWidget.prettyName,
          contentGenerationParams: localWidget.contentGenerationParams,
        },
      },
      {
        onSuccess: () => {
          navigate(paths.orgs.selected.widgetTemplates.getHref(orgId));
        },
      },
    );
  };

  const handleCancel = () => {
    navigate(paths.orgs.selected.widgetTemplates.getHref(orgId));
  };

  const actions = [
    <Button key="cancel" variant="outline" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button
      key="save"
      variant="default"
      onClick={handleSave}
      loading={updateMutation.isLoading}
      disabled={!localWidget?.editable || !hasChanges}
    >
      Save Changes
    </Button>,
  ];

  return (
    <DashboardTemplatesConfigurationLayout
      title={localWidget?.prettyName || "Edit Widget Template"}
      description="Modify the widget template settings below."
      actions={actions}
      loading={isLoading || !localWidget}
    >
      <div className="bg-white shadow rounded-lg p-6">
        {localWidget && (
          <WidgetTemplateForm widget={localWidget} onChange={handleChange} />
        )}
      </div>
    </DashboardTemplatesConfigurationLayout>
  );
};
