import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useCreateOrgInviteMutation } from "../hooks";
import RolesMultiSelect from "./RolesMultiSelect";
import { RoleRead } from "../api/roles";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "./layout/DashboardLayout";

export default function OrgInvitesCreate() {
  const { orgId } = useValidatedParams(["orgId"]);
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm();
  const mutation = useCreateOrgInviteMutation(orgId as string);

  return (
    <DashboardLayout>
      <form
        onSubmit={handleSubmit((values) => {
          const roles = values.roles as RoleRead[];
          mutation.mutate(
            {
              user_email: values.userEmail,
              roles: roles.map((r) => r.id),
            },
            {
              onSuccess: () => {
                navigate(paths.orgs.selected.users.getHref(orgId));
                reset();
              },
            },
          );
        })}
      >
        <div className="flex border-b-[1px] border-gray-200 pb-4">
          <p className="text-sm max-w-lg text-gray-700">
            Invite a user to join the org.
          </p>
        </div>
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="userEmail"
                  className="block text-sm font-medium text-gray-700"
                >
                  User Email
                </label>
                <div className="mt-1">
                  <Controller
                    name="userEmail"
                    control={control}
                    render={({ field }) => (
                      <input
                        id="userEmail"
                        type="text"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="roles"
                  className="block text-sm font-medium text-gray-700"
                >
                  Roles
                </label>
                <div className="mt-1">
                  <Controller
                    name="roles"
                    control={control}
                    render={({ field }) => (
                      <RolesMultiSelect
                        orgId={orgId as string}
                        type="org"
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-6">
          <div className="flex justify-end">
            <button
              onClick={() => navigate(paths.orgs.selected.users.getHref(orgId))}
              type="button"
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </DashboardLayout>
  );
}
