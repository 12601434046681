import { DashboardLayout } from "../layout/DashboardLayout";
import CreateQuestionTemplateButton from "./CreateQuestionTemplateButton";
import { QuestionTemplatesList } from "./QuestionTemplatesList";
import { useValidatedParams } from "@/utils/router";

export default function QuestionTemplatesTab() {
  const { orgId } = useValidatedParams(["orgId"]);

  return (
    <DashboardLayout
      title="Question Thread Templates"
      actions={[<CreateQuestionTemplateButton key={orgId} orgId={orgId} />]}
    >
      <div className="flex">
        <p className="text-sm max-w-2xl text-gray-700">
          Question templates contain a set of questions that you can apply to
          deals.
        </p>
      </div>
      <QuestionTemplatesList orgId={orgId} />
    </DashboardLayout>
  );
}
