import type React from "react";
import styles from "./Tags.styles";
import {
  Button,
  Key,
  Label,
  Tag,
  TagGroup,
  TagList,
} from "react-aria-components";
import { useVisuallyHidden } from "@react-aria/visually-hidden";
import { useMemo } from "react";
import { Icon } from "../../icons";

export type Tag = {
  id: string;
  label: string;
  preventRemoval?: boolean;
};

export interface TagsProps {
  tags: Tag[];
  onRemove?: (id: string) => void;
  label?: string;
}

export const Tags = ({ tags, onRemove, label = "tags" }: TagsProps) => {
  const { visuallyHiddenProps } = useVisuallyHidden();
  const { listStyle, tagStyle, cancelIcon } = styles();

  const props = useMemo(
    () =>
      onRemove
        ? { onRemove: (keys: Set<Key>) => onRemove([...keys][0] as string) }
        : {},
    [onRemove],
  );

  return (
    <TagGroup {...props} selectionMode="none">
      <Label {...visuallyHiddenProps}>{label}</Label>
      <TagList className={listStyle()} items={tags}>
        {(tag) => (
          <Tag
            key={tag.id}
            id={tag.id}
            className={({ allowsRemoving }) =>
              tagStyle({
                allowsRemoving: allowsRemoving && !tag.preventRemoval,
              })
            }
            textValue={tag.label}
            isDisabled={tag.preventRemoval}
          >
            {({ allowsRemoving }) => (
              <>
                {tag.label}
                {allowsRemoving && !tag.preventRemoval && (
                  <Button slot="remove" className={cancelIcon()}>
                    <Icon type="cancelFilled" size="sm" />
                  </Button>
                )}
              </>
            )}
          </Tag>
        )}
      </TagList>
    </TagGroup>
  );
};
