import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";
import OrgInvitesCreate from "@/components/OrgInvitesCreate";
import OrgInvitesUpdate from "@/components/OrgInvitesUpdate";

export const inviteRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.invites.path,
    handle: createHandle("Invites", "user"),
    children: [
      {
        path: paths.orgs.selected.invites.create.path,
        handle: createHandle("Create"),
        element: <OrgInvitesCreate />,
      },
      {
        path: paths.orgs.selected.invites.selected.path,
        handle: createHandle(MatchParam.inviteId),
        children: [
          {
            path: paths.orgs.selected.invites.selected.update.path,
            handle: createHandle("Update"),
            element: <OrgInvitesUpdate />,
          },
        ],
      },
    ],
  },
];
