import { IconType } from "capsa-ui";

interface NavItem {
  type: "nav";
  name: string;
  href: string;
  icon: IconType;
  feature?: string;
}

interface LabelItem {
  type: "label";
  name: string;
  feature?: string;
}

export const companyFeaturesNavigationItems: (NavItem | LabelItem)[] = [
  {
    type: "nav",
    name: "Company Dashboard",
    href: `dashboards/current`,
    icon: "report",
    feature: "dashboard",
  },
  {
    type: "label",
    name: "DATA SOURCES",
  },
  {
    type: "nav",
    name: "Files",
    href: `files`,
    icon: "folderClosed",
  },
  {
    type: "nav",
    name: "Websites",
    href: `websites`,
    icon: "website",
  },
  {
    type: "nav",
    name: "Registry Search",
    href: `registry-search-jobs`,
    icon: "registrySearch",
    feature: "legal_entity_search",
  },
  {
    type: "label",
    name: "TOOLS",
  },
  {
    type: "nav",
    name: "Data Extractions",
    href: `company-profiles`,
    icon: "extract",
  },
  {
    type: "nav",
    name: "Table Aggregations",
    href: `table-aggregations`,
    icon: "table",
  },
  {
    type: "nav",
    name: "Question Threads",
    href: `question-threads`,
    icon: "question",
    feature: "question_threads",
  },
  {
    type: "label",
    name: "DISCOVER",
  },
  {
    type: "nav",
    name: "Chat Assistant",
    href: `chat-threads`,
    icon: "chat",
  },
  {
    type: "nav",
    name: "Search",
    href: `search`,
    icon: "search",
    feature: "search",
  },
  {
    type: "label",
    name: "MANAGE",
  },
  {
    type: "nav",
    name: "Deal Access",
    href: `users`,
    icon: "lock",
  },
];

export interface UserNavigationItem {
  name: string;
  callback: () => void;
  icon: IconType;
  current: boolean;
}

export function getUserNavigation(logout: () => void): UserNavigationItem[] {
  return [
    {
      name: "Logout",
      callback: logout,
      icon: "cancel",
      current: false,
    },
  ];
}
