import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { RoleRead } from "../../api/roles";
import { useOrgUsersQuery, useRolesQuery } from "../../hooks";
import {
  useCreateDealUserMutation,
  useDealUsersQuery,
} from "../../hooks/dealUsers";
import RolesMultiSelect from "../RolesMultiSelect";
import { paths } from "@/config/paths";
import { useValidatedParams } from "@/utils/router";
import { DashboardLayout } from "../layout/DashboardLayout";

type URLParams = {
  orgId: string;
  dealId: string;
};

function _DealUsersCreate({ orgId, dealId }: URLParams) {
  const navigate = useNavigate();
  const { control, handleSubmit, reset, register } = useForm();
  const orgUsersQuery = useOrgUsersQuery(orgId);
  const dealUsersQuery = useDealUsersQuery(orgId, dealId);
  const rolesQuery = useRolesQuery(orgId, "deal");
  const mutation = useCreateDealUserMutation(orgId, dealId);

  if (
    orgUsersQuery.isLoading ||
    dealUsersQuery.isLoading ||
    rolesQuery.isLoading
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="pb-4 text-sm text-gray-500">Loading...</p>
        </ul>
      </div>
    );
  }

  if (orgUsersQuery.error || dealUsersQuery.error) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="pb-4 text-sm text-gray-500">Error loading users.</p>
        </ul>
      </div>
    );
  }

  if (rolesQuery.error) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="pb-4 text-sm text-gray-500">Error loading roles.</p>
        </ul>
      </div>
    );
  }

  const users = orgUsersQuery.data.filter((user) => {
    return !dealUsersQuery.data.find((dealUser) => dealUser.id === user.id);
  });

  if (users.length === 0) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="pb-4 text-sm text-gray-500">
            No users available to add.
          </p>
        </ul>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit((values) => {
        const roles = values.roles as RoleRead[];
        mutation.mutate(
          {
            user_id: values.user,
            roles: roles.map((r) => r.id),
          },
          {
            onSuccess: () => {
              navigate(
                paths.orgs.selected.deals.selected.users.getHref(orgId, dealId),
              );
              reset();
            },
          },
        );
      })}
    >
      <div className="flex border-b-[1px] border-gray-200 pb-4">
        <p className="text-sm max-w-lg text-gray-700">
          Add a user to the deal.
        </p>
      </div>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="user"
                className="block text-sm font-medium text-gray-700"
              >
                User
              </label>
              <div className="mt-1">
                <select
                  id="user"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  {...register("user")}
                >
                  {users.map((user) => (
                    <option key={user.id} id={user.id} value={user.id}>
                      {user.email}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-6">
              <label
                htmlFor="roles"
                className="block text-sm font-medium text-gray-700"
              >
                Roles
              </label>
              <div className="mt-1">
                <Controller
                  name="roles"
                  control={control}
                  render={({ field }) => (
                    <RolesMultiSelect
                      orgId={orgId as string}
                      type="deal"
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="flex justify-end">
          <button
            onClick={() => navigate(paths.orgs.selected.users.getHref(orgId))}
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
}

export default function DealUsersCreate() {
  const { orgId, dealId } = useValidatedParams(["orgId", "dealId"]);

  return (
    <DashboardLayout>
      <_DealUsersCreate orgId={orgId} dealId={dealId} />
    </DashboardLayout>
  );
}
