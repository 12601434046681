import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";
import { BlockBlobClient } from "@azure/storage-blob";

export interface PartialDate {
  year: number | null;
  month: number | null;
  day: number | null;
}

export enum FileType {
  CIM = "cim",
  TEASER = "teaser",
  ANNUAL_REPORT = "annual_report",
  CONSOLIDATED_ANNUAL_REPORT = "consolidated_annual_report",
}

export interface FileMetadata {
  name_dates: PartialDate[] | null;
  num_tables: number | null;
  file_type: FileType | null;
  covered_years: number[] | null;
  language: "en" | "de" | null;
  covered_countries: string[] | null;
}

export interface FileRead {
  id: string;
  org_id: string;
  deal_id: string;
  name: string;
  container: string;
  blob: string;
  file_metadata: FileMetadata;
  ingestion_status: "pending" | "completed" | "failed";
  ingestion_error_message: string | null;
  download_url: string | null;
  created_at: string;
  num_tables: number | null;
}

export const listDealFiles = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<FileRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/files`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export const readDealFile = async (
  user: User,
  orgId: string,
  dealId: string,
  fileId: string,
): Promise<FileRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/files/${fileId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface FileReadFirst {
  id: string;
  name: string;
  upload_url: string;
  created_at: string;
  ingestion_status: "pending" | "complete" | "failed";
}

export interface FileCreate {
  file: File;
  password: string | null;
}

export const createDealFile = async (
  user: User,
  orgId: string,
  dealId: string,
  data: FileCreate,
  onStart: (name: string) => void,
  onProgress: (progress: { loadedBytes: number }) => void,
  onEnd: (name: string) => void,
): Promise<FileReadFirst> => {
  const response = await resolveFetch<FileReadFirst>(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/files`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify({ name: data.file.name, password: data.password }),
    }),
  );
  await uploadDealFile(
    user,
    orgId,
    dealId,
    response.id,
    response.upload_url,
    data.file,
    onStart,
    onProgress,
    onEnd,
  );
  return response;
};

export const deleteDealFile = async (
  user: User,
  orgId: string,
  dealId: string,
  fileId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/files/${fileId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export const uploadDealFile = async (
  user: User,
  orgId: string,
  dealId: string,
  fileId: string,
  url: string,
  file: File,
  onStart: (name: string) => void,
  onProgress: (progress: { loadedBytes: number }) => void,
  onEnd: (name: string) => void,
): Promise<null> => {
  // TODO
  if (process.env.NODE_ENV !== "production") {
    url = url.replace("http://storage", "http://localhost");
  }
  const blockBlobClient = new BlockBlobClient(url);
  onStart(file.name);
  await blockBlobClient.uploadData(file, { onProgress: onProgress });
  await sendFileUploadedEvent(user, orgId, dealId, fileId);
  onEnd(file.name);
  return null;
};

export interface PageRead {
  page_number: number;
  download_url: string;
}

export const listDealFilePages = async (
  user: User,
  orgId: string,
  dealId: string,
  fileId: string,
): Promise<PageRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/files/${fileId}/pages`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export const sendFileUploadedEvent = async (
  user: User,
  orgId: string,
  dealId: string,
  fileId: string,
  attempt: number = 1,
  maxAttempts: number = 3,
): Promise<null> => {
  try {
    return await resolveFetch(
      fetch(
        `${baseURL}/orgs/${orgId}/deals/${dealId}/files/${fileId}/commands/send-file-uploaded-event`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await user.getIdToken()}`,
          },
        },
      ),
    );
  } catch (error) {
    if (attempt < maxAttempts) {
      const delay = 1000 * 2 ** attempt;
      await new Promise((resolve) => setTimeout(resolve, delay));
      return await sendFileUploadedEvent(
        user,
        orgId,
        dealId,
        fileId,
        attempt + 1,
        maxAttempts,
      );
    }
    throw error;
  }
};
