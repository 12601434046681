import { RouteObject } from "react-router-dom";
import { paths } from "@/config/paths";
import WebsitesList from "@/components/DealTab/WebsitesSubTab/WebsitesList";
import WebsitesCreate from "@/components/DealTab/WebsitesCreate";
import Website from "@/components/DealTab/WebsitesSubTab/Website";
import { createHandle } from "@/utils/router";
import { MatchParam } from "@/types/router";

export const websiteRoutes = (): RouteObject[] => [
  {
    path: paths.orgs.selected.deals.selected.websites.path,
    handle: createHandle("Websites", "website"),
    children: [
      {
        path: "",
        element: <WebsitesList />,
      },
      {
        path: paths.orgs.selected.deals.selected.websites.selected.path,
        element: <Website />,
        handle: createHandle(MatchParam.websiteId),
      },
      {
        path: paths.orgs.selected.deals.selected.websites.create.path,
        element: <WebsitesCreate />,
        handle: createHandle("Create"),
      },
    ],
  },
];
